import React, { useState } from 'react';
import { InlineWidget } from "react-calendly";

const DeliveryPageCalendar = ({calendlyUrl}) => {

  return (
              
    <div className="deliveryPage-calendar">
      <div className="deliveryPage-calendar-inner">            
        <div className="deliveryPage-calendar-inner-outerScale">
          <div className='deliveryPage-calendar-inner-innerScale'>
        <InlineWidget 
          url="https://calendly.com/jasper-yarn/sample-intro-call"                     
          styles={{
            //colorScheme: 'white',
            height: '100%'
          }}
          pageSettings={{
            //backgroundColor: 'ffffff',
            hideEventTypeDetails: true,
            //hideLandingPageDetails: true,
            //backgroundColor: '000000',  // Dark background
            backgroundColor: 'FAFAFF',
            //backgroundColor: '#141414',  // Dark background
            
            //primaryColor: '00a2ff',     // Accent color
            //textColor: 'ffffff'         // White text
            // primaryColor: '00a2ff',
            // textColor: '4d5055'
          }}
        />
        
        </div>
        </div>
      </div>

    </div>
            
  );
};

export default DeliveryPageCalendar;