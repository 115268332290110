import { post, fetch } from '../apiProd';
import { uploadToS3, uploadLargeFileToS3 } from './utils/uploadWebcamToS3';

//const SIZE_THRESHOLD = 100 * 1024 * 1024;
const SIZE_THRESHOLD = 1000 * 1024 * 1024;

export const createWebcamUpload = (sessionId, params) => {

  return post(`/creator/session/${sessionId}/webcam`, params);
};

export const markUploadComplete = (sessionId, captureId) => {
  return post(`/creator/session/${sessionId}/webcam/${captureId}/upload-complete`);
};

export const addWebcamToCreatorSession = ({
  blob,
  captureId,
  sessionId,
  variableName,
  variableWebcamId,
  creatorSetAudioOnly
}) => {
  return (dispatch) => {
    console.log( blob.size)
    // console.log('addWebcamToCreatorSession starting with:', {
    //   blob,
    //   captureId,
    //   sessionId,
    //   variableName,
    //   variableWebcamId,
    //   creatorSetAudioOnly
    // });

    if (!sessionId || !captureId) {
      console.error('Missing required parameters:', { sessionId, captureId });
      return Promise.reject('Missing required parameters');
    }

    dispatch({ type: 'WEBCAM_UPLOAD_START', payload: { captureId } });
    
    const uploadParams = {
      captureId,
      variableWebcamId,
      contentType: blob.type,
      creatorSetAudioOnly
    };
    
   // console.log('Calling createWebcamUpload with params:', uploadParams);
    
    return createWebcamUpload(sessionId, uploadParams)
      .then((response) => {
      //  console.log('createWebcamUpload response:', response);
        const {uploadURL} = response
        // Upload to S3 based on file size
        const uploadPromise = blob.size > SIZE_THRESHOLD
          ? uploadLargeFileToS3(blob, uploadURL)
          : uploadToS3(blob, uploadURL);

        return uploadPromise
          .then(() => markUploadComplete(sessionId, captureId))
          .then(response => {
            dispatch({ type: 'WEBCAM_UPLOAD_SUCCESS', payload: { captureId, response } });
            return response;
          });
      })
      .catch(error => {
        dispatch({ type: 'WEBCAM_UPLOAD_ERROR', payload: { captureId, error } });
        console.error('Error during webcam upload:', error);
        throw error;
      });
  };
};


export const updateWebcamEdits = (sessionId, captureId, edits) => {
  return post(`/creator/session/${sessionId}/webcam/${captureId}/update-edits`, edits).then((response)=>{
    console.log('updateWebcamEdits response is',response)
  })
};