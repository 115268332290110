export const VOICE_MATCH_SAMPLE_TEXT="Hey, I'm your AI voice! Click Edit Voiceover below if you want to re-record me. Remember, I'll always match the talking speed, clarity, and liveliness of the original recording."

export const VIDEO_GEN_TIMEOUT=120*1000*2

export const voiceMatchSettings={
 stability:0.6,
 similarityBoost:0.55,
 styleExaggeration:0.15,
 speakerBoost:true,
 model_id:"eleven_multilingual_v2"
}


export const libraryVoiceSettings ={
  stability:0.53,
  similarityBoost:0.65,
  styleExaggeration:0.5,
  speakerBoost:true,
  model_id: "eleven_monolingual_v1",
}