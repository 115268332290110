import React from 'react';
import Icon from '../../Icon';
import VideoDuration from './videoDuration';

function VideoPlayerPrePlayControls({duration, play}) {

  return (
    <>

    <div className='videoPlayer-prePlayControls'>

      <div onClick={play} className='videoPlayer-prePlayControls-playBtn'>
        <div className='videoPlayer-prePlayControls-playBtn-iconContainer'>
          <Icon name='playbackPlay'/>
        </div>
        {/*}
        <div className='videoPlayer-prePlayControls-playBtn-label'>
          <VideoDuration seconds={duration} />
        </div>
        */}
      </div>
      
    </div>

    

    </>
  );
}

export default VideoPlayerPrePlayControls;