import React, { useState, useEffect, useRef } from 'react';

const GeneratingButtonSpinner = ({ duration = 12000 }) => {
  const [progress, setProgress] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const startTimeRef = useRef(null);
  const animationFrameRef = useRef(null);

  const updateProgress = () => {
    if (!startTimeRef.current) {
      startTimeRef.current = performance.now();
    }

    const elapsedTime = performance.now() - startTimeRef.current;
    if (elapsedTime < duration) {
      const linearProgress = (elapsedTime / duration) * 100;
      setProgress(linearProgress);
      animationFrameRef.current = requestAnimationFrame(updateProgress);
    } else {
      setShowSpinner(true);
      setProgress(100);
    }
  };

  useEffect(() => {
    updateProgress();
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [duration]);

  return (
    <>
      <div data-visibility-state={showSpinner ? 'visible' : 'hidden'} className="page-generateVideo-submitBtn--generating-spinnerContainer">
        <div className="page-generateVideo-submitBtn--generating-spinner"/>
      </div>
      <div data-visibility-state={showSpinner ? 'hidden' : 'visible'} className="page-generateVideo-submitBtn--generating-progressBarOuterContainer">
        <div className="page-generateVideo-submitBtn--generating-progressBarInnerContainer">
          <div 
            className="page-generateVideo-submitBtn--generating-progressBar" 
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>
    </>
  );
};

export default GeneratingButtonSpinner;