import React, { useState, useEffect, useCallback } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Icon from '../../Icon'

const CampaignVariantRecordToolbarStopButton = ({ status, setStatus, stopRecording, restartRecording, suggestedDuration }) => {
  const [progress, setProgress] = useState(0);
  const [startTime, setStartTime] = useState(null);

  const resetProgress = useCallback(() => {
    setProgress(0);
    setStartTime(null);
  }, []);

  useEffect(() => {
    let intervalId;
    if (status === 'recording') {
      resetProgress();
      setStartTime(Date.now());
      intervalId = setInterval(() => {
        const elapsedTime = (Date.now() - startTime) / 1000; // Convert to seconds
        const newProgress = Math.min((elapsedTime / suggestedDuration), 1);
        setProgress(newProgress);
      }, 100); // Update every 100ms for smoother animation
    } else {
      resetProgress();
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [status, suggestedDuration, startTime, resetProgress]);

  const handleStopClick = useCallback(() => {
    console.log('Stop click, current status:', status);
    stopRecording(true);
    setStatus('postEdit');
  }, [setStatus, stopRecording, status]);

  // const handleRestartClick = useCallback(() => {
  //   console.log('Restart click, current status:', status);
  //   stopRecording(false);
  //   setStatus('pre');
  // }, [setStatus, stopRecording, status]);

  return (
    <>
      {status === 'recording' && (
        <>
        <button 
          className='campaignVariantPage-record-toolbar-restartButton'
          onClick={restartRecording}
        >
          <div className='campaignVariantPage-record-toolbar-restartButton-circle'>
            <Icon name='backArrow' />
          </div>
        </button>
        <div className='campaignVariantPage-record-toolbar-midSpacer'/>
        <button 
          className='campaignVariantPage-record-toolbar-stopBtn'
          onClick={handleStopClick}
        >                              
          <div className='campaignVariantPage-record-toolbar-stopBtn-inner'>
            <div className='campaignVariantPage-record-toolbar-stopBtn-square' />          
            <div className='campaignVariantPage-record-toolbar-stopBtn-circle' />          
            <div className='campaignVariantPage-record-toolbar-stopBtn-circularProgressBar'>
              <CircularProgressbar 
                value={progress * 100}
                strokeWidth={5}
              />
            </div>   
          </div>               
          
        </button>

        <div className='campaignVariantPage-record-toolbar-midSpacer'/>
        <div className='campaignVariantPage-record-toolbar-restartButtonSpacer' />
        </>
      )}
    </>
  );
};

export default CampaignVariantRecordToolbarStopButton;