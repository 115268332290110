import React, { useState, useEffect,useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {generateProjectWithOptions} from '../../actions/voiceMatch'
import Pusher from 'pusher-js';
import { Mixpanel } from '../../Mixpanel';
import voicesData from '../../utils/voicesData'
import backgroundMusicTracks from '../../utils/backgroundMusicTracks'
import backgroundColorOptions from '../../utils/backgroundColorOptions'


function getVoiceFor(id) {
  const voice = voicesData.find(v => v.id === id);
  return voice
}

function getBackgroundMusicFor(id) {
  const music = backgroundMusicTracks.find(v => v.id === id);
  return music
}

function getBackgroundColorOptionsForId(id) {
  const options = backgroundColorOptions.find(v => v.id === id);
  return options.options
}


//const TEST_VOICE_ID='ApZf0OKtCSKJU9uBoGkz' //me clone

// const voiceMatchSettings={
//    stability:0.53,
//    similarityBoost:0.65,
//    styleExaggeration:0.5,
//    speakerBoost:true,
//    model_id:"eleven_multilingual_v2"
// }


//const TEST_VOICE_ID='ApZf0OKtCSKJU9uBoGkz' //me clone
const TEST_VOICE_ID='8LtExxXPgCOnA2YXh8pt' //tommy clone

const voiceMatchSettings={
   stability:0.6,
   // stability:0.2,
   similarityBoost:0.55,
   styleExaggeration:0.15,
   speakerBoost:true,
   model_id:"eleven_multilingual_v2"
}






// const voiceMatchSettings={
//    stability:0.50,
//    similarityBoost:0.85,
//    styleExaggeration:0.15,
//    speakerBoost:true,
//    model_id:"eleven_monolingual_v2"
// }

// // const voiceMatchSettings={
// //    stability:0.53,
// //    similarityBoost:0.65,
// //    styleExaggeration:0.50,
// //    speakerBoost:true,
// //    model_id:"eleven_multilingual_v2"
// // }



const libraryVoiceSettings ={
  stability:0.53,
  similarityBoost:0.65,
  styleExaggeration:0.5,
  speakerBoost:true,
  model_id: "eleven_monolingual_v1",
}

async function generateVideo(projectId, queryParams) {
  try {
    const { slug, ...otherParams } = queryParams;
    const optionsBase64 = btoa(JSON.stringify(otherParams));
    const url = `https://orch.yarndist.com/orchestrator/render/${projectId}?options=${optionsBase64}&&renderSegmentSizeSeconds=2`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
  });
  if (!response.ok) {
    throw new Error('Failed to generate video');
  }
  const data = await response.json();
  return data;
  } catch (error) {
    console.error('Error generating video:', error);
    throw error;
  }
}


const GeneratingVideo = ({ goTo,projectId,voiceMatchId,selectedBGColor,queryParams,selectedLibraryVoice,variantsGeneratedCount,setVariantsGeneratedCount,selectedBGMusic }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pusherRef = useRef(null);
  const channelRef = useRef(null);


  //1:create a video with the options and cloned voice.  API gives us back a project id for this new project
  //2: trigger ssr and wait for pusher with that render id
  //3. when video is ready show download link.  if failed do something
  //todo track the time taken for each of these steps
  //const [status, setStatus] = useState('pre');
  const [videoReady, setVideoReady] = useState(false);
  const [videoFailed, setVideoFailed] = useState(false);
  const [progress, setVideoProgress] = useState(0);
  const [generatedProjectId, setGeneratedProjectId] = useState(null);
  const [generateNewProjectTime, setGenerateNewProjectTime] = useState(null);
  const [videoGenerationTime, setVideoGenerationTime] = useState(null);
  const [renderId, setRenderId] = useState(null);
  const [assetURL, setAssetURL] = useState(null);
  const [videoGenerationStartTime, setVideoGenerationStartTime] = useState(null);

  useEffect(() => {
    //temp take this out
   handleGenerateNewProject();
  
  }, []);

  const getQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    const params = {};
    for (let [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    return params;
  };

  //1.
  const handleGenerateNewProject = async () => {
    const startTime = performance.now();
    setVideoProgress(0);

    const progressInterval = setInterval(() => {
      setVideoProgress((prevProgress) => {
        return prevProgress < 100 ? prevProgress + 0.5 : prevProgress;
      });
    }, 35000 / 200);


    try {
      const queryParams = getQueryParams()
      let voiceId = voiceMatchId 
      let voiceSettings =voiceMatchSettings
      voiceSettings.providerId=voiceId
      voiceSettings.voiceId=voiceId

      if(!voiceMatchId){
        if(selectedLibraryVoice){
          voiceSettings = libraryVoiceSettings
          const voiceData = getVoiceFor(selectedLibraryVoice)
          // console.log(voiceData)
          if(voiceData){
            voiceId=voiceData.providerId
              voiceSettings = {
              ...voiceSettings,
                stability: voiceData.stability ?? voiceSettings.stability,
                similarityBoost: voiceData.similarityBoost ?? voiceSettings.similarityBoost,
                styleExaggeration: voiceData.styleExaggeration ?? voiceSettings.styleExaggeration,
                speakerBoost: voiceData.speakerBoost ?? voiceSettings.speakerBoost,
                model_id: voiceData.modelId ?? voiceSettings.model_id,
                providerId:voiceData.providerId,
                voiceId:voiceData.id
              };
          }
        }
      }
      //console.log(`generate project with voice ${voiceId}`)

      const bgTrack= getBackgroundMusicFor(selectedBGMusic)
      let trackId 
      if(bgTrack){
        trackId=bgTrack.trackId
      }

      const bgColorOptions = getBackgroundColorOptionsForId(selectedBGColor)
 


      const result = await dispatch(generateProjectWithOptions(projectId,voiceSettings,bgColorOptions,trackId,queryParams));
      const endTime = performance.now();
      const duration = endTime - startTime;
      //test comment out
     setGenerateNewProjectTime(duration);

      if (result && result.projectId) {
      //  console.log('result is', result);

        //test comment out
        setGeneratedProjectId(result.projectId);
        await handleGenerateVideo(result.projectId);

      }
    } catch (error) {
      console.error('Error generating project:', error);
      // Handle error case
    }
  };


  useEffect(() => {
    if (!renderId) return;

    pusherRef.current = new Pusher('a7eac2ac287fcd5e92ea', {
      cluster: 'us2',
      encrypted: true
    });


    channelRef.current = pusherRef.current.subscribe('video-renders');

    channelRef.current.bind('render-update', function(data) {
      console.log('pusher data', data);
      if (`${data.renderId}` === `${renderId}`) {
        
        const endTime = performance.now();
        const duration = endTime - videoGenerationStartTime;
        setVideoGenerationTime(duration);
        console.log(`Video generation took ${duration.toFixed(2)} milliseconds`);
        const isLibraryVoice = selectedLibraryVoice?true:false
        let libraryVoiceName
        if(isLibraryVoice){
          libraryVoiceName=selectedLibraryVoice.label
        }
        const newVariantsGeneratedCount=variantsGeneratedCount+1
        setVariantsGeneratedCount(newVariantsGeneratedCount)
        if(data.status=='failed'){
          Mixpanel.track('Video generation failed', {
            projectId: projectId,
            renderId:renderId,
            queryParams:queryParams,
            generateProjectTime: Math.round(generateNewProjectTime / 1000),
            renderVideoTime: Math.round(duration / 1000),
            totalTime: Math.round((duration + generateNewProjectTime) / 1000),
            isLibraryVoice:isLibraryVoice,
            libraryVoiceName:libraryVoiceName,
            variantsGeneratedCount:newVariantsGeneratedCount
          });
        setVideoFailed(true)
        }else{
          setVideoReady(true)
          setAssetURL(data.resultUrl);
          Mixpanel.track('Video generated succeeded', {
            projectId: projectId,
            queryParams:queryParams,
            generateProjectTime: Math.round(generateNewProjectTime / 1000),
            renderVideoTime: Math.round(duration / 1000),
            totalTime: Math.round((duration + generateNewProjectTime) / 1000),
            isLibraryVoice:isLibraryVoice,
            libraryVoiceName:libraryVoiceName,
            variantsGeneratedCount:newVariantsGeneratedCount,
            resultUrl:data.resultUrl
          });
        }     
       disconnectPusher();  
    } else {
      console.log(`code 132`)
       // console.log('its the wrong render id or it failed');
      }
    });
    return () => {
       disconnectPusher();
    };
  }, [renderId]);

  const disconnectPusher = () => {
    if (channelRef.current) {
      channelRef.current.unbind_all();
      channelRef.current.unsubscribe();
   }
    if (pusherRef.current) {
      pusherRef.current.disconnect();
    }
  };

//2
const handleGenerateVideo = async (newProjectId) => {
    const startTime = performance.now();
    setVideoGenerationStartTime(startTime)
    const queryParams = getQueryParams();
    
    try {
      const result = await generateVideo(newProjectId, queryParams);
      setRenderId(result.renderId)
      const endTime = performance.now();
      const duration = endTime - startTime;
      setVideoGenerationTime(duration);
      console.log('Video generation request:', result);
    } catch (error) {
      console.error('Error generating video:', error);
      // Handle error case
    }
  };

   const handleDownload = async () => {
    try {
      const response = await fetch(assetURL);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // Get the file name from the URL or use a default name
      const fileName = assetURL.split('/').pop() || 'downloaded-video.mp4';
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      goTo('editChoices')
    } catch (error) {
      console.error('Download failed:', error);
      alert('Download failed. Please try again.');
    }
  };

  const retryVideoGeneration=()=>{
    setVideoReady(false)
    setVideoFailed(false)
    setVideoProgress(0)
    setGeneratedProjectId(null)
    setGenerateNewProjectTime(null)
    setVideoGenerationTime(null)
    setRenderId(null)
    setAssetURL(null)
    setVideoGenerationStartTime(null)
    handleGenerateNewProject()
  }

  // const startProgressBar = useCallback(() => {
  //   setVideoProgress(0); // Reset progress to 0
  //   const interval = setInterval(() => {
  //     setVideoProgress((prevProgress) => {
  //       if (prevProgress < 100) {
  //         return prevProgress + 0.5;
  //       } else {
  //         clearInterval(interval);
  //         return prevProgress;
  //       }
  //     });
  //   }, 35000/200);

  //   return () => clearInterval(interval);
  // }, []);



  return (
    <>
      <div className='flow-title'>
        {!videoReady && 
          <div className='flow-title-h1'>
            Preparing your video
          </div>
        }
        {videoReady && 
          <div className='flow-title-h1'>
            Video Ready!
          </div>
        }
      </div>          
      <div className='flow-content'>
        {!videoReady &&
          <div className='flow-content-h3'> 
            Your download link appear here once it's finished generating.
          </div>
        }
      </div>

      <div className='flow-footer'>                  
        {!videoReady &&  !videoFailed &&                  
          <button disabled className='flow-submitBtn flow-submitBtn--progress'> 
            <div className='flow-submitBtn--progress-spinnerContainer'>
              <div className='flow-submitBtn--progress-spinner' />
            </div>
          </button>                                 
        }

        {videoReady && !videoFailed &&                
          <button onClick={handleDownload} className='flow-submitBtn flow-submitBtn--ready'> 
            Download Video
          </button>                                 
        }
        {videoFailed &&                
          <button onClick={retryVideoGeneration} className='flow-submitBtn flow-submitBtn--ready'> 
            Something went wrong- try again?
          </button>                                 
        }


      </div>
    </>
  );
};

export default GeneratingVideo;
