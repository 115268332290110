import React, { useState, useRef, useEffect } from 'react';
import Icon from '../Icon'
import backgroundMusicTracks from '../../utils/backgroundMusicTracks'
import prodBackgroundMusicTracks from '../../utils/prodBackgroundMusicTracks'

const SelectBGMusicGrid = ({ selectNext, smallSize,selectedBGMusic,setSelectedBGMusic,projectId,isProd  }) => {
  const [playingMusic, setPlayingMusic] = useState(null);
  const audioRefs = useRef({});


  const getTransformedUrl = (src, startTime = 0) => {
    return `https://res.cloudinary.com/yarn/video/upload/so_${startTime},eo_${startTime + 10}/br_192k/${src}.mp3`;
  };

  const stopAllAudio = () => {
    Object.values(audioRefs.current).forEach(audio => {
      audio.pause();
      audio.currentTime = 0;
    });
    setPlayingMusic(null);
  };

  useEffect(() => {
    let tracks = backgroundMusicTracks
    if(isProd){
      tracks = prodBackgroundMusicTracks
    }
    // Preload all audio files
    tracks.forEach(option => {
      const audio = new Audio();
      audio.src = getTransformedUrl(option.src, option.startTime);

      audio.preload = 'auto';
      audioRefs.current[option.id] = audio;
       audio.addEventListener('ended', () => {
        setPlayingMusic(null);
      });
    });

    // Add event listener for tab blur
    const handleVisibilityChange = () => {
      if (document.hidden) {
        stopAllAudio();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function
    return () => {
      stopAllAudio();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      Object.values(audioRefs.current).forEach(audio => {
        audio.src = '';
      });
    };
  }, []);

  const handlePlayPause = (id) => {
    if (playingMusic === id) {
      audioRefs.current[id].pause();
      setPlayingMusic(null);
    } else {
      // Stop the current track if any
      stopAllAudio();
      // Play the new track
      audioRefs.current[id].currentTime = 0;
      audioRefs.current[id].play();
      setPlayingMusic(id);
    }
  };

    // Convert projectId to number to ensure type consistency
  const numericProjectId = Number(projectId);

  let filteredAndSortedTracks 
  if(isProd){
    filteredAndSortedTracks = prodBackgroundMusicTracks
      .sort((a, b) => a.title.localeCompare(b.title));
  }else{
    filteredAndSortedTracks = backgroundMusicTracks
      .filter(track => track.projectIds.includes(numericProjectId))
      .sort((a, b) => a.title.localeCompare(b.title));
  }

  return (
    <div data-small-size={smallSize} className="selectGrid selectGrid--music">
      {filteredAndSortedTracks.map((track) => (
        <div
          key={track.id}
          data-active-state={selectedBGMusic === track.id ? 'active' : 'inactive'}
          className='selectGrid-btn selectGrid-btn--music'          
        >
          <div className='selectGrid-btn-inner'>
            
            <div className='selectGrid-btn--music-title'>
              {track.title}
            </div>
            {track.subtitle &&
            <div className='selectGrid-btn--music-subtitle'>
              {track.subtitle}
            </div>
            }
            <div className='selectGrid-btn--music-vSpacer'>

            </div>
            
            <button 
              className='selectGrid-btn-playPauseBtn'
              onClick={() => handlePlayPause(track.id)}
            >
              {playingMusic === track.id ? <Icon name='playbackPause' /> : <Icon name='playbackPlay' />}
            </button>

            {selectedBGMusic === track.id &&
              <div className='selectGrid-btn--voice-activeIconContainer'>
                <Icon name='checkmark' />
              </div>
            }

            <div className='selectGrid-btn-activeBorder' />

            <button 
              onClick={() => setSelectedBGMusic(track.id)}
              onDoubleClick={selectNext}
              className='selectGrid-btn--music-bgButton' 
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default SelectBGMusicGrid;