import { useState, useRef, useEffect } from 'react';
import Pusher from 'pusher-js';
import { generateVideo } from '../utils/creatorPage/generateVideo';
import { captureRenderTimeout, captureProjectCreationFailed } from '../utils/creatorPage/sentryEvents';
import voicesData from '../utils/voicesData'
import backgroundMusicTracks from '../utils/backgroundMusicTracks'
import backgroundColorOptions from '../utils/backgroundColorOptions'
import { useDispatch } from 'react-redux';
import {generateProjectForCreatorSession} from '../actions/session'
import {voiceMatchSettings,libraryVoiceSettings} from '../utils/creatorPage/clayCreatorPageConfigs'

function getVoiceFor(id) {
  const voice = voicesData.find(v => v.id === id);
  return voice
}

function getBackgroundMusicFor(id) {
  const music = backgroundMusicTracks.find(v => v.id === id);
  return music
}

function getBackgroundColorOptionsForId(id) {
  const options = backgroundColorOptions.find(v => v.id === id);
  return options.options
}

const useVideoGeneration = (sessionId,projectId, customizations, selectedLibraryVoice, voiceMatchId, showCaptions,variableWebcams,projectPageSettings,webcamProjectSelectedVoiceId,isMacOSBackground,showExtendedVersion) => {
  const [videoReady, setVideoReady] = useState(false);
  const [videoFailed, setVideoFailed] = useState(false);
  const [generatedProjectId, setGeneratedProjectId] = useState(null);
  const [videoGenerationTime, setVideoGenerationTime] = useState(null);
  const [assetURL, setAssetURL] = useState(null);
  const [videoGenerationStartTime, setVideoGenerationStartTime] = useState(null);
  const [generatingVideo, setGeneratingVideo] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showError, setShowError] = useState(false);

  const dispatch = useDispatch();
  const pusherRef = useRef(null);
  const channelRef = useRef(null);

  const disconnectPusher = () => {
    if (channelRef.current) {
      channelRef.current.unbind_all();
      channelRef.current.unsubscribe();
    }
    if (pusherRef.current) {
      pusherRef.current.disconnect();
    }
  };

  const resetVideoGenState = () => {
    setVideoReady(false);
    setVideoFailed(false);
    setGeneratedProjectId(null);
    setVideoGenerationTime(null);
    setAssetURL(null);
    setVideoGenerationStartTime(null);
    setGeneratingVideo(false);
    disconnectPusher();
  };

  const constructVoiceSettings = () => {
    if (selectedLibraryVoice) {
      const voiceData = getVoiceFor(selectedLibraryVoice);
      if (voiceData) {
        const voiceId = voiceData.providerId;
        return {
          ...libraryVoiceSettings,
          stability: voiceData.stability ?? libraryVoiceSettings.stability,
          similarityBoost: voiceData.similarityBoost ?? libraryVoiceSettings.similarityBoost,
          styleExaggeration: voiceData.styleExaggeration ?? libraryVoiceSettings.styleExaggeration,
          speakerBoost: voiceData.speakerBoost ?? libraryVoiceSettings.speakerBoost,
          model_id: voiceData.modelId ?? libraryVoiceSettings.model_id,
          providerId: voiceData.providerId,
          voiceId:voiceData.id
        };
      }
    }else {
      return {
        ...voiceMatchSettings,
        providerId: voiceMatchId,
        voiceId:voiceMatchId
      };
    }
  }

  const constructBackgroundOptions = () => {
    const options = {};
    // Only include background music if it's enabled in project settings
    if (projectPageSettings?.enableSelectBGMusic) {
      const bgTrack = getBackgroundMusicFor(customizations.selectedBGMusic);
      if (bgTrack?.trackId) {
        options.backgroundMusicTrack = bgTrack.trackId;
      }
    }
    // Only include background color if it's enabled in project settings
    if (projectPageSettings?.enableSelectBGColor) {
      const bgColorOptions = getBackgroundColorOptionsForId(customizations.selectedBGColor);
      if (bgColorOptions?.prod) {
        options.projectBGColor = bgColorOptions.prod;
      }
      if(isMacOSBackground){
        options.projectBGColor = bgColorOptions.macOS || bgColorOptions.prod;
      }
    }
    return options;
  };


  const getSubdomain = (hostname) => {
    // Special handling for localhost
    if (hostname.includes('localhost')) {
      const parts = hostname.split('.');
      if (parts.length > 1) {
        return parts[0];
      }
    }
    // Regular domain handling
    return hostname.split('.').slice(0, -2);
  }

  

  const handleGenerateNewProject = async (queryParams) => {
    setShowError(false);
    setIsDownloading(false);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setGeneratingVideo(true);
    const startTime = performance.now();
    try {
      const backgroundOptions = constructBackgroundOptions();
      const hasVariableWebcam = variableWebcams && variableWebcams.length>0
      let params=queryParams
      if(showExtendedVersion){
        params.section = 'advanced'
      }

      const subdomain = getSubdomain(window.location.hostname);
  
      if(subdomain && subdomain=='dev'){ //TEMP show notion logo on dev subdomain
        params.useNotionBrand=true
      }


      console.log(params)
      let options = {
        ...backgroundOptions,
       // showCaptions:hasVariableWebcam?false:showCaptions,
        showCaptions:showCaptions,
        queryParams,
        hasVariableWebcam,
        voiceSettings: hasVariableWebcam ? voiceMatchSettings : constructVoiceSettings(),
        webcamProjectSelectedVoiceId:webcamProjectSelectedVoiceId
      };

     
     // console.log(options)

     
      const result = await dispatch(generateProjectForCreatorSession(sessionId,options))
      if (result && result.projectId) {
        setGeneratedProjectId(result.projectId);
      }
    } catch (error) {
      captureProjectCreationFailed(error);
      console.error('Error generating project:', error);
      setGeneratingVideo(false);
    }
  };


  useEffect(() => {
    if (!generatedProjectId) return;
    pusherRef.current = new Pusher('a7eac2ac287fcd5e92ea', {
      cluster: 'us2',
      encrypted: true
    });
    channelRef.current = pusherRef.current.subscribe('video-renders');
    channelRef.current.bind('render-update', function(data) {
      if (`${data.projectId}` === `${generatedProjectId}`) {
        if(data.status=='failed'){
          setVideoFailed(true)
        }else if(data.status=='completed'){
        setVideoReady(true)
          setAssetURL(data.resultUrl);
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          disconnectPusher();
       }
      } 
    });
    return () => {
      disconnectPusher();
    };
  }, [generatedProjectId]);

  const handleDownload = async () => {
    setIsDownloading(true);
    try {
      const response = await fetch(assetURL);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      const fileName = assetURL.split('/').pop() || 'downloaded-video.mp4';
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download failed:', error);
      alert('Download failed. Please try again.');
    }
    setIsDownloading(false);
  };

  return {
    videoReady,
    videoFailed,
    generatingVideo,
    assetURL,
    isDownloading,
    showError,
    handleGenerateNewProject,
    handleDownload,
    setShowError,
    resetVideoGenState,
  };
};

export default useVideoGeneration;
