import {post,fetch,put} from '../apiProd'



export const createSession = (projectId, queryParams,requestUrl) => {
  const requestBody={
    projectId,
    queryParams,
    requestUrl
  }

  return (dispatch) => {
    return post(`/creator/session`,requestBody)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log(error)
        return error
      })
  }
}

export const fetchSession = (sessionId) => {
  return (dispatch) => {
    return fetch(`/creator/session/${sessionId}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log('Error fetching session:', error);
        throw error;
      })
  }
}


export const updateSession = (sessionId, { customizations, webcamCaptures }) => {
  return (dispatch) => {
    return put(`/creator/session/${sessionId}`, {
      customizations,
      webcamCaptures
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log('Error updating session:', error);
        throw error;
      })
  }
}



export const generateProjectForCreatorSession = (sessionId,options) => {
  return async (dispatch) => {
    try {
      const response = await post(`/creator/session/${sessionId}/generate`,options);

      return response
    } catch (error) {
      console.error('Error uploading voice sample:', error);
      dispatch({ 
        type: 'SUBMIT_VOICE_MATCH_SAMPLE_FAILURE',
        payload: error.toString()
      });
      return { success: false, error: error.toString() };
    }
  };
};
