import React from 'react';
import * as Popover from '@radix-ui/react-popover';
import Icon from '../../Icon';

function VideoPlayerControlsPlaybackRate({ playbackRate, setPlaybackRate }) {
  //const playbackRates = [0.8, 1, 1.2, 1.5, 1.7, 2, 2.5];
  const playbackRates = [0.8, 1.0, 1.2, 1.4];

  const formatRate = (rate) => rate.toFixed(1);


  return (
    
    <>
      <div className='videoPlayer-playbackRate'>
      {playbackRates.map((rate) => (
          <button
            key={rate}
            data-state={rate === playbackRate ? "active" : "inactive"}
            className='videoPlayer-playbackRate-btn'
            onClick={() => {setPlaybackRate(rate)}}
          >            
            <div className='videoPlayer-playbackRate-btn-label'>
              {formatRate(rate)}
            </div>
            <div className='videoPlayer-playbackRate-btn-iconContainer'>
              <Icon name='multiplySemibold' />
            </div>
          </button>
        ))}
      </div>

    {/*
    <Popover.Root modal={true}>
      <Popover.Trigger asChild>
        <button className="videoPlayer-controls-btn videoPlayer-controls-btn--playbackRate">
          <div className='videoPlayer-controls-btn--playbackRate-label'>
            {playbackRate}
          </div>
          <div className='videoPlayer-controls-btn--playbackRate-rateIcon'>
            
          </div>
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content 
          className="videoPlayer-playbackRatePopover"
          side="top"
        >
          <div className="videoPlayer-playbackRatePopover-btnList">
            {playbackRates.map((rate) => (
              <button
                key={rate}
                data-state={rate === playbackRate ? "active" : "inactive"}
                className='videoPlayer-playbackRatePopover-btn'
                onClick={() => {setPlaybackRate(rate)}}
              >
                {rate}x
              </button>
            ))}
          </div>          
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>*/}
    </>
  );
}

export default VideoPlayerControlsPlaybackRate;