import React from 'react';
import Icon from '../../Icon'
import { useNavigate } from 'react-router-dom';
import {formatTimestamp} from '../../../utils/formatTimestamp'

const CampaignHomePageDoneCardReady = ({variant}) => {
  const navigate = useNavigate();
  const personalizationData=variant.personalization_data
  let prospectName,prospectCompany 
  
  if(personalizationData){
    prospectName=personalizationData.prospectName
    prospectCompany=personalizationData.prospectOrg
  }

  const shareLink = `/share/${variant.yarn_share_id}`;

  const handleCardClick = () => {
    const fullShareLink = `${window.location.origin}${shareLink}`;
    window.open(fullShareLink, '_blank', 'noopener,noreferrer');
  };

  const handleCopyLinkClick = (e) => {
    e.stopPropagation(); // This prevents the event from bubbling up to parent elements
    const fullShareLink = `${window.location.origin}${shareLink}`;
    navigator.clipboard.writeText(fullShareLink)
      .then(() => {
        console.log('Link copied to clipboard');
      })
      .catch(err => {
        console.error('Failed to copy link: ', err);
      });
  };


  let timestampLabel = formatTimestamp(variant.updated_at)

  const muxPlaybackId = variant.mux_playback_id

  const time = 3
  const posterImageURL = `https://image.mux.com/${muxPlaybackId}/thumbnail.webp?width=960&time=${time}`
  const gifImageURL = `https://image.mux.com/${muxPlaybackId}/animated.webp?width=640&time=${time}`
  

  return (

    <div onClick={handleCardClick} data-state='ready' className='campaignHomePage-doneCard'>
      <div className='campaignHomePage-doneCard-preview'>
        <div className='campaignHomePage-doneCard-preview-border' />
        <div className='campaignHomePage-doneCard-preview-imgContainer campaignHomePage-doneCard-preview-imgContainer--static'>
          <img className='campaignHomePage-doneCard-preview-img' src={posterImageURL} />
        </div>
        
        <div className='campaignHomePage-doneCard-preview-imgContainer campaignHomePage-doneCard-preview-imgContainer--animated'>
          <img className='campaignHomePage-doneCard-preview-img' src={gifImageURL} />
        </div>
        
      </div>

      <div className='campaignHomePage-doneCard-footer'>
        <div className='campaignHomePage-doneCard-footer-left'>
          <div className='campaignHomePage-doneCard-footer-label'>
            <div className='campaignHomePage-doneCard-footer-label-name'>
              {prospectName}
            </div>
            <div className='campaignHomePage-doneCard-footer-label-company'>
              {prospectCompany}
            </div>                  
          </div>
          <div className='campaignHomePage-doneCard-footer-timestamp'>
            {timestampLabel}
          </div>    
        </div>
        <div className='campaignHomePage-doneCard-footer-right'>
          <div onClick={handleCopyLinkClick} className='campaignHomePage-doneCard-footer-copyLinkButton'>
            <Icon name='link' />
          </div>    
        </div>
      </div>      
    </div>    
  );
};

export default CampaignHomePageDoneCardReady;