import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { submitVoiceMatchSample } from '../actions/voiceMatch';

const VoiceRecorder = ({ projectId, onVoiceMatchSuccess }) => {

  const [hasMicrophonePermission, setHasMicrophonePermission] = useState(false);


 const checkMicrophonePermission = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    // If we get here, permission was granted
    stream.getTracks().forEach(track => track.stop());
    setHasMicrophonePermission(true);
    return true;
  } catch (error) {
    console.error('Error accessing the microphone', error);
    setHasMicrophonePermission(false);
    return false;
  }
};


const requestMicrophonePermission = async () => {
  const permissionGranted = await checkMicrophonePermission();
  if (permissionGranted) {
    console.log('Microphone permission granted');
  } else {
    console.log('Microphone permission denied');
  }
};



  const dispatch = useDispatch();
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState('');
  const [audioBlob, setAudioBlob] = useState(null);
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);
  const mediaStream = useRef(null);

  const startRecording = async () => {
    audioChunks.current = [];
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStream.current = stream;
      mediaRecorder.current = new MediaRecorder(stream);

      mediaRecorder.current.ondataavailable = (event) => {
        audioChunks.current.push(event.data);
        console.log('Data available:', event.data);
      };

      mediaRecorder.current.onstop = () => {
        console.log('Recording stopped');
        const blob = new Blob(audioChunks.current, { type: 'audio/webm' });
        console.log('Blob:', blob);
        const url = URL.createObjectURL(blob);
        setAudioURL(url);
        setAudioBlob(blob);

        // Stop all tracks to release the media stream and remove the red dot
        stream.getTracks().forEach(track => track.stop());
      };

      mediaRecorder.current.start();
      setIsRecording(true);
      console.log('Recording started');
    } catch (error) {
      console.error('Error accessing the microphone', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && isRecording) {
      mediaRecorder.current.stop();
      setIsRecording(false);
    }
  };

  const reRecord = () => {
    setAudioURL('');
    setAudioBlob(null);
  };

  const submitRecording = async () => {
    if (!audioBlob) return;

    try {
      const result = await dispatch(submitVoiceMatchSample(projectId, audioBlob));
      if (result.success) {
       // alert('Voice sample uploaded successfully!');
        onVoiceMatchSuccess(result.voiceId);
        setAudioURL('');
        setAudioBlob(null);
      } else {
       // alert('Failed to upload voice sample. Please try again.');
      }
    } catch (error) {
      console.error('Error in submitRecording:', error);
     // alert('An unexpected error occurred. Please try again.');
    }
  };

  return (
    <div>
      Read this text
      <br/>
      In a bustling city where the echoes of courtrooms and whispers of justice intertwined, prosecutors had opened a massive investigation into allegations of fixing games and illegal betting. The legal system, like different telescope designs, aimed to expose the truth, each method possessing unique strengths and weaknesses.
As the investigation unfolded, the need to strengthen the education of good lawyers became evident. 



      <br/>

      {!hasMicrophonePermission &&

      <button onClick={requestMicrophonePermission}>give microphone permission! </button>

    }


      {!audioBlob ? (
        <button onClick={isRecording ? stopRecording : startRecording}>
          {isRecording ? 'Stop Recording' : 'Start Recording'}
        </button>
      ) : (
        <>
          <audio src={audioURL} controls onError={(e) => console.error('Audio playback error:', e)} />
          <button onClick={reRecord}>Re-record</button>
          <button onClick={submitRecording}>Submit Recording</button>
        </>
      )}
    </div>
  );
};

export default VoiceRecorder;
