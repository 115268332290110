import React, {useState} from 'react'
import Icon from '../../Icon';
import VideoPlayerSeekBar from './VideoPlayerSeekBar';
import VideoPlayerSeekBarBasic from './VideoPlayerSeekBarBasic';
import VideoDuration from './videoDuration';
import VideoPlayerControlsVolumeSlider from './VideoPlayerControlsVolumeSlider'
import VideoPlayerControlsPlaybackRate from './VideoPlayerControlsPlaybackRate'

function VideoPlayerControls({
    playbackId,
    playing,
    onPlayPauseClick,    
    progress,
    onSeek,
    onSeekMouseDown,
    onSeekMouseUp,
    duration,
    showCaptions,
    toggleShowCaptions,
    showControls,
    volume,
    muted,
    onVolumeChange,
    onToggleMute,
    handleToggleFullscreen,
    isFullscreen,
    playbackRate,
    setPlaybackRate,
    showTimelinePreview
  }) {


  return (
    <>

    <div data-visible-state={showControls ? true : false} className='videoPlayer-controlsContainer'>

      <div className='videoPlayer-controls'>  
          {showTimelinePreview &&       
            <VideoPlayerSeekBar 
              playbackId={playbackId}
              playing={playing}
              progress={progress}
              onSeek={onSeek}
              onSeekMouseDown={onSeekMouseDown}
              onSeekMouseUp={onSeekMouseUp}
              duration={duration}              
            />        
          }
          {!showTimelinePreview &&       
            <VideoPlayerSeekBarBasic
              playbackId={playbackId}
              playing={playing}
              progress={progress}
              onSeek={onSeek}
              onSeekMouseDown={onSeekMouseDown}
              onSeekMouseUp={onSeekMouseUp}
              duration={duration}              
            /> 
          }
        <div className='videoPlayer-controls-btnBar'>
          <button onClick={onPlayPauseClick} className='videoPlayer-controls-playPauseBtn'>
            {playing && 
              <Icon name='playbackPause' />
            }
            {!playing && 
              <Icon name='playbackPlay' />
            }
          </button>

          <div className='videoPlayer-controls-timestamps'>
            <div className='videoPlayer-controls-timestamps-timestamp videoPlayer-controls-timestamps-timestamp--current'>              
              <VideoDuration seconds={progress * duration} />
            </div>
            <div className='videoPlayer-controls-timestamps-timestamp videoPlayer-controls-timestamps-timestamp--total'>
              <VideoDuration seconds={duration} />
            </div>
          </div>

          <div className='videoPlayer-controls-hSpacer' />


          <VideoPlayerControlsPlaybackRate
            playbackRate={playbackRate}
            setPlaybackRate={setPlaybackRate}            
          />
          
          <VideoPlayerControlsVolumeSlider 
            volume={volume}
            muted={muted}
            onVolumeChange={onVolumeChange}
            onToggleMute={onToggleMute}
          />

          
          {/*} Add later*/}
          
          {/*}
          <button onClick={toggleShowCaptions} className='videoPlayer-controls-btn videoPlayer-controls-btn--showCaptions'>
            {showCaptions && <Icon name='playbackCCFill' />}
            {!showCaptions && <Icon name='playbackCC' />}
          </button>
          */}

          

          {/*}
          <button onClick={handleToggleFullscreen} className='videoPlayer-controls-btn videoPlayer-controls-btn--zoom'>
            <Icon name='playbackZoomOnly' />            
          </button>
          */}

        </div>


      </div>
            
    </div>

    <div data-visible-state={showControls ? true : false} className='videoPlayer-controlsCover' />

    

    </>
  );
}

export default VideoPlayerControls;