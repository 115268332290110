import React, { useCallback } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import Icon from '../../Icon'
import VideoRecordModalStartStopBtn from './VideoRecordModalStartStopBtn'

const cleanupLabel = (label) => {
  return label.replace(/\s*\(.*?\)\s*/g, '').trim();
};

const SettingsDropdown = ({ 
  options, 
  value, 
  onChange, 
  iconName, 
  selectHideVideo,
  setSelectHideVideo,
  isVideo
}) => {
  const activeOption = options && options.find(option => option.value === value);
  const buttonLabel = activeOption ? cleanupLabel(activeOption.label) : 'Select...';
  
  const handleSelect = useCallback((optionValue) => {
    onChange(optionValue);
  }, [onChange]);

  const handleHideVideo = useCallback(() => {
    setSelectHideVideo(!selectHideVideo);
  }, [selectHideVideo, setSelectHideVideo]);

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild>
        <button className='videoRecordModal--instructions-toolbar-settings-btn'>          
            <Icon name={iconName} />          
        </button>
      </DropdownMenu.Trigger>
      
      <DropdownMenu.Content 
        align="start" 
        alignOffset={-20} 
        sideOffset={2} 
        side={'top'} 
        collisionPadding={10} 
        className='dropdownMenu'
      >
        {/* Regular device options */}
        {options && options.map((option) => (
          <DropdownMenu.Item
            key={option.value}
            onSelect={() => handleSelect(option.value)}
            className={`dropdownMenu-item ${option.value === value ? 'dropdownMenu-item--active' : 'dropdownMenu-item--inactive'}`}
          >
            {cleanupLabel(option.label)}
          </DropdownMenu.Item>
        ))}
        
        {isVideo && (
          <>
            <DropdownMenu.Separator className="dropdownMenu-separator" />
            <DropdownMenu.Item
              onSelect={handleHideVideo}
              className='dropdownMenu-item'
            >
              {selectHideVideo ? 'Generate with webcam' : 'Hide webcam (audio-only)'}
            </DropdownMenu.Item>
          </>
        )}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};


const VideoRecordModalInstructionsToolbar = ({ 
  isRecording,
  onRestart,
  onStartRecording,
  onOpenChange,
  onStopRecording,
  activeCamera, 
  setActiveCamera, 
  activeMicrophone, 
  setActiveMicrophone,
  cameras,
  microphones,
  isReadyToRecord,
  isDisplayRecording,
  selectHideVideo,
  setSelectHideVideo,
  countdownActive,
}) => {
  return (
    <div className='videoRecordModal--instructions-toolbar'>      
      
      <div data-visibility-state={(isDisplayRecording || countdownActive) ? 'hidden' : 'visible'} className='videoRecordModal--instructions-toolbar-settings'>        
        
        {/*}
        <SettingsDropdown
          options={cameras}
          value={activeCamera}
          onChange={setActiveCamera}
          setSelectHideVideo={setSelectHideVideo}
          iconName="cameraRegular"
          isVideo                 
        />
        <SettingsDropdown
          options={microphones}
          value={activeMicrophone}
          onChange={setActiveMicrophone}
          iconName="microphoneRegular"
        />
        */}
      </div>
      
      <button onClick={onRestart} data-visibility-state={isDisplayRecording ? 'visible' : 'hidden'} className='videoRecordModal--instructions-toolbar-restartBtn'>        
        <Icon name='playbackRestart' />
      </button>

      <VideoRecordModalStartStopBtn 
        isRecording={isRecording}
        onStartRecording={onStartRecording}
        onStopRecording={onStopRecording}
        isReadyToRecord={isReadyToRecord}
        isDisplayRecording={isDisplayRecording}
        countdownActive={countdownActive}
      />        
    
      
      <button data-visibility-state={(isDisplayRecording || countdownActive) ? 'hidden' : 'visible'} onClick={() => onOpenChange(false)} className='videoRecordModal--instructions-toolbar-cancelBtn'>        
        Cancel
      </button>      
            
    </div>        
  );
};

export default VideoRecordModalInstructionsToolbar;