// VideoRecordModalInstructionsWizard.js
import React, { useState, useRef, useEffect, useMemo } from 'react';
import VideoRecordModalInstructionsWizardTabBar from './VideoRecordModalInstructionsWizardTabBar'
import VideoRecordModalInstructionsPanel from './VideoRecordModalInstructionsPanel';
import VideoRecordModalInstructionsWizardPanel from './VideoRecordModalInstructionsWizardPanel'
import VideoRecordModalInstructionsToolbar from './VideoRecordModalInstructionsToolbar';
import VideoRecordModalInstructionsWizardPreRecordToolbar from './VideoRecordModalInstructionsWizardPreRecordToolbar'
import VideoRecordModalInstructionsWizardExample from './VideoRecordModalInstructionsWizardExample'

const VIDEO_GAP = 20;
const PLAYBACK_CONTROLS_HEIGHT = 50;
const VIDEO_TITLE_HEIGHT = 30;
const VIDEOS_TOP_MARGIN = 0;
const VIDEOS_BOTTOM_MARGIN = 50;
const VIDEOS_OUTER_SIDE_PADDING = 10;

const calculateVideoDimensions = (centerWidth, centerHeight) => {
  const INPUT_VIDEO_ASPECT_RATIO = 4/4;
  const GENERATED_VIDEO_ASPECT_RATIO = 16/9;
  
  const availableHeight = centerHeight - VIDEO_TITLE_HEIGHT - PLAYBACK_CONTROLS_HEIGHT - VIDEOS_TOP_MARGIN - VIDEOS_BOTTOM_MARGIN;
  const availableWidth = centerWidth - VIDEO_GAP - VIDEOS_OUTER_SIDE_PADDING - VIDEOS_OUTER_SIDE_PADDING;
  
  const totalAspectRatio = INPUT_VIDEO_ASPECT_RATIO + GENERATED_VIDEO_ASPECT_RATIO;
  const calculatedHeight = availableWidth / totalAspectRatio;
  
  const finalHeight = Math.min(calculatedHeight, availableHeight);
  
  const inputVideoWidth = finalHeight * INPUT_VIDEO_ASPECT_RATIO;
  const generatedVideoWidth = finalHeight * GENERATED_VIDEO_ASPECT_RATIO;
  
  return {
    height: finalHeight,
    inputVideoWidth,
    generatedVideoWidth
  };
};

const VideoRecordModalInstructionsWizard = ({
  videoRef,
  isRecording,
  onStartRecording,
  onStopRecording,
  onRestart,
  onOpenChange,
  activeCamera,
  setActiveCamera,
  activeMicrophone,
  setActiveMicrophone,
  cameras,
  microphones,
  instructions,
  isReadyToRecord,
  isDisplayRecording,
  selectHideVideo,
  setSelectHideVideo 
}) => {
  const [activeTab, setActiveTab] = useState('example');  
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const measureRef = useRef(null);

  const videoDimensions = useMemo(() => 
    calculateVideoDimensions(dimensions.width, dimensions.height),
    [dimensions.width, dimensions.height]
  ) || { height: 0, inputVideoWidth: 0, generatedVideoWidth: 0 }; // Add default value here

  useEffect(() => {
    if (!measureRef.current) return;

    const observer = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (!measureRef.current) return;
        
        setDimensions({
          width: measureRef.current.offsetWidth,
          height: measureRef.current.offsetHeight
        });
      });
    });

    observer.observe(measureRef.current);
    return () => observer.disconnect();
  }, []);

  const title = "Intro yourself" // title of webcam

  console.log('selectHideVideo', selectHideVideo)
  return (
    <>
      <div className='videoRecordModal--instructions-tabPanel'>
        <div className='videoRecordModal--instructions-tabPanel-title'>
          {title}
        </div>        
        <VideoRecordModalInstructionsWizardTabBar
          activeTab={activeTab}
          setActiveTab={(value) => setActiveTab(value)}
        />              
      </div>

      <div data-hide-recording={selectHideVideo ? true : false} className="videoRecordModal--instructions-center" ref={measureRef}>
        {/*}
        {activeTab === 'instructions' &&         
          <VideoRecordModalInstructionsWizardPanel 
            instructions={instructions}
            selectHideVideo={selectHideVideo}
          />        
        }
        */}


        {activeTab === 'example' && videoDimensions.height &&
          <VideoRecordModalInstructionsWizardExample 
            dimensions={videoDimensions}
            videoGap={VIDEO_GAP}
            videoTitleHeight={VIDEO_TITLE_HEIGHT}
            playbackControlsHeight={PLAYBACK_CONTROLS_HEIGHT}
            videosTopMargin={VIDEOS_TOP_MARGIN}
            videosBottomMargin={VIDEOS_BOTTOM_MARGIN}
          />        
        }

               
          <div data-visibility-state={activeTab === 'record' ? 'visible' : 'hidden'} className='videoRecordModal--instructions-center-recordContainer'>
            <VideoRecordModalInstructionsPanel instructions={instructions} selectHideVideo={selectHideVideo}/>              
            <div className="videoRecordModal--instructions-preview">
              <video 
                ref={videoRef} 
                autoPlay 
                playsInline 
                muted
                className="videoRecordModal--instructions-preview-video"
              />
            </div>        
          </div> 
        
      </div>

      {(activeTab === 'instructions' || activeTab === 'example') && 
        <VideoRecordModalInstructionsWizardPreRecordToolbar
          // goNext={() => {
          //   if (activeTab === 'example') {
          //     setActiveTab('instructions');
          //   } else if (activeTab === 'instructions') {
          //     setActiveTab('record');
          //   }
          // }} 
          goNext={() => {
            if (activeTab === 'example') {
              setActiveTab('record');
            } else if (activeTab === 'instructions') {
              setActiveTab('record');
            }
          }}          
        />
      }

      {activeTab === 'record' && 
        <VideoRecordModalInstructionsToolbar 
          isRecording={isRecording}
          onStartRecording={onStartRecording}
          onStopRecording={onStopRecording}
          onRestart={onRestart}
          onOpenChange={onOpenChange}
          activeCamera={activeCamera}
          setActiveCamera={setActiveCamera}
          activeMicrophone={activeMicrophone}
          setActiveMicrophone={setActiveMicrophone}
          cameras={cameras}
          microphones={microphones}
          isReadyToRecord={isReadyToRecord}
          isDisplayRecording={isDisplayRecording}
          selectHideVideo={selectHideVideo}
          setSelectHideVideo={setSelectHideVideo}
        />
      }
    </>
  );
};

export default VideoRecordModalInstructionsWizard;