import {fetch} from '../api'


export const fetchVariantByShareId = (shareId) => {
  return fetch(`/campaign-variant/share/${shareId}`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error
    })
}


export const fetchRenderJobByShareId = (shareId) => {
  return fetch(`/render-job-status/${shareId}`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error
    })
}




