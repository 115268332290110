import React, { useState } from 'react';
import Icon from '../Icon'

const TextInput = ({placeholder, maxLength, autoFocus,value,handleChange}) => {
  // const [value, setValue] = useState('');

  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };

  const required = true

  const nonEmpty = value.length > 0

    
  return (
    <div className="textInput">
      <input 
        className="textInput-input"
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        maxLength={maxLength}
        autoFocus={autoFocus}
      />      
      <div data-visibility-state={nonEmpty && required ? 'visible' : 'hidden'} className='textInput-activeIconContainer'>
        <Icon name='checkmark' />
      </div>
    </div>
  );
};

export default TextInput;