import React from 'react';
import * as Popover from '@radix-ui/react-popover';
import Icon from '../../Icon';

function VideoPlayerControlsPlaybackRate({ playbackRate, setPlaybackRate }) {
  const playbackRates = [0.8, 1, 1.2, 1.5, 1.7, 2, 2.5];

  return (
    <Popover.Root modal={true}>
      <Popover.Trigger asChild>
        <button className="videoPlayer-controls-btn videoPlayer-controls-btn--playbackRate">
          <div className='videoPlayer-controls-btn--playbackRate-label'>
            {playbackRate}
          </div>
          <div className='videoPlayer-controls-btn--playbackRate-rateIcon'>
            
          </div>
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content 
          className="videoPlayer-playbackRatePopover"
          side="top"
        >
          <div className="videoPlayer-playbackRatePopover-btnList">
            {playbackRates.map((rate) => (
              <button
                key={rate}
                data-state={rate === playbackRate ? "active" : "inactive"}
                className='videoPlayer-playbackRatePopover-btn'
                onClick={() => {setPlaybackRate(rate)}}
              >
                {rate}x
              </button>
            ))}
          </div>          
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

export default VideoPlayerControlsPlaybackRate;