import React, { useState, useEffect } from 'react';

const CampaignVariantProspectCardCompanyLogo = ({ logoSrc }) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const targetArea = 4000; // Target area in square pixels

  useEffect(() => {
    const getImageDimensions = (url) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = function() {
          resolve({ width: img.width, height: img.height });
        };
        img.onerror = reject;
        img.src = url;
      });
    };

    const scaleImage = async () => {
      try {
        const { width, height } = await getImageDimensions(logoSrc);
        const aspectRatio = width / height;
        const newHeight = Math.sqrt(targetArea / aspectRatio);
        const newWidth = newHeight * aspectRatio;
        setDimensions({
          width: Math.round(newWidth),
          height: Math.round(newHeight)
        });
      } catch (error) {
        console.error("Error loading image:", error);
      }
    };
    scaleImage();
  }, [logoSrc]);

  return (
    <div className="campaignVariantPage-prospectCard-right">
      <img
        src={logoSrc}
        width={dimensions.width}
        height={dimensions.height}
        alt="Company Logo"
        style={{ objectFit: 'contain' }}
      />
    </div>
  );
};

export default CampaignVariantProspectCardCompanyLogo;