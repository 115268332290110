import React, { useState } from 'react';
import Icon from '../../Icon';

const VideoPreviewPlayerPreControls = ({duration, exampleVideo, instructionsVideo}) => {
  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };
  
  return (          
    <div data-example-video={(exampleVideo || instructionsVideo) ? true : false} className='page-videoPreviewPlayer-preControls'>
      
      {exampleVideo && 
        <div className='page-videoPreviewPlayer-preControls-duration'>
          Watch Video
        </div>
      }

      {instructionsVideo && 
        <div className='page-videoPreviewPlayer-preControls-duration'>
          Watch Instructions
        </div>
      }

      {!exampleVideo && !instructionsVideo &&
        <>
        <div className='page-videoPreviewPlayer-preControls-iconContainer'>
          <Icon name='playbackPlay' />
        </div>
        <div className='page-videoPreviewPlayer-preControls-duration'>
          {formatDuration(duration)}
        </div>
        </>
      }
    </div>
  );
};

export default VideoPreviewPlayerPreControls;