
import axios from 'axios';

const CHUNK_SIZE = 20 * 1024 * 1024; 


export const uploadToS3 = async (blob, uploadURL) => {
  try {
    const response = await axios.put(uploadURL, blob, {
      headers: {
        'Content-Type': 'video/webm',
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        console.log(`Upload progress: ${percentCompleted}%`);
      },
    });
    console.log('Upload to S3 successful', response);
  } catch (error) {
    console.error('Error uploading to S3:', error);
    throw error;
  }
};

export const uploadLargeFileToS3 = async (blob, uploadURL) => {
  const totalChunks = Math.ceil(blob.size / CHUNK_SIZE);
  let uploadedChunks = 0;

  for (let i = 0; i < totalChunks; i++) {
    const start = i * CHUNK_SIZE;
    const end = Math.min(start + CHUNK_SIZE, blob.size);
    const chunk = blob.slice(start, end);
    const chunkNumber = i + 1;
    const contentRange = `bytes ${start}-${end - 1}/${blob.size}`;

    try {
      await axios.put(uploadURL, chunk, {
        headers: {
          'Content-Type': 'video/mp4',
          'Content-Range': contentRange,
          'x-amz-part-number': chunkNumber,
        },
      });
      uploadedChunks++;
      const percentCompleted = Math.round((uploadedChunks / totalChunks) * 100);
      console.log(`Chunk ${chunkNumber}/${totalChunks} uploaded successfully (${percentCompleted}% complete)`);
    } catch (error) {
      console.error(`Error uploading chunk ${chunkNumber}:`, error);
      throw error;
    }
  }
  console.log('All chunks uploaded successfully');
};


export const getUploadProgress = (uploadedBytes, totalBytes) => {
  return Math.round((uploadedBytes / totalBytes) * 100);
};