import { useState} from 'react';
import { addWebcamToCreatorSession } from '../actions/webcam';
import { useDispatch } from 'react-redux';
import {randomID} from '../utils/randomID'
import { convertMedia } from '@remotion/webcodecs';
import { webFileReader } from '@remotion/media-parser/web-file';


const getStableBlob = async (rawBlob) => {
  try {
    const stableBlob = await convertMedia({
      src: rawBlob,
      container: 'webm',
      reader: webFileReader,
    });
    return stableBlob.save()
  } catch (error) {
    console.error(
      'Error converting blob using WebCodecs, falling back to original blob:',
      error
    );
  }
}

const getStableBlobFromUrl = async (url) => {
  try {
    const stableBlob = await convertMedia({
      src: url,
      container: 'webm',
    });
    return stableBlob.save()
  } catch (error) {
    console.error(
      'Error converting blob using WebCodecs, falling back to original blob:',
      error
    );
  }
  }


function useWebcams(variableWebcams, sessionId,addWebcamCaptureToSession,updateWebcamCaptureInSession,resetVideoGenState) {
  const dispatch = useDispatch();

  const [webcamStatuses, setWebcamStatuses] = useState(() => {
    return variableWebcams.reduce((acc, webcam) => ({
      ...acc,
      [webcam.id]: { state: 'waiting' }
    }), {});
  });


const initializeWebcamStateFromSession = async (webcamCaptures) => {

  // Initialize all webcams to waiting state if webcamCaptures is empty
  if (!webcamCaptures || Object.keys(webcamCaptures).length === 0) {
    const initialStatuses = variableWebcams.reduce((acc, webcam) => ({
      ...acc,
      [webcam.id]: { state: 'waiting' }
    }), {});
    setWebcamStatuses(initialStatuses);
    return;
  }

  const newStatuses = { ...webcamStatuses }

  // Process each webcam sequentially
  for (const webcam of variableWebcams) {
    if (webcamStatuses[webcam.id]?.recording) {
      continue;
    }

    const captures = webcamCaptures[webcam.id] || []
    const mostRecentCapture = captures[captures.length - 1]

    if (mostRecentCapture) {
      try {
        // Update status to loading for this specific webcam
        setWebcamStatuses(prev => ({
          ...prev,
          [webcam.id]: { state: 'loading' }
        }))

        const seekableBlob = await getStableBlobFromUrl(
          `https://yarn-assets.s3.us-east-1.amazonaws.com/webcamRecordings/${mostRecentCapture.captureId}/recording_original.webm`
        )
        const url = URL.createObjectURL(seekableBlob)

        // Update status for this webcam after loading
        newStatuses[webcam.id] = {
          state: 'complete',
          captureId: mostRecentCapture.captureId,
          recording: url,
          creatorEdits: mostRecentCapture.creatorEdits
        }

        // Update the state after each webcam is processed
        setWebcamStatuses(newStatuses)
      } catch (error) {
        console.error(`Error loading webcam ${webcam.id}:`, error)
        newStatuses[webcam.id] = {
          state: 'error',
          error: error.message
        }
        setWebcamStatuses(newStatuses)
      }
    } else {
      newStatuses[webcam.id] = { state: 'waiting' }
      setWebcamStatuses(newStatuses)
    }
  }
}

const handleUpdateTrim = async (webcamId, trimStart, trimEnd,duration) => {
  resetVideoGenState()
  try {    
    const status = webcamStatuses[webcamId];
    if (!status?.captureId) return;
    setWebcamStatuses(prev => ({
      ...prev,
      [webcamId]: {
        ...prev[webcamId],
        creatorEdits: {
          ...prev[webcamId].creatorEdits,
          trimStart,
          trimStartTime:trimStart*duration,
          trimEnd,
          trimEndTime:trimEnd*duration
        }
      }
    }));

    // Update the session with new creator edits
    await updateWebcamCaptureInSession(
      webcamId,
      status.captureId,
      {
        ...status.creatorEdits,
        trimStart,
        trimStartTime:trimStart*duration,
        trimEnd,
        trimEndTime:trimEnd*duration
      }
    );

  } catch (error) {
    console.error('Error updating trim points:', error);
  }
};

  ///We finished a recording lets upload it
  //videoWasDisabled is true if creator chose to do audio only
  const onRecordingComplete = async (webcamId, blob,creatorSetAudioOnly) => {
    try {
      if(resetVideoGenState){
        resetVideoGenState()
      }
      const captureId=randomID()
      console.log('captureId',captureId)
      const stableBlob = await getStableBlob(blob)
      const url = URL.createObjectURL(stableBlob);
      // Update status to uploading
      setWebcamStatuses(prev => ({
        ...prev,
        [webcamId]: { 
          ...prev[webcamId], 
          state: 'uploading',
          recording: url,
          captureId:captureId,
          creatorEdits:{audioOnly:creatorSetAudioOnly|| false}
        }
      }));


      // Find the webcam variable data
      const webcam = variableWebcams.find(w => w.id === webcamId);

      if (!webcam) {
        throw new Error(`Webcam with id ${webcamId} not found`);
      }

      const result = await dispatch(addWebcamToCreatorSession({
        blob:stableBlob,
        captureId,
        sessionId
      }))

      await addWebcamCaptureToSession(webcamId, captureId, {
        status: 'complete',
        creatorEdits:{audioOnly:creatorSetAudioOnly}
      });


      setWebcamStatuses(prev => ({
        ...prev,
        [webcamId]: { 
          ...prev[webcamId], 
          state: 'complete',
        }
      }));
    } catch (error) {
      setWebcamStatuses(prev => ({
        ...prev,
        [webcamId]: { 
          ...prev[webcamId], 
          state: 'error',
          error: error.message
        }
      }));
    }
  };

  const allWebcamsComplete = 
    Object.keys(webcamStatuses).length > 0 && // Ensure we have webcams
    Object.keys(webcamStatuses).length === variableWebcams.length && // Ensure all webcams are accounted for
    Object.values(webcamStatuses).every(status => status.state === 'complete');


  // Additional helper to check if any webcams are in error state
  const hasErrors = Object.values(webcamStatuses).some(
    status => status.state === 'error'
  );


  return {
    webcamStatuses,
    allWebcamsComplete,
    hasErrors,
    onRecordingComplete,
    initializeWebcamStateFromSession,
    handleUpdateTrim
  };
}

export default useWebcams;