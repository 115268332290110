import React, { useState } from 'react';
import VideoRecordModalInstructionsPanel from './VideoRecordModalInstructionsPanel';
import VideoRecordModalInstructionsToolbar from './VideoRecordModalInstructionsToolbar';

const VideoRecordModalInstructionsWizardTabBarTab = ({
  activeTab,
  setActiveTab,
  tab,
  label
}) => {  

  return (
    <>
      <button onClick={() => setActiveTab(tab)} data-active-state={activeTab === tab ? 'active' : 'inactive'} className='videoRecordModal--instructions-tabPanel-tab'>
        {label}
      </button>
    </>
  );
};

const VideoRecordModalInstructionsWizardTabBar = ({
  activeTab,
  setActiveTab,
}) => {  

  // tab options
  // instructions
  // example 

  return (
    <>      
      <VideoRecordModalInstructionsWizardTabBarTab 
        tab="example"
        label="Example"
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {/*}
      <VideoRecordModalInstructionsWizardTabBarTab 
        tab="instructions"
        label="Instructions"
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />      
      */}
      <VideoRecordModalInstructionsWizardTabBarTab 
        tab="record"
        label="Record"
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </>
  );
};

export default VideoRecordModalInstructionsWizardTabBar;