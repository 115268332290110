import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import Icon from '../../Icon'
import { useMicrophoneLevel } from './useMicrophoneLevel';
import { useAudioMuteDetection } from './useAudioMuteDetection';

const cleanupLabel = (label) => {
  return label.replace(/\s*\(.*?\)\s*/g, '').trim();
};

const CameraDropdown = ({ 
  options, 
  value, 
  onChange, 
  iconName, 
  selectHideVideo,
  setSelectHideVideo  
}) => {
  const activeOption = options && options.find(option => option.value === value);
  const buttonLabel = activeOption ? cleanupLabel(activeOption.label) : 'Select...';
  
  const handleSelect = useCallback((optionValue) => {
    onChange(optionValue);
  }, [onChange]);

  const handleHideVideo = useCallback(() => {
    setSelectHideVideo(!selectHideVideo);
  }, [selectHideVideo, setSelectHideVideo]);

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild>
        <button className='videoRecordModal--instructions-preview-inputDevices-camera'>          
            <Icon name='cameraRegular' />          
        </button>
      </DropdownMenu.Trigger>
      
      <DropdownMenu.Content 
        align="start" 
        alignOffset={0} 
        sideOffset={-2} 
        side={'bottom'} 
        collisionPadding={10} 
        className='dropdownMenu'
      >
        {/* Regular device options */}
        {options && options.map((option) => (
          <DropdownMenu.Item
            key={option.value}
            onSelect={() => handleSelect(option.value)}
            className={`dropdownMenu-item ${option.value === value ? 'dropdownMenu-item--active' : 'dropdownMenu-item--inactive'}`}
          >
            {cleanupLabel(option.label)}
          </DropdownMenu.Item>
        ))}
                
        <DropdownMenu.Separator className="dropdownMenu-separator" />
        <DropdownMenu.Item
          onSelect={handleHideVideo}
          className='dropdownMenu-item'
        >
          {selectHideVideo ? 'Generate with webcam' : 'Hide webcam (audio-only)'}
        </DropdownMenu.Item>
          
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};


const MicrophoneDropdown = ({ 
  options, 
  value, 
  onChange,  
  mediaStream 
}) => {
  const activeOption = options && options.find(option => option.value === value);
  const buttonLabel = activeOption ? cleanupLabel(activeOption.label) : 'Select...';
  
  const microphoneInput = useMicrophoneLevel(mediaStream);

  const volumeSegmentCount = 10;
  
  // Calculate which segments should be active based on microphoneInput
  const segments = useMemo(() => {
    return Array.from({ length: volumeSegmentCount }, (_, index) => {
      const segmentThreshold = (index + 1) / volumeSegmentCount;
      return microphoneInput * 1.2 >= segmentThreshold;
    });
  }, [microphoneInput, volumeSegmentCount]);  


  const handleSelect = useCallback((optionValue) => {
    onChange(optionValue);
  }, [onChange]);  

  
  

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild>
        <button className='videoRecordModal--instructions-preview-inputDevices-microphone'>          
          <div className='videoRecordModal--instructions-preview-inputDevices-microphone-iconContainer'>                      
            <Icon name='microphoneRegular' />                      
          </div>
          <div className='videoRecordModal--instructions-preview-inputDevices-microphone-label'>
            {buttonLabel}
          </div>
          <div className='videoRecordModal--instructions-preview-inputDevices-microphone-hSpacer' />
          
          

          
          <div className='videoRecordModal--instructions-preview-inputDevices-microphone-segmentedVolumeContainer'>
             {segments.map((isActive, index) => (
              <div 
                key={index}
                data-state={isActive ? 'active' : 'inactive'} 
                className='videoRecordModal--instructions-preview-inputDevices-microphone-segmentedVolume' 
              />
            ))}
          </div>          
        </button>
      </DropdownMenu.Trigger>
      
      <DropdownMenu.Content 
        align="start" 
        alignOffset={0} 
        sideOffset={2} 
        side={'top'} 
        collisionPadding={10} 
        className='dropdownMenu dropdownMenu--microphoneSelection'
      >
        {/* Regular device options */}
        {options && options.map((option) => (
          <DropdownMenu.Item
            key={option.value}
            onSelect={() => handleSelect(option.value)}
            className={`dropdownMenu-item ${option.value === value ? 'dropdownMenu-item--active' : 'dropdownMenu-item--inactive'}`}
          >
            {cleanupLabel(option.label)}
          </DropdownMenu.Item>
        ))}
          
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};


const VideoRecordModalInstructionsInputDevices = ({
  isReadyToRecord,
  isDisplayRecording,
  selectHideVideo,
  setSelectHideVideo,
  activeCamera,
  setActiveCamera,
  activeMicrophone,
  setActiveMicrophone,
  cameras,
  microphones,
  mediaStream,
  countdownActive
}) => {

  const microphoneInput = useMicrophoneLevel(mediaStream);
  const isMuted = useAudioMuteDetection(microphoneInput, 1000); // 2 seconds
  const showMuteAlert = isMuted
  

  return (
    <>
      {/* showalert here */}
      <div data-visibility-state={(isDisplayRecording || countdownActive) ? 'hidden' : 'visible'} className='videoRecordModal--instructions-preview-inputDevices'>            
        <CameraDropdown 
          options={cameras}
          value={activeCamera}
          onChange={setActiveCamera}
          setSelectHideVideo={setSelectHideVideo}                              
        />      
        <MicrophoneDropdown 
          options={microphones}
          value={activeMicrophone}
          onChange={setActiveMicrophone}
          iconName="microphoneRegular"
          mediaStream={mediaStream}
        />              
      </div>

      <div data-visibility={showMuteAlert ? 'visible' : 'hidden'} className='videoRecordModal--instructions-preview-inputDevicesMuteAlert'>
        <div className='videoRecordModal--instructions-preview-inputDevicesMuteAlert-iconContainer'>
          <Icon name='alert' />
        </div>
        <div className='videoRecordModal--instructions-preview-inputDevicesMuteAlert-label'>
          No audio detected
        </div>
      </div>          


    </>
  );
};

export default VideoRecordModalInstructionsInputDevices;