import React, { useState } from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import Icon from '../Icon';
import { formatTimestamp } from '../../utils/formatTimestamp';

const ClayOnboardingTitle = () => {
  let videoTitle;
  let timestampLabel;
   
  videoTitle = `Welcome to Clay`

  return (
    <Tooltip.Provider delayDuration={0}>
      <div className="deliveryPage-title">
        <div className="deliveryPage-title-left">                
          <div className="deliveryPage-title-details">            
            <div className="deliveryPage-title-details-title">
              {videoTitle}
            </div>                
          </div>
        </div>
        <div className="deliveryPage-title-right">
          <div className="deliveryPage-title-timestamp">
            {timestampLabel}
          </div>       
          <button className='deliveryPage-showCalendarBtn'>
            <div className='deliveryPage-showCalendarBtn-iconContainer'>
              <Icon name='calendar' />
            </div>
            <div className='deliveryPage-showCalendarBtn-label'>
              Calendar
            </div>
          </button>          
        
        </div>
      </div>
    </Tooltip.Provider>
  );
};

export default ClayOnboardingTitle;