import React, { useState, useRef, useEffect } from 'react';
import { Rnd } from 'react-rnd';

const HANDLE_WIDTH = 10;

const CampaignVariantPlaybackTrimBar = ({ onTrimChange, onSeek }) => {
  const [startTrimProgress, setStartTrimProgress] = useState(0);
  const [endTrimProgress, setEndTrimProgress] = useState(1);
  const [containerWidth, setContainerWidth] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef(null);
  const startRndRef = useRef(null);
  const endRndRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth);
    }
  }, []);

  const calculateProgress = (position) => {
    return Math.max(0, Math.min(1, position / containerWidth));
  };

  const updateRenderedPositions = (startX, endX) => {
    let newStart = calculateProgress(startX + HANDLE_WIDTH);
    let newEnd = calculateProgress(endX);

    if (newEnd < newStart) {
      newStart = newEnd;
    }

    setStartTrimProgress(newStart);
    setEndTrimProgress(newEnd);
    onTrimChange(newStart, newEnd);
  };

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDrag = (e, d, isStart) => {
    const startX = isStart ? d.x : startTrimProgress * containerWidth - HANDLE_WIDTH;
    const endX = isStart ? endTrimProgress * containerWidth : d.x;
    updateRenderedPositions(startX, endX);

    if (isStart) {
      onSeek(calculateProgress(startX + HANDLE_WIDTH));
    }
  };

  const handleDragStop = (e, d, isStart) => {
    setIsDragging(false);
    const startX = isStart ? d.x : startTrimProgress * containerWidth - HANDLE_WIDTH;
    const endX = isStart ? endTrimProgress * containerWidth : d.x;
    updateRenderedPositions(startX, endX);

    // Update RND handles to their correct positions
    if (startRndRef.current) {
      startRndRef.current.updatePosition({ x: startTrimProgress * containerWidth - HANDLE_WIDTH, y: 0 });
    }
    if (endRndRef.current) {
      endRndRef.current.updatePosition({ x: endTrimProgress * containerWidth, y: 0 });
    }
  };

  const handleStyle = {
    cursor: isDragging ? 'grabbing' : 'grab'
  };

  return (
    <div className='campaignVariantPage-playback-toolbar-trimBar-trimbarWrapper'>
      <div 
        ref={containerRef}
        className="campaignVariantPage-playback-toolbar-trimBar-trimbar"      
      >
        <Rnd
          ref={startRndRef}
          size={{ width: HANDLE_WIDTH, height: '100%' }}
          position={{ x: startTrimProgress * containerWidth - HANDLE_WIDTH, y: 0 }}
          onDragStart={handleDragStart}
          onDrag={(e, d) => handleDrag(e, d, true)}
          onDragStop={(e, d) => handleDragStop(e, d, true)}
          enableResizing={false}
          className='campaignVariantPage-playback-toolbar-trimBar-trimbar-handle campaignVariantPage-playback-toolbar-trimBar-trimbar-handle--rnd campaignVariantPage-playback-toolbar-trimBar-trimbar-handle--rnd--start'
          style={handleStyle}
        />
        
        <Rnd
          ref={endRndRef}
          size={{ width: HANDLE_WIDTH, height: '100%' }}
          position={{ x: endTrimProgress * containerWidth, y: 0 }}
          onDragStart={handleDragStart}
          onDrag={(e, d) => handleDrag(e, d, false)}
          onDragStop={(e, d) => handleDragStop(e, d, false)}
          enableResizing={false}
          className='campaignVariantPage-playback-toolbar-trimBar-trimbar-handle campaignVariantPage-playback-toolbar-trimBar-trimbar-handle--rnd'
          style={handleStyle}
        />
                
        <div 
          className='campaignVariantPage-playback-toolbar-trimBar-trimbar-handle campaignVariantPage-playback-toolbar-trimBar-trimbar-handle--render campaignVariantPage-playback-toolbar-trimBar-trimbar-handle--render--start'
          style={{position: 'absolute', left: `${startTrimProgress * 100}%`, width: `${HANDLE_WIDTH}px`, marginLeft: `${HANDLE_WIDTH * -1}px`}} 
        >
          <div className='campaignVariantPage-playback-toolbar-trimBar-trimbar-handle-line' />
          <div className='campaignVariantPage-playback-toolbar-trimBar-trimbar-handle-line' />
        </div>
        <div 
          className='campaignVariantPage-playback-toolbar-trimBar-trimbar-handle campaignVariantPage-playback-toolbar-trimBar-trimbar-handle--render campaignVariantPage-playback-toolbar-trimBar-trimbar-handle--render--end'
          style={{position: 'absolute', left: `${endTrimProgress * 100}%`, width: `${HANDLE_WIDTH}px`}}         
        >
          <div className='campaignVariantPage-playback-toolbar-trimBar-trimbar-handle-line' />
          <div className='campaignVariantPage-playback-toolbar-trimBar-trimbar-handle-line' />
        </div>

        <div className='campaignVariantPage-playback-toolbar-trimBar-trimbar-border campaignVariantPage-playback-toolbar-trimBar-trimbar-border--top' style={{position: 'absolute', left: `${startTrimProgress * 100}%`, width: `${(endTrimProgress - startTrimProgress) * 100}%`}} />

        <div className='campaignVariantPage-playback-toolbar-trimBar-trimbar-border campaignVariantPage-playback-toolbar-trimBar-trimbar-border--bottom' style={{position: 'absolute', left: `${startTrimProgress * 100}%`, width: `${(endTrimProgress - startTrimProgress) * 100}%`}} />

        
      </div>
    </div>
  );
};

export default CampaignVariantPlaybackTrimBar;