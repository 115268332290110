import Pusher from 'pusher-js'

const PUSHER_KEY='b11320b204b927369764'

Pusher.Runtime.createXHR = function () {
	var xhr = new XMLHttpRequest();
	xhr.withCredentials = true;
	return xhr;
};

// Pusher.logToConsole = true 

const pusher = new Pusher(PUSHER_KEY, {
	encrypted: true,
	cluster: "us2",
})

export default pusher