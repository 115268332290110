// VideoPreviewPlayerEditing.jsx
import React, {useState, useRef, useEffect} from 'react';
import Icon from '../../Icon';
import ReactSlider from 'react-slider';
import { Rnd } from 'react-rnd';

const HANDLE_WIDTH = 10;
const MIN_TRIM_WIDTH = 50; // Minimum width in pixels between handles

function VideoPreviewPlayerEditing({    
  playing,
  onPlayPauseClick,
  progress,
  onSeek,      
  duration,
  showControls,
  editable,
  setEditing,
  onTrimChange,
  onSeekMouseDown,
  onSeekMouseUp,
  trimStart,
  trimEnd,
  // New callback:
  onEditingComplete
}) {

  const [startTrimProgress, setStartTrimProgress] = useState(trimStart);
  const [endTrimProgress, setEndTrimProgress] = useState(trimEnd);
  const [containerWidth, setContainerWidth] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef(null);
  const startRndRef = useRef(null);
  const endRndRef = useRef(null);

  useEffect(() => {
    setStartTrimProgress(trimStart);
    setEndTrimProgress(trimEnd);
  }, [trimStart, trimEnd]);

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth);
    }
  }, []);

  const calculateProgress = (position) => {
    return Math.max(0, Math.min(1, position / containerWidth));
  };

  const updateRenderedPositions = (startX, endX) => {
    let newStart = calculateProgress(startX + HANDLE_WIDTH);
    let newEnd = calculateProgress(endX);
  
    const minProgressWidth = MIN_TRIM_WIDTH / containerWidth;
    
    if (newEnd - newStart < minProgressWidth) {
      if (isDragging) {
        if (startX !== startTrimProgress * containerWidth - HANDLE_WIDTH) {
          newStart = newEnd - minProgressWidth;
        } else {
          newEnd = newStart + minProgressWidth;
        }
      } else {
        newStart = newEnd - minProgressWidth;
      }
    }

    if (newEnd < newStart) {
      newStart = newEnd;
    }

    newStart = Math.max(0, Math.min(1 - minProgressWidth, newStart));
    newEnd = Math.max(minProgressWidth, Math.min(1, newEnd));

    setStartTrimProgress(newStart);
    setEndTrimProgress(newEnd);
  };

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDrag = (e, d, isStart) => {
    const startX = isStart ? d.x : startTrimProgress * containerWidth - HANDLE_WIDTH;
    const endX = isStart ? endTrimProgress * containerWidth : d.x;

    const newStart = calculateProgress(startX + HANDLE_WIDTH);
    const newEnd = calculateProgress(endX);
    const minProgressWidth = MIN_TRIM_WIDTH / containerWidth;

    // Check if we would violate minimum width
    const wouldViolateMin = newEnd - newStart < minProgressWidth;

    updateRenderedPositions(startX, endX);

    if (isStart && !wouldViolateMin) {
      onSeek(calculateProgress(startX + HANDLE_WIDTH));
    }
  };

  const handleDragStop = (e, d, isStart) => {
    setIsDragging(false);
    const startX = isStart ? d.x : startTrimProgress * containerWidth - HANDLE_WIDTH;
    const endX = isStart ? endTrimProgress * containerWidth : d.x;
    updateRenderedPositions(startX, endX);

    if (startRndRef.current) {
      startRndRef.current.updatePosition({ x: startTrimProgress * containerWidth - HANDLE_WIDTH, y: 0 });
    }
    if (endRndRef.current) {
      endRndRef.current.updatePosition({ x: endTrimProgress * containerWidth, y: 0 });
    }
  };

  const handleDone = () => {
    onTrimChange(startTrimProgress, endTrimProgress);
    setEditing(false);
    if (onEditingComplete) {
      onEditingComplete();
    }
  };

  const handleCancel = () => {
    setEditing(false);
    if (onEditingComplete) {
      onEditingComplete();
    }
  };

  const handleStyle = {
    cursor: isDragging ? 'grabbing' : 'grab'
  };

  return (
    <>
      <div className='page-videoPreviewPlayer-controls page-videoPreviewPlayer-controls--editing'>
        <button onClick={onPlayPauseClick} className='page-videoPreviewPlayer-controls-playPauseBtn'>
          {playing && <Icon name='playbackPause' />}
          {!playing && <Icon name='playbackPlay' />}
        </button>

        <div className='page-videoPreviewPlayer-controls-trimbar'>
          <div className='page-videoPreviewPlayer-controls-trimbar-trimbarWrapper'>
            <div ref={containerRef} className="page-videoPreviewPlayer-controls-trimbar-trimbar">
              <Rnd
                ref={startRndRef}
                size={{ width: HANDLE_WIDTH, height: '100%' }}
                position={{ x: startTrimProgress * containerWidth - HANDLE_WIDTH, y: 0 }}
                onDragStart={handleDragStart}
                onDrag={(e, d) => handleDrag(e, d, true)}
                onDragStop={(e, d) => handleDragStop(e, d, true)}
                enableResizing={false}
                className='page-videoPreviewPlayer-controls-trimbar-trimbar-handle page-videoPreviewPlayer-controls-trimbar-trimbar-handle--rnd page-videoPreviewPlayer-controls-trimbar-trimbar-handle--rnd--start'
                style={handleStyle}
              />
              
              <Rnd
                ref={endRndRef}
                size={{ width: HANDLE_WIDTH, height: '100%' }}
                position={{ x: endTrimProgress * containerWidth, y: 0 }}
                onDragStart={handleDragStart}
                onDrag={(e, d) => handleDrag(e, d, false)}
                onDragStop={(e, d) => handleDragStop(e, d, false)}
                enableResizing={false}
                className='page-videoPreviewPlayer-controls-trimbar-trimbar-handle page-videoPreviewPlayer-controls-trimbar-trimbar-handle--rnd'
                style={handleStyle}
              />
                      
              <div 
                className='page-videoPreviewPlayer-controls-trimbar-trimbar-handle page-videoPreviewPlayer-controls-trimbar-trimbar-handle--render page-videoPreviewPlayer-controls-trimbar-trimbar-handle--render--start'
                style={{
                  position: 'absolute', 
                  left: `${startTrimProgress * 100}%`, 
                  width: `${HANDLE_WIDTH}px`, 
                  marginLeft: `${HANDLE_WIDTH * -1}px`
                }} 
              >
                <div className='page-videoPreviewPlayer-controls-trimbar-trimbar-handle-line' />
                <div className='page-videoPreviewPlayer-controls-trimbar-trimbar-handle-line' />
              </div>
              <div 
                className='page-videoPreviewPlayer-controls-trimbar-trimbar-handle page-videoPreviewPlayer-controls-trimbar-trimbar-handle--render page-videoPreviewPlayer-controls-trimbar-trimbar-handle--render--end'
                style={{
                  position: 'absolute', 
                  left: `${endTrimProgress * 100}%`, 
                  width: `${HANDLE_WIDTH}px`
                }}         
              >
                <div className='page-videoPreviewPlayer-controls-trimbar-trimbar-handle-line' />
                <div className='page-videoPreviewPlayer-controls-trimbar-trimbar-handle-line' />
              </div>

              <div 
                className='page-videoPreviewPlayer-controls-trimbar-trimbar-border page-videoPreviewPlayer-controls-trimbar-trimbar-border--top' 
                style={{
                  position: 'absolute', 
                  left: `${startTrimProgress * 100}%`, 
                  width: `${(endTrimProgress - startTrimProgress) * 100}%`
                }} 
              />

              <div 
                className='page-videoPreviewPlayer-controls-trimbar-trimbar-border page-videoPreviewPlayer-controls-trimbar-trimbar-border--bottom' 
                style={{
                  position: 'absolute', 
                  left: `${startTrimProgress * 100}%`, 
                  width: `${(endTrimProgress - startTrimProgress) * 100}%`
                }} 
              />

              <div className='page-videoPreviewPlayer-controls-trimbar-trimbar-backgroundBG' /> 

              <div 
                className='page-videoPreviewPlayer-controls-trimbar-trimbar-foregroundBG'
                style={{
                  position: 'absolute', 
                  left: `${startTrimProgress * 100}%`, 
                  width: `${(endTrimProgress - startTrimProgress) * 100}%`
                }} 
              />
            </div>
          </div>        
          <div className='page-videoPreviewPlayer-controls-trimbar-seekbar'>
            <ReactSlider
              className="page-videoPreviewPlayer-controls-trimbar-seekbar-seekbar"
              thumbClassName="page-videoPreviewPlayer-controls-trimbar-seekbar-seekbar-thumb"
              trackClassName="page-videoPreviewPlayer-controls-trimbar-seekbar-seekbar-track"                  
              renderThumb={(props, state) => {
                const { key, ...restProps } = props;
                return <div key={key} className='videoPlayer-seekBar-seekbar-thumb-thumb' {...restProps}></div>;
              }}        
              value={progress}
              min={0}
              max={0.999999}
              step={0.0001}
              onAfterChange={onSeekMouseUp}
              onBeforeChange={onSeekMouseDown}
              onChange={onSeek}
            />  
          </div>
        </div>
        
        <div className='page-videoPreviewPlayer-controls-rightBtnSet'>
          <button onClick={handleDone} className='page-videoPreviewPlayer-controls-doneBtn'>            
            Done
          </button>

          <button onClick={handleCancel} className='page-videoPreviewPlayer-controls-cancelBtn'>            
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}

export default VideoPreviewPlayerEditing;
