import React, { useState, useEffect } from 'react';

const CampaignVariantRecordToolbarCountdown = ({ status, setStatus }) => {
  const [activeNumber, setActiveNumber] = useState(3);
  const [countdownComplete, setCountdownComplete] = useState(false);
  const COUNTDOWN_INTERVAL = 1000; // 1 second
  const HOLD_DURATION = 200; // 1 second hold after countdown

  useEffect(() => {
    if (status === 'countdown' && !countdownComplete) {
      const countdownInterval = setInterval(() => {
        setActiveNumber((prevNumber) => {
          if (prevNumber === 1) {
            clearInterval(countdownInterval);
            setCountdownComplete(true);
            return 0; // Use 0 to indicate countdown finished
          }
          return prevNumber - 1;
        });
      }, COUNTDOWN_INTERVAL);

      return () => clearInterval(countdownInterval);
    }
  }, [status, countdownComplete]);

  useEffect(() => {
    if (countdownComplete) {
      const holdTimeout = setTimeout(() => {
        setStatus('recording');
        setActiveNumber(3); // Reset for next countdown
        setCountdownComplete(false);
      }, HOLD_DURATION);

      return () => clearTimeout(holdTimeout);
    }
  }, [countdownComplete, setStatus]);

  return (
    <>
      {status === 'countdown' && (
        <div className='campaignVariantPage-record-countdown'>
          {[3, 2, 1].map((number) => (
            <div
              key={number}
              data-active-state={activeNumber === number ? 'true' : 'false'}
              className='campaignVariantPage-record-countdown-number'
            >
              {number}
            </div>
          ))}          
        </div>
      )}
    </>
  );
};

export default CampaignVariantRecordToolbarCountdown;