import React from 'react';
import { useSpring, animated, config } from 'react-spring';

const Tab = ({ label, isActive, isComplete, tabWidth, activeIndex }) => {
  
  const standardWeakOpacity = 0.25
  const lightWeakOpacity = 0.125
  
  let weakOpacity = standardWeakOpacity

  if(label === 'Intro' && activeIndex > 0){
    weakOpacity = lightWeakOpacity
  }
  if(label === 'Outro' && activeIndex > 1){
    weakOpacity = lightWeakOpacity
  }

  const outerProps = useSpring({
    opacity: isActive ? 1 : weakOpacity,
    config: { tension: 120, friction: 24, clamp: true }
  });

  const activeCompleteTabColor = '#4584EA';
  // console.log('label', label)
  // console.log('isComplete', isComplete)
  const innerProps = useSpring({
    backgroundColor: isComplete
      ? (isActive ? activeCompleteTabColor : 'black')
      : 'black',
    config: { tension: 120, friction: 24, clamp: true }
  });

  return (
    <animated.div
      className='campaignVariantPage-tab'      
      style={{
        ...outerProps,
        width: `${tabWidth}px`
      }}
    >    
      <animated.div 
        className='campaignVariantPage-tab-inner'
        style={innerProps}
      >
        <div className='campaignVariantPage-tab-inner-label'>          
          {label}
        </div>
      </animated.div>
    </animated.div>
  );
};

const CampaignVariantFooter = ({ status, tabs, campaign }) => {
  const fade = status === 'recording' || status === 'countdown';
  const tabWidth = 110; // Width of each tab in pixels
  const containerWidth = 600; // Width of the tabs container

  const activeIndex = tabs.findIndex(tab => tab.isActive);
  const totalWidth = tabs.length * tabWidth;
  const offset = (containerWidth - tabWidth) / 2;
  const translateX = activeIndex * tabWidth - offset;

  const translateProps = useSpring({
    transform: `translateX(${-translateX}px)`,
    config: { tension: 120, friction: 24, delay: 50 }
  });

  return (
    <div data-fade-state={fade ? 'fade' : 'default'} className="campaignVariantPage-footer">
      <div className="campaignVariantPage-footer-left" />
      <div className="campaignVariantPage-footer-center">      
        <div className="campaignVariantPage-tabsContainer" style={{ width: `${containerWidth}px`, overflow: 'hidden' }}>
          <animated.div 
            className="campaignVariantPage-tabs" 
            style={{              
              width: `${totalWidth}px`,
              ...translateProps
            }}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                tabWidth={tabWidth}
                label={tab.label}                
                isActive={tab.isActive}
                isComplete={tab.isComplete}
                activeIndex={activeIndex}
              />
            ))}
          </animated.div>
        </div>
      </div>
      <div className="campaignVariantPage-footer-right" />    
    </div>
  );
};

export default CampaignVariantFooter;