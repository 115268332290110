import findIndex from 'lodash/findIndex'



export default function (state = null, action) {
	switch (action.type) {
	

    case 'SIGN_OUT':
      return null

    case 'FETCH_PROJECT_SUCCESS':
     
      return action.project;
    



    default:
      return state;
	}
}
