import mixpanel from 'mixpanel-browser';



//let MIXPANEL_TOKEN='b689cf7fccd6e95db1da7c283f8ccdd6'


mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);


let env_check=true

let actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => { //only need to use alias to track users  is to connect a user’s anonymous events from before a signup (or other identification) event with the post-signup activity on your site or in your app.
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props)
    },
  },
};

export let Mixpanel = actions;

  //https://help.mixpanel.com/hc/en-us/articles/115004497803-Identity-Management-Best-Practices