import React, { useState } from 'react';

const CampaignVariantGenerateTab = (props) => {
 
  const {campaignVariant,isUploading} = props
    const [copySuccess, setCopySuccess] = useState(false);


  const isUploadingWebcam = isUploading // when is uploading the webcam videos 
  
 // const shareUrl = `gen.yarn.so/kjqwkjeqaskjxckjasdkj`
  let shareUrl 
  if(campaignVariant){
    shareUrl = `${window.location.origin}/share/${campaignVariant.yarn_share_id}`;
  }

  //shareUrl = 'gen.yarn.so/share/3c884640aa01ba079d70b968b4828eb2'


  //const showLoadingState = isUploadingWebcam || !shareUrl
  const showLoadingState = isUploading

    // Handle opening in new tab
  const handleOpenNewTab = () => {
    // Ensure URL has protocol
    const urlToOpen = shareUrl.startsWith('http') ? shareUrl : `https://${shareUrl}`;
    window.open(urlToOpen, '_blank');
  };

  // Handle copy to clipboard
  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      setCopySuccess(true);
      // Reset success message after 2 seconds
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };



  return (

    
    <div className="campaignVariantPage-generateScreen">


      <div className="campaignVariantPage-generateScreen-center">
        <div className="campaignVariantPage-generateScreen-center-title">
          Share Link
        </div>
        {showLoadingState &&
          <div className="campaignVariantPage-generateScreen-center-link campaignVariantPage-generateScreen-center-link--loading">
            Loading
          </div>
        }
        {!showLoadingState &&
          <div className="campaignVariantPage-generateScreen-center-link">                      
            {shareUrl}            
          </div>
        }
        <div className='campaignVariantPage-generateScreen-center-buttonList'>
          <button 
            className="campaignVariantPage-generateScreen-center-button"
            onClick={handleOpenNewTab}
            disabled={showLoadingState}
          >
            Open in New Tab
          </button>
          <button 
            className="campaignVariantPage-generateScreen-center-button"
            onClick={handleCopyLink}
            disabled={showLoadingState}
          >
            {copySuccess ? 'Copied!' : 'Copy Link'}
          </button>
        </div>


      </div>
        

      {/*}
      
      <div style={{ 
        backgroundColor: 'red', 
        padding: '10px', 
        color: 'white',
        borderRadius: '5px',
      }}>
        Generate Video Tab

        <button onClick={props.generateProject}>GENERATE</button>
      </div>

      */}
      
    </div>
  );
};

export default CampaignVariantGenerateTab;
