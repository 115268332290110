import React, { useCallback } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import Icon from '../../Icon'

const cleanupLabel = (label) => {
  return label.replace(/\s*\(.*?\)\s*/g, '').trim();
};

const Dropdown = ({ options, value, onChange, label, iconName }) => {
  const activeOption = options.find(option => option.value === value);
  const buttonLabel = activeOption ? cleanupLabel(activeOption.label) : 'Select...';

  const handleSelect = useCallback((optionValue) => {
    onChange(optionValue);
  }, [onChange]);

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild>
        <button className='campaignVariantPage-recordPanel-cameraSettings-btn'>          
            <Icon name={iconName} />          
        </button>
      </DropdownMenu.Trigger>
      
      <DropdownMenu.Content align="start" alignOffset={-20} sideOffset={2} collisionPadding={10} className='dropdownMenu forceDarkTheme'>
        {options.map((option) => (
          <DropdownMenu.Item
            key={option.value}
            onSelect={() => handleSelect(option.value)}
            className={`dropdownMenu-item ${option.value === value ? 'dropdownMenu-item--active' : 'dropdownMenu-item--inactive'}`}
          >

            {cleanupLabel(option.label)}
          </DropdownMenu.Item>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

const CampaignVariantRecordCameraSettings = ({ 
  activeCamera, 
  setActiveCamera, 
  activeMicrophone, 
  setActiveMicrophone,
  cameras,
  microphones
}) => {
  return (
    <div className="campaignVariantPage-recordPanel-cameraSettings">
      
      <Dropdown
        options={microphones}
        value={activeMicrophone}
        onChange={setActiveMicrophone}
        iconName="microphoneRegular"
      />
      <Dropdown
        options={cameras}
        value={activeCamera}
        onChange={setActiveCamera}
        iconName="cameraRegular"
      />
    </div>
  );
};

export default CampaignVariantRecordCameraSettings;