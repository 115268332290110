import React from 'react';
import Icon from '../Icon'
import backgroundColorOptions from '../../utils/backgroundColorOptions'

const SelectBGColorGrid = ({ selectNext, smallSize, selectedBGColor, setSelectedBGColor, isMacOSBackground }) => {

  

  const cloudinaryBaseUrl = 'https://res.cloudinary.com/yarn/image/upload';
  const transformations = 'c_scale,w_400,q_auto,f_png';  

  return (
    <div data-small-size={smallSize} className="selectGrid selectGrid--color">
      {backgroundColorOptions.map((bg) => (
        <button
          key={bg.id}
          data-active-state={selectedBGColor === bg.id ? 'active' : 'inactive'}
          className='selectGrid-btn selectGrid-btn--color'
          onClick={() => setSelectedBGColor(bg.id)}
          onDoubleClick={selectNext}
        >
          <div className='selectGrid-btn-inner'>
          {bg.options?.macosSrc && isMacOSBackground ? (
            <img 
              className='selectGrid-btn--color-img' 
              src={`${cloudinaryBaseUrl}/${transformations}/clay/${bg.options.macosSrc}.png`}                           
            />
          ) : bg.src ? (
            <img 
              className='selectGrid-btn--color-img' 
              src={`${cloudinaryBaseUrl}/${transformations}/${bg.src}`} 
              alt={`Background ${bg.id}`} 
            />
          ) : (
            <div 
              className="selectGrid-btn--color-colorSwatch" 
              style={{ background: bg.color }} 
            />
          )}
          {selectedBGColor === bg.id &&
            <div data-light-bg={bg.id===0} className='selectGrid-btn--color-iconContainer'> 
              <Icon name='checkmark' />
            </div>
          }
          <div className='selectGrid-btn-activeBorder' />
          </div>
        </button>
      ))}
    </div>
  );
};

export default SelectBGColorGrid;