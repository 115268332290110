import React, { useState, useEffect } from 'react';
import { convertMedia } from '@remotion/webcodecs';
import { webFileReader } from '@remotion/media-parser/web-file';
import Icon from '../Icon';
import RecordRTC from 'recordrtc';
import VideoPreviewPlayer from './videoPreviewPlayer/VideoPreviewPlayer';
import VideoPreviewPlayerOverlay from './videoPreviewPlayer/VideoPreviewPlayerOverlay';
import VideoRecordModal from './videoRecordModal/VideoRecordModal';

const VideoInput = (props) => {
  const {
    onRecordingComplete,
    status,
    hasInstructions,
    instructions,
    script,
    handleUpdateTrim,
    webcamId,
    creatorEdits,
    previewVideo
  } = props;

 // const [videoSrc, setVideoSrc] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectHideVideo, setSelectHideVideo] = useState(false);
  
  let previewType = 'video';
  if (selectHideVideo || creatorEdits?.audioOnly) {
    previewType = 'audio';
  }

  const [overlayMode, setOverlayMode] = useState(false);
  const [editingFromOverlay, setEditingFromOverlay] = useState(false);
  const [userSelectedMode, setUserSelectedMode] = useState(false);

  // Add useEffect to handle preview video changes
  useEffect(() => {
    if (!userSelectedMode && previewVideo) {
      setOverlayMode(true);
    }
  }, [previewVideo, userSelectedMode]);

  // Update the click handlers to track user preference
  const handleOverlayModeChange = (newMode) => {
    setOverlayMode(newMode);
    setUserSelectedMode(true);
  };

  // useEffect(() => {
  //   const stabilizeAndSetVideo = async () => {
  //     if (status?.recording) {
  //       try {
  //         // Attempt conversion using WebCodecs
  //         const stableBlob = await convertMedia({
  //           src: status.recording,
  //           container: 'webm',
  //           reader: webFileReader,
  //         });
  //         const url = URL.createObjectURL(stableBlob);
  //         setVideoSrc(url);
  //         return () => URL.revokeObjectURL(url);
  //       } catch (error) {
  //         console.error('Error stabilizing video using WebCodecs:', error);
  //         // Fallback: use RecordRTC.getSeekableBlob
  //         try {
  //           const seekableBlob = await new Promise((resolve, reject) => {
  //             RecordRTC.getSeekableBlob(status.recording, (blob) => {
  //               if (blob) {
  //                 resolve(blob);
  //               } else {
  //                 reject(new Error('RecordRTC.getSeekableBlob failed'));
  //               }
  //             });
  //           });
  //           const url = URL.createObjectURL(seekableBlob);
  //           setVideoSrc(url);
  //         } catch (fallbackError) {
  //           console.error('Error using RecordRTC.getSeekableBlob:', fallbackError);
  //           // Additional fallback: use original recording blob
  //           const url = URL.createObjectURL(status.recording);
  //           setVideoSrc(url);
  //         }
  //       }
  //     }
  //   };

  //   stabilizeAndSetVideo();
  // }, [status?.recording]);


  // useEffect(() => {
  //   if (videoSrc && handleUpdateTrim) {
  //     // Reset trims: trimStart = 0, trimEnd = 1.
  //     // (You may pass duration = 0 here, then update when duration is available.)
  //     handleUpdateTrim(webcamId, 0, 1, 0);
  //   }
  // }, [videoSrc, handleUpdateTrim, webcamId]);

  const handleVideoRecorded = (seekableBlob) => {
    setIsModalOpen(false);
    if (onRecordingComplete) {
      onRecordingComplete(seekableBlob, selectHideVideo);
    }
  };

  const handleReRecord = () => {    
    setSelectHideVideo(false);
    setIsModalOpen(true);
  };

  // When editing (from overlay) is complete, switch back to overlay mode.
  const handleEditingComplete = () => {
    if (editingFromOverlay) {
      setOverlayMode(true);
      setEditingFromOverlay(false);
    }
  };

  // // Cleanup URL when component unmounts
  // useEffect(() => {
  //   return () => {
  //     if (videoSrc) {
  //       URL.revokeObjectURL(videoSrc);
  //     }
  //   };
  // }, [videoSrc]);
  
  const videoSrc = status?.recording


  return (
    <>
      {videoSrc ? (
        <>
          <div className='page-videoPreview'>
            <div data-media-type={previewType} className='page-videoPreview-previewContainer'>
              {previewType === 'video' && !editingFromOverlay && previewVideo &&
                <div className='page-videoPreview-overlayTabs'>
                  {overlayMode && 
                    <button
                      onClick={() => handleOverlayModeChange(false)}
                      data-active-state={overlayMode ? 'inactive' : 'active'}
                      className='page-videoPreview-overlayTab'
                    >
                      Show original recording
                    </button>
                  }
                  {!overlayMode && 
                    <button
                      onClick={() => handleOverlayModeChange(true)}
                      data-active-state={overlayMode ? 'active' : 'inactive'}
                      className='page-videoPreview-overlayTab'
                    >
                      Show generation preview
                    </button>
                  }
                </div>
              }

              {/* Standard preview (with edit controls) */}
              {(!overlayMode || previewType === 'audio') && 
                <VideoPreviewPlayer 
                  key={status.captureId}
                  url={videoSrc}
                  selectHideVideo={creatorEdits?.audioOnly}
                  editable={true}
                  handleUpdateTrim={handleUpdateTrim}
                  webcamId={webcamId}
                  creatorEdits={creatorEdits}
                  previewVideo={previewVideo}
                  startInEditMode={editingFromOverlay}  // Pass flag to start in edit mode
                  onEditingComplete={handleEditingComplete} // Callback when editing is done
                />
              }

              {/*}
              {overlayMode && 
                <VideoPreviewPlayerUnderlay
                  url={videoSrc}
                  selectHideVideo={creatorEdits?.audioOnly}
                  editable={true}
                  handleUpdateTrim={handleUpdateTrim}
                  webcamId={webcamId}
                  creatorEdits={creatorEdits}
                  previewGreenScreenVideoCloudinaryId={previewGreenScreenVideoCloudinaryId}
                />
              }*/}

              {/* Overlay preview with added "Edit" button */}
              {overlayMode && previewType === 'video' && 
                <VideoPreviewPlayerOverlay
                  url={videoSrc}
                  selectHideVideo={creatorEdits?.audioOnly}
                  editable={true}
                  handleUpdateTrim={handleUpdateTrim}
                  webcamId={webcamId}
                  creatorEdits={creatorEdits}
                  previewVideo={previewVideo}                  
                  onEdit={() => {
                    setOverlayMode(false);
                    setEditingFromOverlay(true);
                  }}
                />
              }
            </div>
          </div>
          <button 
            onClick={handleReRecord} 
            className='page-voiceover-openModalBtn page-voiceover-openModalBtn--new page-voiceover-openModalBtn--rerecord'
          >
            Re-record
          </button> 
        </>
      ) : (
        <div className='page-voiceover-newWebcamContainer'>
          <button 
            onClick={() => setIsModalOpen(true)} 
            className='page-voiceover-openModalBtn page-voiceover-openModalBtn--new page-voiceover-openModalBtn--new--webcam'
          >
            Record Video
          </button> 
        </div>
      )}       
      
      <VideoRecordModal 
        isOpen={isModalOpen}
        onOpenChange={setIsModalOpen}
        onVideoRecorded={handleVideoRecorded}
        instructions={instructions}
        script={script}
        selectHideVideo={selectHideVideo}
        setSelectHideVideo={setSelectHideVideo}
        type={hasInstructions ? 'instructions' : 'script'}
        previewVideo={previewVideo}
        webcamId={webcamId}
      />
    </>
  );
};

export default VideoInput;
