import React, { useState, useEffect } from 'react';
import Pusher from 'pusher-js';

const ExportProjectComponent = ({ projectId, query }) => {
  const [status, setStatus] = useState('pre');
  const [isGenerating, setIsGenerating] = useState(false);
  const [renderId, setRenderId] = useState(null);
  const [assetURL, setAssetURL] = useState(null);

  const handleGenerateVideo = async () => {
    setIsGenerating(true);
    setStatus('Generating video...');
    try {
      const result = await generateVideo(projectId, query);
      setRenderId(result.renderId);
    } catch (error) {
      setStatus('Failed to generate video');
      console.error('Error generating video:', error);
    }
  };

  useEffect(() => {
    if (!renderId) return;

    const pusher = new Pusher('a7eac2ac287fcd5e92ea', {
      cluster: 'us2',
      encrypted: true
    });
    
    const channel = pusher.subscribe('video-renders');

    channel.bind('render-update', function(data) {
      console.log(data);
      if (`${data.renderId}` === `${renderId}`) {
        setStatus('assetReady');
        setAssetURL(data.resultUrl);
      } else {
        console.log('its the wrong render id');
      }
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [renderId]);

  const handleExport = async () => {
    if (status === 'pre') {
      handleGenerateVideo();
    } else if (status === 'assetReady') {
      try {
        const response = await fetch(assetURL);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        const fileName = assetURL.split('/').pop() || 'downloaded-video.mp4';
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Download failed:', error);
        alert('Download failed. Please try again.');
      }
    }
  };

  let buttonText = 'Generate Video';
  if (isGenerating) buttonText = 'Generating...';
  if (status === 'assetReady') buttonText = 'Download Video';

  return (
    <div>
      <button onClick={handleExport} disabled={isGenerating}>
        {buttonText}
      </button>
      {status !== 'pre' && <div>Status: {status}</div>}
    </div>
  );
};


async function generateVideo(projectId,queryParams) {
  try {
   // const {slug,...otherParams} = queryParams
    let otherParams={}

    
    const optionsBase64 = btoa(JSON.stringify(otherParams));
    const url = `https://yarn-orch-dev.foxits.cz/orchestrator/render/${projectId}?options=${optionsBase64}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Failed to generate video');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error generating video:', error);
    throw error;
  }
}


export default ExportProjectComponent;