// VideoPreviewPlayer.jsx
import React, { useRef, useState, useEffect } from 'react';
import Icon from '../../Icon';
import ReactPlayer from 'react-player/file';
import VideoPreviewPlayerControls from './VideoPreviewPlayerControls';
import VideoPreviewPlayerPreControls from './VideoPreviewPlayerPreControls';
import VideoPreviewPlayerEditing from './VideoPreviewPlayerEditing';

function VideoPreviewPlayer({
  url,
  selectHideVideo,
  exampleVideo,
  editable,
  handleUpdateTrim,
  webcamId,
  creatorEdits,
  previewVideo,
  startInEditMode,
  onEditingComplete,
  instructionsVideo,
  defaultPlaybackRate
}) {
  const playerRef = useRef(null);

  // Use creatorEdits.trimStart/trimEnd as fractions (0–1). Defaults: 0 and 1.
  let initialTrimStart = creatorEdits && creatorEdits.trimStart ? creatorEdits.trimStart : 0;
  let initialTrimEnd = creatorEdits && creatorEdits.trimEnd ? creatorEdits.trimEnd : 1;

  // State for playback. Here, "progress" represents the adjusted progress within the trimmed segment.
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0); // Adjusted progress (0–1 over trimmed segment)
  const [progressSeconds, setProgressSeconds] = useState(0);
  const [hasPlayed, setHasPlayed] = useState(false);
  const [seeking, setSeeking] = useState(false);
  const [lastPlayPauseChange, setLastPlayPauseChange] = useState(Date.now());
  const [showPreControls, setShowPreControls] = useState(true);
  const [editing, setEditing] = useState(startInEditMode || false);
  const [trimStart, setTrimStart] = useState(initialTrimStart);
  const [trimEnd, setTrimEnd] = useState(initialTrimEnd);
  const [playbackRate, setPlaybackRate] = useState(defaultPlaybackRate ? defaultPlaybackRate : 1);

  useEffect(() => {
    if (startInEditMode) {
      setEditing(true);
    }
  }, [startInEditMode]);

  // Converts an adjusted progress value (0–1 in trimmed space) into a raw progress value (0–1 of the full video)
  const getRawProgress = (adjustedProgress) => {
    return trimStart + adjustedProgress * (trimEnd - trimStart);
  };

  const handleTrimChange = (start, end) => {
    setTrimStart(start);
    setTrimEnd(end);
    if (playerRef.current) {
      // Seek to the new raw start
      playerRef.current.seekTo(start);
    }
    // Reset our adjusted progress to 0 (i.e. at the new trimStart)
    setProgress(0);
    setProgressSeconds(0);
    handleUpdateTrim(webcamId, start, end, duration);
  };

  const handlePlay = () => {
    setPlaying(true);
    setHasPlayed(true);
    setLastPlayPauseChange(Date.now());
    setShowPreControls(false);
  };

  const handlePause = () => {
    setPlaying(false);
    setLastPlayPauseChange(Date.now());
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  // A threshold for ending playback (in editing mode)
  const END_THRESHOLD = 0.99;

  const handleProgress = (state) => {
    if (!seeking) {
      const rawProgress = state.played;
      let adjustedProgress = 0;
      let adjustedSeconds = 0;

      if (rawProgress < trimStart) {
        adjustedProgress = 0;
        adjustedSeconds = 0;
      } else if (rawProgress > trimEnd) {
        adjustedProgress = 1;
        adjustedSeconds = (trimEnd - trimStart) * duration;
      } else {
        adjustedProgress = (rawProgress - trimStart) / (trimEnd - trimStart);
        adjustedSeconds = state.playedSeconds - trimStart * duration;
      }

      setProgress(adjustedProgress);
      setProgressSeconds(adjustedSeconds);

      // End playback when reaching the end of the trimmed segment
      if (!editing && rawProgress >= trimEnd) {
        handleEnded();
      } else if (editing && rawProgress >= END_THRESHOLD) {
        handleEnded();
      }
    }
  };

  const handleEnded = () => {
    setPlaying(false);
    if (!editing) {
      setShowPreControls(true);
    }
    setTimeout(() => {
      if (playerRef.current) {
        // Seek to the raw trimStart position
        playerRef.current.seekTo(trimStart);
      }
      // Reset adjusted progress to 0
      setProgress(0);
      setProgressSeconds(0);
    }, 50);
  };

  useEffect(() => {
    if (progress >= 1 && playing) {
      handleEnded();
    }
  }, [progress, playing]);

  // The slider (or any seek control) works in the adjusted (0–1) space.
  const handleSeekChange = (newValue) => {
    const adjustedValue = parseFloat(newValue); // Value between 0 and 1
    const rawProgress = getRawProgress(adjustedValue);
    const newProgressSeconds = adjustedValue * (trimEnd - trimStart) * duration;
    if (playerRef.current) {
      playerRef.current.seekTo(rawProgress);
    }
    setProgress(adjustedValue);
    setProgressSeconds(newProgressSeconds);
  };

  const handleSeekMouseDown = () => {
    setSeeking(true);
  };

  const handleSeekMouseUp = (newValue) => {
    handleSeekChange(newValue);
    setSeeking(false);
  };

  const containerRef = useRef(null);
  const showPlayerControls =
    (!showPreControls || progress > 0 || selectHideVideo) && duration > 0;

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.seekTo(trimStart);
    }
  }, [trimStart, duration]);

  return (
    <div
      ref={containerRef}
      className="page-videoPreviewPlayer"
      data-media-type={selectHideVideo ? 'audio' : 'video'}
    >
      <ReactPlayer
        ref={playerRef}
        className="page-videoPreviewPlayer-video"
        width="100%"
        height="100%"
        url={url}
        playing={playing}
        playbackRate={playbackRate}
        progressInterval={20}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handleEnded}
        onDuration={handleDuration}
        onProgress={handleProgress}
        controls={false}
      />

      {!showPlayerControls && !editing && (
        <VideoPreviewPlayerPreControls
          duration={(trimEnd - trimStart) * duration}
          exampleVideo={exampleVideo}
          instructionsVideo={instructionsVideo}
        />
      )}

      {editable && !editing && !selectHideVideo && (
        <div
          data-show-bg={showPlayerControls ? false : true}
          className="page-videoPreviewPlayer-editButtonContainer"
        >
          <button
            onClick={() => setEditing(true)}
            className="page-videoPreviewPlayer-editButton"
          >
            <div className="page-videoPreviewPlayer-editButton-label">Edit Video</div>
          </button>
        </div>
      )}
      
      {/*}
      <div className="page-videoPreviewPlayer-playbackRateControls">
        <button onClick={()=> setPlaybackRate(1)} data-active-state={playbackRate === 1 ? 'active' : 'inactive'} className="page-videoPreviewPlayer-playbackRateControls-btn">
          <div className='page-videoPreviewPlayer-playbackRateControls-btn-label'>
            1.0
          </div>
          <div className='page-videoPreviewPlayer-playbackRateControls-btn-iconContainer'>
            <Icon name='multiplySemibold' />
          </div>            
        </button>
        <button onClick={()=> setPlaybackRate(1.2)} data-active-state={playbackRate === 1.2 ? 'active' : 'inactive'} className="page-videoPreviewPlayer-playbackRateControls-btn">
          <div className='page-videoPreviewPlayer-playbackRateControls-btn-label'>
            1.2
          </div>
          <div className='page-videoPreviewPlayer-playbackRateControls-btn-iconContainer'>
            <Icon name='multiplySemibold' />
          </div>
        </button>
        <button onClick={()=> setPlaybackRate(1.5)} data-active-state={playbackRate === 1.5 ? 'active' : 'inactive'} className="page-videoPreviewPlayer-playbackRateControls-btn">
          <div className='page-videoPreviewPlayer-playbackRateControls-btn-label'>
            1.5
          </div>
          <div className='page-videoPreviewPlayer-playbackRateControls-btn-iconContainer'>
            <Icon name='multiplySemibold' />
          </div>
        </button>
        <button onClick={()=> setPlaybackRate(2)} data-active-state={playbackRate === 2 ? 'active' : 'inactive'} className="page-videoPreviewPlayer-playbackRateControls-btn">
          <div className='page-videoPreviewPlayer-playbackRateControls-btn-label'>
            2.0
          </div>
          <div className='page-videoPreviewPlayer-playbackRateControls-btn-iconContainer'>
            <Icon name='multiplySemibold' />
          </div>
        </button>
      </div>
      */}
      


      {showPlayerControls && !editing && (
        <VideoPreviewPlayerControls
          showControls
          playing={playing}
          onPlayPauseClick={() => {
            setPlaying(!playing);
            setLastPlayPauseChange(Date.now());
          }}
          progress={progress} // already adjusted (0–1)
          onSeek={handleSeekChange}
          onSeekMouseDown={handleSeekMouseDown}
          onSeekMouseUp={handleSeekMouseUp}
          duration={(trimEnd - trimStart) * duration}
          editable={editable}
          playbackRate={playbackRate}
          onPlaybackRateChange={setPlaybackRate}          
        />
      )}

      {editing && (
        <VideoPreviewPlayerEditing
          showControls
          editing={editing}
          setEditing={setEditing}
          progress={progress} // adjusted progress value
          onSeek={handleSeekChange}
          onSeekMouseDown={handleSeekMouseDown}
          onSeekMouseUp={handleSeekMouseUp}
          duration={duration}
          trimStart={trimStart}
          trimEnd={trimEnd}
          onTrimChange={handleTrimChange}
          playing={playing}
          onPlayPauseClick={() => {
            setPlaying(!playing);
            setLastPlayPauseChange(Date.now());
          }}
          onEditingComplete={onEditingComplete}
        />
      )}

      <button onClick={() => setPlaying(!playing)} className="videoPlayer-bgPlayPauseBtn" />
    </div>
  );
}

export default VideoPreviewPlayer;
