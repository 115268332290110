import React, { useState, useEffect } from 'react';

const RotatingGenerateVideoFacts = () => {
  const facts = [
    "85% of LinkedIn users preferred watching video to reading text",
    "Videos are shared 20x more on LinkedIn than any other post type",
    "Over 1bn hours of YouTube video are watched every day",
    "Clay has over 100,000 users",
    "Octopuses have three hearts",
    "Viewers retain 95% of video information vs. 10% of text",
    "The name 'Clay' is inspired by the idea of molding and shaping data",
    "Video makes up more than 82% of internet traffic, 15x higher than in 2017",    
    "65% of Gen Z self identify as video content creators",
  ];

  const [currentFactIndex, setCurrentFactIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentFactIndex(prevIndex => (prevIndex + 1) % facts.length);
    }, 7000);

    return () => clearInterval(intervalId);
  }, [facts.length]);

  return (
    <div className="page-generateVideo-message">
      {facts.map((fact, index) => (
        <div
          key={index}
          className="page-generateVideo-message-inner"
          data-fact-visible={index === currentFactIndex}
        >
          {fact}
        </div>
      ))}
    </div>
  );
};

export default RotatingGenerateVideoFacts;