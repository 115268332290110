import React, { useState, useEffect,useRef } from 'react';
import { useParams } from 'react-router-dom';
import ClayOnboardingVideoPage from '../components/deliveryPage/ClayOnboardingVideoPage';

import {fetchCloudflareForShareId} from '../actions/videoDelivery'

const ClayOnboardingVideoContainer = () => {
  const [cloudflareId, setCloudflareId] = useState(null);

  const { shareId } = useParams();



useEffect(() => {
  const fetchCloudflareId = async () => {
    try {
      const response = await fetchCloudflareForShareId(shareId)
      setCloudflareId(response.cloudflare_id)
    } catch (error) {
      console.log(error)
    }
  };
  fetchCloudflareId() // Call the local function instead
}, [shareId]);
  
 // console.log(cloudflareId)
  return (
    <div className='deliveryPageContainer'>
        <ClayOnboardingVideoPage
        cloudflarePlaybackId={cloudflareId}
      />   
    </div>
  );
};

export default ClayOnboardingVideoContainer;