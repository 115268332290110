import React, { useState, useRef, useEffect } from 'react';
import Icon from '../Icon'

const CampaignVariantSampleScript = ({status, tabState,campaignVariant,userDisplayScript,setUserDisplayScript}) => {



  let prospectName 
  let prospectOrg 
  let caseStudy

  if(campaignVariant && campaignVariant.personalization_data){
    prospectName = campaignVariant.personalization_data.prospectFirstName
    prospectOrg = campaignVariant.personalization_data.prospectOrg
    caseStudy = campaignVariant.personalization_data.caseStudy
  }


  const initialIntroScript = `Hey ${prospectName}, it's Jasper, GTM engineer here at Clay. Not sure we've met before... I'm friends with Sarah from my Slack days, so say hi! Been tracking the GTM work you guys have been doing and thought you might be interested in what we're up to at Clay in Q4.`
  const initialOutroScript = `Anyway, super excited to see how ${prospectOrg} and Clay could work together, those were just a few examples. 

Let me know if you want the full ${caseStudy} case study … and if you grab a 20 minute slot on my Calendly, we could set up a custom waterfall together live so you guys can play around for yourself. Awesome thanks!`

  // console.log(`tabState ${tabState}`)



  let initialScript = initialIntroScript
  if(tabState == 'outro'){
    initialScript = initialOutroScript
  }

  const [scriptContent, setScriptContent] = useState(initialScript);
  
  const textareaRef = useRef(null);
  
  const handleScriptChange = (event) => {
    setScriptContent(event.target.value);
  };
  
  useEffect(() => {
    if (userDisplayScript && textareaRef.current) {
      textareaRef.current.focus();
      // Move cursor to end of text
      const length = textareaRef.current.value.length;
      textareaRef.current.setSelectionRange(length, length);
    }
  }, [userDisplayScript]);
  
  const recordingMode = status === 'recording' || status === 'countdown';

  useEffect(() => {
    if (recordingMode && textareaRef.current) {
      textareaRef.current.blur();
      window.getSelection().removeAllRanges();
    }
  }, [recordingMode]);
  
  const handleToggleScript = () => {
    setUserDisplayScript(!userDisplayScript);
  };
  

  const showScript = tabState != 'generate' && userDisplayScript
  const showBtn = tabState != 'generate'
  
  return (
    <>
    
      <div data-visibility-state={showScript ? 'visible' : 'hidden'} className='campaignVariantPage-sampleScriptContainer'>
        <div data-script-style={recordingMode ? 'bold' : 'default'} className='campaignVariantPage-sampleScript'>
          <div data-visibility-state={recordingMode ? 'low' : 'visible'} className='campaignVariantPage-sampleScript-title'>
            Script
          </div>
          <button onClick={handleToggleScript} data-visibility-state={recordingMode ? 'hidden' : 'visible'} className='campaignVariantPage-sampleScript-hide'>
            Hide
          </button>
          <textarea
            ref={textareaRef}
            value={scriptContent}
            onChange={handleScriptChange}
            disabled={recordingMode}
            placeholder="Type your script"
            className='campaignVariantPage-sampleScript-textarea'
          />
          <div className='campaignVariantPage-sampleScript-ghost'>
            {scriptContent}
          </div>
          
          <div data-visibility-state={!recordingMode ? 'visible' : 'hidden'} className='campaignVariantPage-sampleScript-instructionsContainer'>
            <div className='campaignVariantPage-sampleScript-instructions'>
              Press Shift + Enter to Start Recording
            </div>
          </div>
          <div data-visibility-state={recordingMode ? 'visible' : 'hidden'} className='campaignVariantPage-sampleScript-instructionsContainer'>
            <div className='campaignVariantPage-sampleScript-instructions campaignVariantPage-sampleScript-instructions--bold'>
              Press Spacebar to Stop
            </div>
            <div className='campaignVariantPage-sampleScript-instructions'>
              Press Shift + Enter to Restart
            </div>
          </div>
        </div>
      </div>          
    
    
    {showBtn && 
      <div data-visibility-state={userDisplayScript ? 'fade' : 'bright'} onClick={handleToggleScript} className='campaignVariantPage-sampleScriptToggleBtn'>
        <div className='campaignVariantPage-sampleScriptToggleBtn-inner'>
          {!userDisplayScript && 
            <div className='campaignVariantPage-sampleScriptToggleBtn-iconContainer'>
              <Icon name='stars' />
            </div>
          }
          <div className='campaignVariantPage-sampleScriptToggleBtn-label'>
            {userDisplayScript ? 'Hide Script' : 'Sample Script'}
          </div>      
        </div>
      </div>
    }
    </>
  );
};

export default CampaignVariantSampleScript;