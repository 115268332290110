// useAudioMuteDetection.js
import { useState, useEffect, useRef } from 'react';

export const useAudioMuteDetection = (microphoneLevel, timeThresholdMs = 2000, manualReset = false) => {
  const [isMuted, setIsMuted] = useState(false);
  const lastAudioActivityTime = useRef(Date.now());
  const checkIntervalRef = useRef(null);
  
  // Reset mute state if manually requested
  useEffect(() => {
    if (manualReset) {
      setIsMuted(false);
      lastAudioActivityTime.current = Date.now();
    }
  }, [manualReset]);
  
  useEffect(() => {
    // Reset the timer if we detect any audio activity
    if (microphoneLevel > 0) {
      lastAudioActivityTime.current = Date.now();
      if (isMuted) {
        setIsMuted(false);
      }
    }
    
    // Start checking for mute status if not already checking
    if (!checkIntervalRef.current) {
      checkIntervalRef.current = setInterval(() => {
        const currentTime = Date.now();
        const timeSinceLastActivity = currentTime - lastAudioActivityTime.current;
        
        // If it's been silent for longer than the threshold, consider it muted
        if (timeSinceLastActivity >= timeThresholdMs && !isMuted) {
          setIsMuted(true);
        }
      }, 200); // Check every 200ms
    }
    
    return () => {
      if (checkIntervalRef.current) {
        clearInterval(checkIntervalRef.current);
        checkIntervalRef.current = null;
      }
    };
  }, [microphoneLevel, isMuted, timeThresholdMs]);
  
  return isMuted;
};