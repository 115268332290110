import React, { useState, useEffect, useRef, useMemo } from 'react';
import ReactPlayer from 'react-player/file';
import VideoPreviewPlayerControls from './VideoPreviewPlayerControls';
import VideoPreviewPlayerPreControls from './VideoPreviewPlayerPreControls';
import Icon from '../../Icon';

function VideoPreviewPlayerOverlay({ url, creatorEdits, previewVideo, webcamId, editable, onEdit }) {
  const webcamVideoUrl = url;
  const webcamRef = useRef(null);
  const exampleRef = useRef(null);

  const [webcamReady, setWebcamReady] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [progressFraction, setProgressFraction] = useState(0);
  const [progressSeconds, setProgressSeconds] = useState(0);
  const [currentLayout, setCurrentLayout] = useState(null);
  const [underlayDuration, setUnderlayDuration] = useState(0);
  const [overlayDuration, setOverlayDuration] = useState(0);
  const [seeking, setSeeking] = useState(false);
  const [showPreControls, setShowPreControls] = useState(true);
  const [overridePlaybackRate, setOverridePlaybackRate] = useState(1);

  // These trims apply only to the underlay (webcam) video.
  const trimStart = creatorEdits?.trimStart || 0;
  const trimEnd = creatorEdits?.trimEnd || 1;

  let inputVideoUrl = null;
  let exampleVideoUrl = null;
  let layoutRanges = null;

  if (previewVideo?.previewSegments?.length > 0) {
    const matchingSegment = previewVideo.previewSegments.find(segment =>
      segment.clipId === webcamId
    );

    if (matchingSegment) {
      const overlayVideoDuration = matchingSegment.duration;
      const overlayVideoStart = matchingSegment.startTime;
      const generatedCloudinaryId = `generation_${previewVideo.id}`;

      // The overlay video uses the full original video (no trim applied)
      exampleVideoUrl = `https://res.cloudinary.com/yarn/video/upload/so_${overlayVideoStart},eo_${
        overlayVideoStart + overlayVideoDuration
      }/collabPreviewVideo/${generatedCloudinaryId}.mp4`;

      const captureId = matchingSegment.captureId;
      inputVideoUrl = `https://res.cloudinary.com/yarn/video/upload/so_0,eo_${overlayVideoDuration}/webcamRecordings/${captureId}.mp4`;

      layoutRanges = matchingSegment.layoutRanges;
    }
  }

  // For the underlay, map an adjusted fraction (0–1 over the trimmed portion)
  // to the raw progress (as a fraction) of the webcam video.
  const getRawProgress = (adjustedFraction) => {
    return trimStart + adjustedFraction * (trimEnd - trimStart);
  };

  // Compute playbackRate so that the full overlay video plays over the trimmed portion.
  const playbackRate = useMemo(() => {
    if (!underlayDuration || !overlayDuration) return 1;
    const trimmedUnderlayDuration = underlayDuration * (trimEnd - trimStart);
    return overlayDuration / trimmedUnderlayDuration;
  }, [underlayDuration, overlayDuration, trimStart, trimEnd]);

  const adjustedRanges = useMemo(() => {
    if (!layoutRanges) return [];
    return layoutRanges.map(range => ({
      ...range,
      startTime: range.startTime / playbackRate,
      endTime: range.endTime / playbackRate
    }));
  }, [layoutRanges, playbackRate]);

  useEffect(() => {
    if (!adjustedRanges.length) return;
    const layout = adjustedRanges.find(
      range => progressSeconds >= range.startTime && progressSeconds <= range.endTime
    );
    if (layout) {
      setCurrentLayout(layout);
    }
  }, [progressSeconds, adjustedRanges]);

  const getLayoutStyle = (layout) => {
    if (!layout) return {};
    const { rect } = layout;
    const totalWidth = 1920;
    const totalHeight = 1080;
    // console.log('rect.width')
    // console.log(rect.width)
    // console.log('rect.cornerRadius')
    // console.log(rect.cornerRadius)
    // console.log('rect.cornerRadius / rect.width')
    // console.log(rect.cornerRadius / rect.width)

    return {
      width: `${(rect.width / totalWidth) * 100}%`,
      height: `${(rect.height / totalHeight) * 100}%`,
      left: `${(rect.x / totalWidth) * 100}%`,
      top: `${(rect.y / totalHeight) * 100}%`,
      borderRadius: rect.cornerRadius ? `${(rect.cornerRadius / rect.width) * 100}%` : '0'
    };
  };

  const handlePlay = () => {
    setPlaying(true);
    setShowPreControls(false);
  };

  // Underlay player's pause handler.
  const handlePause = () => {
    setPlaying(false);
  };

  // For the overlay player, check the global playing state before pausing.
  const handleOverlayPause = () => {
    if (!playing) {
      handlePause();
    }
  };

  // The underlay player's progress drives our adjusted progress.
  const handleUnderlayProgress = (state) => {
    if (!seeking) {
      const rawProgress = state.played; // This is a fraction (0–1)
      let adjustedFraction = 0;
      let adjustedSeconds = 0;
      if (rawProgress < trimStart) {
        adjustedFraction = 0;
        adjustedSeconds = 0;
      } else if (rawProgress > trimEnd) {
        adjustedFraction = 1;
        adjustedSeconds = (trimEnd - trimStart) * underlayDuration;
      } else {
        adjustedFraction = (rawProgress - trimStart) / (trimEnd - trimStart);
        adjustedSeconds = state.playedSeconds - trimStart * underlayDuration;
      }
      setProgressFraction(adjustedFraction);
      setProgressSeconds(adjustedSeconds);
      if (rawProgress >= trimEnd) {
        handleEnded();
      }
    }
  };

  const handleEnded = () => {
    setPlaying(false);
    setShowPreControls(true);
    setTimeout(() => {
      // Underlay: seek to trimStart using fraction.
      webcamRef.current?.seekTo(trimStart, 'fraction');
      // Overlay: always start at 0 seconds.
      exampleRef.current?.seekTo(0);
      setProgressFraction(0);
      setProgressSeconds(0);
    }, 50);
  };

  // When seeking via the controls:
  const handleSeekChange = (fraction) => {
    const adjustedFraction = parseFloat(fraction);
    const rawProgress = getRawProgress(adjustedFraction);
    webcamRef.current?.seekTo(rawProgress, 'fraction');
    // Overlay: map adjusted fraction to full timeline (overlayDuration is in seconds).
    exampleRef.current?.seekTo(adjustedFraction * overlayDuration);
    const newSeconds = adjustedFraction * (trimEnd - trimStart) * underlayDuration;
    setProgressFraction(adjustedFraction);
    setProgressSeconds(newSeconds);
  };

  const handleSeekMouseDown = () => setSeeking(true);
  const handleSeekMouseUp = (newValue) => {
    handleSeekChange(newValue);
    setSeeking(false);
  };

  const handleWebcamReady = () => {
    setWebcamReady(true);
  };

  const handleUnderlayDuration = (dur) => {
    setUnderlayDuration(dur);
  };

  const handleOverlayDuration = (dur) => {
    setOverlayDuration(dur);
  };

  const handleError = (e) => {
    console.error('ReactPlayer error:', e);
  };

  // On mount (or when trimStart changes), seek underlay to trimStart (as a fraction)
  // and overlay to 0.
  useEffect(() => {
    if (webcamReady) {
      webcamRef.current?.seekTo(trimStart, 'fraction');
      exampleRef.current?.seekTo(0);
    }
  }, [webcamReady, trimStart]);

  const showPlayerControls =
    (!showPreControls || progressFraction > 0) && underlayDuration > 0;

  return (
    <div className="page-videoPreviewPlayer">
      <div
        className="page-videoPreviewPlayer-webcamVideoContainer"
        style={getLayoutStyle(currentLayout)}
      >
        <ReactPlayer
          ref={webcamRef}
          className="page-videoPreviewPlayer-webcamVideo"
          width="100%"
          height="100%"
          url={webcamVideoUrl}
          playing={playing}
          progressInterval={50}
          onPlay={handlePlay}
          onPause={handlePause}
          onDuration={handleUnderlayDuration}
          onProgress={handleUnderlayProgress}
          onReady={handleWebcamReady}
          onError={handleError}
          controls={false}
          playbackRate={overridePlaybackRate}
        />
      </div>

      {webcamReady && (
        <ReactPlayer
          ref={exampleRef}
          className="page-videoPreviewPlayer-overlayVideo"
          width="100%"
          height="100%"
          url={exampleVideoUrl}
          playing={playing}
          playbackRate={playbackRate * overridePlaybackRate}
          progressInterval={50}
          onPlay={handlePlay}
          onPause={handleOverlayPause}
          onDuration={handleOverlayDuration}
          onError={handleError}
          controls={false}
          muted
        />
      )}

      {editable && (
        <div
          data-show-bg={showPlayerControls ? false : true}
          className="page-videoPreviewPlayer-editButtonContainer page-videoPreviewPlayer-editButtonContainer--overlay"
        >
          <button onClick={onEdit} className="page-videoPreviewPlayer-overlay-editButton">
            <div className="page-videoPreviewPlayer-editButton-label">Edit Video</div>
          </button>
        </div>
      )}

      {!showPlayerControls && (
        <VideoPreviewPlayerPreControls
          duration={(trimEnd - trimStart) * underlayDuration}
          previewVideo={exampleVideoUrl}
        />
      )}

      {/*}
      <div className="page-videoPreviewPlayer-playbackRateControls">
        <button onClick={()=> setOverridePlaybackRate(1)} data-active-state={overridePlaybackRate === 1 ? 'active' : 'inactive'} className="page-videoPreviewPlayer-playbackRateControls-btn">
          <div className='page-videoPreviewPlayer-playbackRateControls-btn-label'>
            1.0
          </div>
          <div className='page-videoPreviewPlayer-playbackRateControls-btn-iconContainer'>
            <Icon name='multiplySemibold' />
          </div>            
        </button>
        <button onClick={()=> setOverridePlaybackRate(1.2)} data-active-state={overridePlaybackRate === 1.2 ? 'active' : 'inactive'} className="page-videoPreviewPlayer-playbackRateControls-btn">
          <div className='page-videoPreviewPlayer-playbackRateControls-btn-label'>
            1.2
          </div>
          <div className='page-videoPreviewPlayer-playbackRateControls-btn-iconContainer'>
            <Icon name='multiplySemibold' />
          </div>
        </button>
        <button onClick={()=> setOverridePlaybackRate(1.5)} data-active-state={overridePlaybackRate === 1.5 ? 'active' : 'inactive'} className="page-videoPreviewPlayer-playbackRateControls-btn">
          <div className='page-videoPreviewPlayer-playbackRateControls-btn-label'>
            1.5
          </div>
          <div className='page-videoPreviewPlayer-playbackRateControls-btn-iconContainer'>
            <Icon name='multiplySemibold' />
          </div>
        </button>
        <button onClick={()=> setOverridePlaybackRate(2)} data-active-state={overridePlaybackRate === 2 ? 'active' : 'inactive'} className="page-videoPreviewPlayer-playbackRateControls-btn">
          <div className='page-videoPreviewPlayer-playbackRateControls-btn-label'>
            2.0
          </div>
          <div className='page-videoPreviewPlayer-playbackRateControls-btn-iconContainer'>
            <Icon name='multiplySemibold' />
          </div>
        </button>
      </div>
      */}


      <VideoPreviewPlayerControls
        showControls={webcamReady}
        playing={playing}
        editable={true}
        onPlayPauseClick={() => {
          setPlaying(!playing);
          if (!playing) {
            setShowPreControls(false);
          }
        }}
        progress={progressFraction}
        onSeek={handleSeekChange}
        onSeekMouseDown={handleSeekMouseDown}
        onSeekMouseUp={handleSeekMouseUp}
        duration={(trimEnd - trimStart) * underlayDuration}
        playbackRate={overridePlaybackRate}
        onPlaybackRateChange={setOverridePlaybackRate}          
      />

      <button
        onClick={() => {
          setPlaying(!playing);
          if (!playing) {
            setShowPreControls(false);
          }
        }}
        className="videoPlayer-bgPlayPauseBtn"
      />
    </div>
  );
}

export default VideoPreviewPlayerOverlay;
