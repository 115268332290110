import React, { useState, useEffect, useRef, useCallback } from 'react';
import CampaignVariantRecordToolbar from './CampaignVariantRecordToolbar';
import CampaignVariantRecordCameraSettings from './CampaignVariantRecordCameraSettings';
import CampaignVariantRecordInstructions from './CampaignVariantRecordInstructions';

const CampaignVariantRecord = ({ status, setStatus, onRecordingComplete }) => {
  const [activeCamera, setActiveCamera] = useState(null);
  const [activeMicrophone, setActiveMicrophone] = useState(null);
  const [devices, setDevices] = useState({ cameras: [], microphones: [] });

  const isMobile = true
  
  const webcamVideoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const mediaStreamRef = useRef(null);
  const recordedChunksRef = useRef([]);
  const currentStatusRef = useRef(status);
  const recordingTimeoutRef = useRef(null);
  
  // Remove videoElement state as it's redundant with webcamVideoRef
  
  useEffect(() => {
    currentStatusRef.current = status;
  }, [status]);

  const initializeMediaRecorder = useCallback(
    (stream) => {
      console.log('initialize media recorder------');
      const initializeWithMimeType = (mimeType) => {
        const options = {
          mimeType: mimeType,
          videoBitsPerSecond: isMobile ? 2000000 : 3000000,
        };
        try {
          const mediaRecorder = new MediaRecorder(stream, options);
          mediaRecorderRef.current = mediaRecorder;
          recordedChunksRef.current = [];

          mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              recordedChunksRef.current.push(event.data);
            }
          };
        } catch (error) {
          console.error('Error creating MediaRecorder:', error);
          throw error;
        }
      };

      try {
        initializeWithMimeType('video/webm;codecs=vp8,opus');
      } catch (webmError) {
        console.warn('WebM not supported, falling back to MP4:', webmError);
        try {
          initializeWithMimeType('video/mp4');
        } catch (mp4Error) {
          console.error('Neither WebM nor MP4 are supported:', mp4Error);
          alert('Your device does not support the required video format. Please try a different device or browser.');
        }
      }
    },
    [isMobile]
  );

  const stopMediaStream = useCallback(() => {
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => {
        track.stop();
      });
      mediaStreamRef.current = null;
    }
    if (mediaRecorderRef.current) {
      if (mediaRecorderRef.current.state !== 'inactive') {
        mediaRecorderRef.current.stop();
      }
      mediaRecorderRef.current = null;
    }
    if (webcamVideoRef.current) {
      webcamVideoRef.current.srcObject = null;
    }
  }, []);

  const setupMediaStream = useCallback(() => {
    if (!activeCamera && !activeMicrophone) return; // Don't setup if no devices selected
    
    stopMediaStream();
    const constraints = {
      video: {
        deviceId: activeCamera ? { exact: activeCamera } : undefined,
        facingMode: isMobile ? 'environment' : 'user',
        width: { ideal: isMobile ? 1280 : 1920 },
        height: { ideal: isMobile ? 720 : 1080 },
        aspectRatio: { ideal: 16 / 9 },
      },
      audio: activeMicrophone ? { deviceId: { exact: activeMicrophone } } : true,
    };
    
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        if (webcamVideoRef.current) {
          mediaStreamRef.current = stream;
          webcamVideoRef.current.srcObject = stream;
          initializeMediaRecorder(stream);
        }
      })
      .catch((error) => {
        console.error('Error accessing media devices.', error);
        alert('Unable to access camera or microphone. Please check your device settings and permissions.');
      });
  }, [activeCamera, activeMicrophone, isMobile, stopMediaStream, initializeMediaRecorder]);

  // Initial device setup and permissions
  useEffect(() => {
    const initializeDevices = async () => {
      try {
        // First get permissions
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        mediaStreamRef.current = stream;
        
        // Then enumerate devices
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter((device) => device.kind === 'videoinput');
        const audioDevices = devices.filter((device) => device.kind === 'audioinput');
        
        // Set available devices
        setDevices({
          cameras: videoDevices.map((device) => ({ label: device.label, value: device.deviceId })),
          microphones: audioDevices.map((device) => ({ label: device.label, value: device.deviceId })),
        });

        // Set initial devices (prefer saved preferences)
        const savedCamera = localStorage.getItem('preferredCamera');
        const savedMicrophone = localStorage.getItem('preferredMicrophone');

        setActiveCamera(
          savedCamera && videoDevices.some((device) => device.deviceId === savedCamera)
            ? savedCamera
            : videoDevices[0]?.deviceId
        );

        setActiveMicrophone(
          savedMicrophone && audioDevices.some((device) => device.deviceId === savedMicrophone)
            ? savedMicrophone
            : audioDevices[0]?.deviceId
        );
      } catch (error) {
        console.error('Error during device initialization:', error);
        if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
          alert('Please allow access to your camera and microphone to use this feature.');
        } else {
          alert('Error accessing your camera or microphone. Please make sure they are properly connected.');
        }
      }
    };

    initializeDevices();

    return () => {
      stopMediaStream();
    };
  }, []); // Run only once on mount

  // Setup media stream when devices are selected and status is 'pre'
  useEffect(() => {
    if (status === 'pre' && activeCamera && activeMicrophone) {
      setupMediaStream();
    }
  }, [status, activeCamera, activeMicrophone, setupMediaStream]);

  // Handle recording state
  useEffect(() => {
    if (status === 'recording' && mediaRecorderRef.current) {
      mediaRecorderRef.current.start(isMobile ? 1000 : 100);
      console.log('Recording started');
    }
  }, [status, isMobile]);

  const stopRecording = useCallback(
    (shouldComplete = false) => {
      if (recordingTimeoutRef.current) {
        clearTimeout(recordingTimeoutRef.current);
      }
      
      if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
        mediaRecorderRef.current.addEventListener(
          'stop',
          () => {
            if (shouldComplete && recordedChunksRef.current.length > 0) {
              recordingTimeoutRef.current = setTimeout(() => {
                const blob = new Blob(recordedChunksRef.current, {
                  type: mediaRecorderRef.current.mimeType,
                });
                const url = URL.createObjectURL(blob);
                onRecordingComplete(url);
              }, 250);
            }
          },
          { once: true }
        );

        if (mediaRecorderRef.current.state === 'recording') {
          try {
            mediaRecorderRef.current.requestData();
          } catch (e) {
            console.warn('Could not request final data chunk:', e);
          }
        }
        mediaRecorderRef.current.stop();
      }
    },
    [onRecordingComplete]
  );

  const restartRecording = useCallback(() => {
    stopRecording(false);
    recordedChunksRef.current = [];
    setStatus('pre');
  }, [stopRecording, setStatus]);

  const handleCameraChange = useCallback((newCamera) => {
    setActiveCamera(newCamera);
    localStorage.setItem('preferredCamera', newCamera);
  }, []);

  const handleMicrophoneChange = useCallback((newMicrophone) => {
    setActiveMicrophone(newMicrophone);
    localStorage.setItem('preferredMicrophone', newMicrophone);
  }, []);

  // Keyboard handlers
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && event.shiftKey) {
        event.preventDefault();
        if (currentStatusRef.current === 'pre') {
          setStatus('countdown');
        } else if (currentStatusRef.current === 'recording') {
          restartRecording();
        }
      }
      if (event.code === 'Space' && currentStatusRef.current === 'recording') {
        event.preventDefault();
        stopRecording(true);
        setStatus('post');
      }
    };
    
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [setStatus, stopRecording, restartRecording]);

  // Cleanup
  useEffect(() => {
    return () => {
      if (recordingTimeoutRef.current) {
        clearTimeout(recordingTimeoutRef.current);
      }
      stopMediaStream();
    };
  }, [stopMediaStream]);

  return (
    <>
      <video
        ref={webcamVideoRef}
        autoPlay
        playsInline
        muted
        className="campaignVariantPage-video"
      />

      {status === 'pre' && !isMobile && (
        <CampaignVariantRecordCameraSettings
          activeCamera={activeCamera}
          setActiveCamera={handleCameraChange}
          activeMicrophone={activeMicrophone}
          setActiveMicrophone={handleMicrophoneChange}
          cameras={devices.cameras}
          microphones={devices.microphones}
        />
      )}

      <CampaignVariantRecordToolbar
        status={status}
        restartRecording={restartRecording}
        stopRecording={stopRecording}
        setStatus={setStatus}
      />
    </>
  );
};

export default CampaignVariantRecord;