import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { fetchSpeech } from '../actions/textToSpeech';

const VoiceMatchPlayground = ({voiceId}) => {
  const [inputText, setInputText] = useState('');
  const [audioUrl, setAudioUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const audioRef = useRef(null);

  const sampleTexts = [
    "The quick brown fox jumps over the lazy dog.",
    "Hello, world! How are you today?",
    "React makes it painless to create interactive UIs."
  ];

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleGenerate = async () => {
    if (inputText.trim()) {
      setIsLoading(true);
      try {
        // For this example, we're using a placeholder voiceId. Replace this with the actual voiceId.
        const audioUrl = await dispatch(fetchSpeech(inputText, voiceId));
        setAudioUrl(audioUrl);
      } catch (error) {
        console.error('Error generating speech:', error);
        // You might want to set an error state here and display it to the user
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSampleClick = (text) => {
    setInputText(text);
    inputRef.current.focus();
  };

  const handlePlay = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  return (
    <div>
      <h2>Try your voice!</h2>
      <div>
        <input
          ref={inputRef}
          type="text"
          value={inputText}
          onChange={handleInputChange}
          placeholder="Enter text here or select a sample"
        />
        <button onClick={handleGenerate} disabled={isLoading}>
          {isLoading ? 'Generating...' : 'Generate'}
        </button>
      </div>
      <div>
        <h3>Sample Texts:</h3>
        {sampleTexts.map((text, index) => (
          <button key={index} onClick={() => handleSampleClick(text)}>
            Sample {index + 1}
          </button>
        ))}
      </div>
      {audioUrl && (
        <div>
          <h3>Generated Audio:</h3>
          <audio ref={audioRef} src={audioUrl} controls />
          <button onClick={handlePlay}>Play</button>
        </div>
      )}
    </div>
  );
};

export default VoiceMatchPlayground;