import React, { useState } from 'react';
import Icon from '../Icon'

const BackBtn = ({backTo}) => {  

  return (
    <button className='flow-backBtn' onClick={backTo}>
      <Icon name='backArrow' />
    </button>
  );
};

export default BackBtn;