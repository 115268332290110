import findIndex from 'lodash/findIndex'



export default function (state = null, action) {
	switch (action.type) {
    case 'SIGN_OUT':
      return null
    case 'FETCH_CAMPAIGN_SUCCESS':
      return action.campaign;
    
    default:
      return state;
	}
}
