import React, { useState, useRef, useEffect } from 'react';
import Icon from '../Icon';
import voicesData from '../../utils/voicesData';

const LibraryVoiceButton = ({ selectedLibraryVoice }) => {
  const [playingVoice, setPlayingVoice] = useState(null);
  const audioRef = useRef(null);

  const getTransformedUrl = (src) => {
    return `https://res.cloudinary.com/yarn/video/upload/${src}.mp3`;
  };

  useEffect(() => {
    const audio = new Audio();
    audio.preload = 'auto';
    audioRef.current = audio;

    const handleVisibilityChange = () => {
      if (document.hidden) {
        stopAudio();
      }
    };

    const handleAudioEnded = () => {
      setPlayingVoice(null);
    };

    audio.addEventListener('ended', handleAudioEnded);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      stopAudio();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      audio.removeEventListener('ended', handleAudioEnded);
      audio.src = '';
    };
  }, []);

  useEffect(() => {
    if (selectedLibraryVoice) {
      const selectedVoice = voicesData.find(voice => voice.id === selectedLibraryVoice);
      if (selectedVoice) {
        audioRef.current.src = getTransformedUrl(selectedVoice.src);
      }
    }
  }, [selectedLibraryVoice]);

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setPlayingVoice(null);
  };

  const handlePlayPause = () => {
    if (playingVoice === selectedLibraryVoice) {
      stopAudio();
    } else {
      stopAudio();
      audioRef.current.play();
      setPlayingVoice(selectedLibraryVoice);
    }
  };
  
  const selectedVoice = voicesData.find(voice => voice.id === selectedLibraryVoice);
  if (!selectedVoice) return null;

  return (
    <div onClick={handlePlayPause} className="selectedVoice">
      <div className="selectedVoice-inner">                
        <div className="selectedVoice-title">
          {selectedVoice.label}
        </div>
        <button 
          className="selectedVoice-playPauseBtn"        
        >
          {playingVoice === selectedLibraryVoice ? <Icon name="playbackPause" /> : <Icon name="playbackPlay" />}
        </button>       
        <div className='selectedVoice-activeBorder' />   
        <div className='selectedVoice-activeBorder-activeIconContainer'>
          <Icon name='checkmark' />
        </div>
      </div>
    </div>
  );
};

export default LibraryVoiceButton;