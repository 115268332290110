import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player/file';
import VideoRecordModalInstructionsWizardExampleControls from './VideoRecordModalInstructionsWizardExampleControls'
import { convertMedia } from '@remotion/webcodecs';
import { webFileReader } from '@remotion/media-parser/web-file';

const VideoRecordModalInstructionsWizardExample = ({ 
  dimensions,
  videoGap,
  videoTitleHeight,
  playbackControlsHeight,
  videosTopMargin,
  videosBottomMargin
}) => {
  const { height, inputVideoWidth, generatedVideoWidth } = dimensions;
  
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [seeking, setSeeking] = useState(false);

  
  let inputPlayer = null;
  let generatedPlayer = null;
  
  const inputStartTime = 0;
  const inputDuration = 9;
  const inputCloudinaryId = 'owen-capture_nizmm7';
  const generatedCloudinaryId = 'owen-generated_eeawzo';
  const inputVideoUrl = `https://res.cloudinary.com/yarn/video/upload/so_0,eo_${inputDuration}/${inputCloudinaryId}.mp4`;
  const generatedVideoUrl = `https://res.cloudinary.com/yarn/video/upload/so_${inputStartTime},eo_${inputStartTime + inputDuration}/${generatedCloudinaryId}.mp4`;

  const handleProgress = (state) => {
    if (!seeking) {
      setProgress(state.played);
    }
  };

  const handlePlay = () => {
    setPlaying(true);
  };

  const handlePause = () => {
    setPlaying(false);
  };

  const handleSeekChange = (value) => {
    const floatValue = parseFloat(value);
    setProgress(floatValue);
    inputPlayer?.seekTo(floatValue);
    generatedPlayer?.seekTo(floatValue);
  };

  const handleSeekMouseDown = () => {
    setSeeking(true);
  };

  const handleSeekMouseUp = (value) => {
    setSeeking(false);
    const floatValue = parseFloat(value);
    setProgress(floatValue);
    inputPlayer?.seekTo(floatValue);
    generatedPlayer?.seekTo(floatValue);
  };

  const handleEnded = () => {
    setPlaying(false);
    setProgress(0);
    inputPlayer?.seekTo(0);
    generatedPlayer?.seekTo(0);
  };

  return (
    <div className='videoRecordModal--instructions-example'>      
      <div 
        style={{ 
          marginTop: `${videosTopMargin}px`, 
          marginBottom: `${videosBottomMargin}px` 
        }} 
        className='videoRecordModal--instructions-example-videos'
      >      
        <button onClick={() => setPlaying(!playing)} className='videoRecordModal--instructions-example-videos-bgPlayPauseButton' />
        <div 
          className='videoRecordModal--instructions-example-videos-video videoRecordModal--instructions-example-videos-video--input'
          style={{ width: `${inputVideoWidth}px` }}
        >    
          <div 
            className='videoRecordModal--instructions-example-videos-video-title'
            style={{ height: `${videoTitleHeight}px` }}
          >
            <div className='videoRecordModal--instructions-example-videos-video-title-inner'>
              Input Video
            </div>
          </div>
          <div 
            className='videoRecordModal--instructions-example-videos-video-videoBox'
            style={{ height: `${height}px` }}
          >
            <ReactPlayer
              ref={p => { inputPlayer = p }}
              url={inputVideoUrl}
              width="100%"
              height="100%"
              playing={playing}
              progressInterval={20}
              onPlay={handlePlay}
              onPause={handlePause}
              onProgress={handleProgress}
              onEnded={handleEnded}
              controls={false}
              muted
            />
          </div>
        </div>

        <div 
          className='videoRecordModal--instructions-example-videos-hSpacer' 
          style={{ width: `${videoGap}px` }} 
        />

        <div 
          className='videoRecordModal--instructions-example-videos-video videoRecordModal--instructions-example-videos-video--generated'
          style={{ width: `${generatedVideoWidth}px` }}
        >      
          <div 
            className='videoRecordModal--instructions-example-videos-video-title'
            style={{ height: `${videoTitleHeight}px` }}
          >
            <div className='videoRecordModal--instructions-example-videos-video-title-inner'>
              Generated Video
            </div>
          </div>
          <div 
            className='videoRecordModal--instructions-example-videos-video-videoBox'
            style={{ height: `${height}px` }}
          >
            <ReactPlayer
              ref={p => { generatedPlayer = p }}
              url={generatedVideoUrl}
              width="100%"
              height="100%"
              playing={playing}
              progressInterval={20}
              onPlay={handlePlay}
              onPause={handlePause}
              onEnded={handleEnded}
              controls={false}
            />
          </div>
        </div>
      </div>

      <div 
        className='videoRecordModal--instructions-example-playbackControlsContainer'
        style={{ height: `${playbackControlsHeight}px` }}
      >              
        <VideoRecordModalInstructionsWizardExampleControls
          playing={playing}
          onPlayPauseClick={() => setPlaying(!playing)}
          progress={progress}
          onSeek={handleSeekChange}
          onSeekMouseDown={handleSeekMouseDown}
          onSeekMouseUp={handleSeekMouseUp}          
        />        
      </div>
    </div>        
  );
};

export default VideoRecordModalInstructionsWizardExample;