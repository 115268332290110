import React, { useState } from 'react';
import Icon from '../Icon';

const CampaignVariantContextCard = ({type, text}) => {
  
  let iconName = 'chevron'

  if(type === 'location'){
    iconName = 'pin'
  }
  if(type === 'education'){
    iconName = 'graduationcap'
  }
  if(type === 'joinedDate'){
    iconName = 'calendar'
  }
  if(type === 'employeeCount'){
    iconName = 'building'
  }

  let textOnly = false
  if(type === 'companyNews'){
    textOnly = true
  }

  

  return (
    <div data-text-only-layout={textOnly ? 'true' : 'false'} className="campaignVariantPage-contextCards-card">

      {!textOnly && iconName &&
      <div className="campaignVariantPage-contextCards-card-iconContainer">
        <Icon name={iconName} />
      </div>
      }

      <div className="campaignVariantPage-contextCards-card-text">
        {text}
      </div>

    </div>
  );
};



function formatYears(yearsFloat) {
  const totalMonths = Math.ceil(yearsFloat * 12);
  const years = Math.floor(totalMonths / 12);
  const months = totalMonths % 12;
  const parts = [];
  if (years > 0) {
    parts.push(`${years} yr${years !== 1 ? 's' : ''}`);
  }
  if (months > 0) {
    parts.push(`${months} mo${months !== 1 ? 's' : ''}`);
  }
  return parts.join(' ');
}


const CampaignVariantContextCards = ({companyNews,simpleProfile}) => {
  return (
    <div className="campaignVariantPage-contextCards">

      <div className="campaignVariantPage-contextCards-personalList">
        <div className="campaignVariantPage-contextCards-personalList-header">        
          Profile
        </div>

        {simpleProfile && simpleProfile.location &&
        <CampaignVariantContextCard 
          type='location'
          text={`Based in ${simpleProfile.location}`}
        />
      }

      {simpleProfile && simpleProfile.college &&
        <CampaignVariantContextCard 
          type='education'
          text={simpleProfile.college}
        />
      }

      {simpleProfile && simpleProfile.tenure &&
        <CampaignVariantContextCard 
          type='joinedDate'
          //text={`Joined ${formatYears(simpleProfile.tenure)} ago`}
           text={`Joined ${formatYears(simpleProfile.tenure)} ago`}
        />
      }
      </div>

      {companyNews && companyNews.length &&
      <div className="campaignVariantPage-contextCards-companyList">        
        <div className="campaignVariantPage-contextCards-companyList-header">        
          Company News
        </div>
        {companyNews && companyNews.slice(0, 5).map((news, index) => (
          <CampaignVariantContextCard 
            key={index}
            type='companyNews'
            text={news}
          />
        ))}
      </div>
    }
    </div>
  );
};

export default CampaignVariantContextCards;