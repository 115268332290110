import React, { useState } from 'react';
import Icon from '../Icon'

const SelectUseCaseGrid = () => {
  const useCases = [
    { id: 0, label: 'Internationalization', description: 'Native multiple-language support'},
    { id: 1, label: 'Match author voices', description: 'Using PVC cloning'},
    { id: 2, label: 'Data Privacy', description: 'Good for Euro customers'},
    { id: 3, label: 'Accessibility', description: 'Mentions ADA support'},
    { id: 4, label: 'Millenial Demographic', description: 'Podcasting and revenue expansion'},
    { id: 5, label: 'Mobile Support', description: 'Including CarPlay'},
  ];

  const [activeUseCases, setActiveUseCases] = useState([]);

  const toggleUseCase = (id) => {
    setActiveUseCases(prev => 
      prev.includes(id) 
        ? prev.filter(useCaseId => useCaseId !== id) 
        : [...prev, id]
    );
  };
  
  return (
    <div className="selectGrid selectGrid--music">
      {useCases.map((useCase) => {
        const isActive = activeUseCases.includes(useCase.id);
        return (
          <button
            key={useCase.id}
            data-active-state={isActive ? 'active' : 'inactive'}
            className='selectGrid-btn selectGrid-btn--voice'
            onClick={() => toggleUseCase(useCase.id)}          
          >
            <div className='selectGrid-btn-inner'>
              <div className='selectGrid-btn--voice-title selectGrid-btn--voice-title--useCase'>
                {useCase.label}
              </div>
              <div className='selectGrid-btn--music-subtitle selectGrid-btn--music-subtitle--useCase '>
                {useCase.description}
              </div>
              {isActive &&
                <div className='selectGrid-btn--voice-activeIconContainer'>
                  <Icon name='checkmark' />
                </div>
              }
              <div className='selectGrid-btn-activeBorder' />
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default SelectUseCaseGrid;