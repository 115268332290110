import React, { useState, useEffect,useRef } from 'react';
import { useParams } from 'react-router-dom';
import DeliveryPage from '../components/deliveryPage/DeliveryPage';
import {fetchVariantByShareId,fetchRenderJobByShareId} from '../actions/fetchByShareId'

const DeliveryPageContainer = () => {
  const [variantData, setVariantData] = useState(null);
  const [renderJobStatus, setRenderJobStatus] = useState(null);
  const { shareId } = useParams();
  const pollingTimeout = useRef(null)

  useEffect(() => {
    const fetchVariantData = async () => {
      try {
        const response = await fetchVariantByShareId(shareId)
        setVariantData(response)
      } catch (error) {
        console.log(error)
      }
    };

    const pollRenderJobStatus = async () => {
      try {
        const response = await fetchRenderJobByShareId(shareId);
        setRenderJobStatus(response);
        if (response.muxAssetStatus !== 'ready') {
          pollingTimeout.current=setTimeout(pollRenderJobStatus, 2000);
        }
      } catch (error) {
        console.log(error);
        pollingTimeout.current=setTimeout(pollRenderJobStatus, 50000);
      }
    };
    pollRenderJobStatus()
    fetchVariantData();
    return () => {
      if (pollingTimeout.current) {
        clearTimeout(pollingTimeout.current);
      }
    };
  }, [shareId]);


  let muxPlaybackId 
  let muxAssetStatus
  let s3Url
  
  if(renderJobStatus){
    muxAssetStatus=renderJobStatus.muxAssetStatus
    if(muxAssetStatus=='ready'){
      muxPlaybackId=renderJobStatus.muxPublicPlaybackId
      s3Url= renderJobStatus.s3Url
    }
  }

  // console.log(variantData)
  // console.log(renderJobStatus)

  return (
    <div className='deliveryPageContainer'>
      <DeliveryPage 
        muxAssetStatus={muxAssetStatus}
        s3Url={s3Url}
        muxPlaybackId={muxPlaybackId}
        variantData={variantData} />    
    </div>
  );
};

export default DeliveryPageContainer;