import { useState, useEffect, useRef } from 'react';

// Constants for level calibration
const MIN_INPUT_THRESHOLD = 0;
const MAX_INPUT_THRESHOLD = 60;

// Decay settings (in dB per second)
const DECAY_RATE = 60; // -60 dB/sec is a common standard
const FRAME_RATE = 60; // Assuming 60fps for requestAnimationFrame
const DECAY_PER_FRAME = DECAY_RATE / FRAME_RATE;

// Additional smoothing settings
const SMOOTHING_FACTOR = 0.85; // Higher = smoother but less responsive
const RMS_AVERAGING_SIZE = 3; // Number of RMS values to average

export const useMicrophoneLevel = (mediaStream) => {
  const [level, setLevel] = useState(0);
  const analyserRef = useRef(null);
  const animationFrameRef = useRef(null);
  const audioContextRef = useRef(null);
  const lastLevelRef = useRef(0);
  const lastTimeRef = useRef(0);
  const rmsHistoryRef = useRef([]);
  
  useEffect(() => {
    if (!mediaStream) return;
    
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 4096; // Larger FFT size for more stable readings
    analyser.smoothingTimeConstant = 0.9; // Increased smoothing
    
    const source = audioContext.createMediaStreamSource(mediaStream);
    source.connect(analyser);
    
    analyserRef.current = analyser;
    audioContextRef.current = audioContext;
    const dataArray = new Float32Array(analyser.frequencyBinCount);
    
    const updateLevel = (timestamp) => {
      const deltaTime = lastTimeRef.current ? (timestamp - lastTimeRef.current) / 1000 : 0;
      lastTimeRef.current = timestamp;

      analyser.getFloatTimeDomainData(dataArray);
      
      // Calculate RMS value
      const rms = Math.sqrt(dataArray.reduce((acc, val) => acc + (val * val), 0) / dataArray.length);
      
      // Keep a history of RMS values for averaging
      rmsHistoryRef.current.push(rms);
      if (rmsHistoryRef.current.length > RMS_AVERAGING_SIZE) {
        rmsHistoryRef.current.shift();
      }
      
      // Average the RMS values
      const averageRms = rmsHistoryRef.current.reduce((acc, val) => acc + val, 0) / rmsHistoryRef.current.length;
      
      // Convert to dB scale
      const db = 20 * Math.log10(Math.max(averageRms, 1e-6));
      
      // Normalize to 0-1 range
      let currentLevel = Math.max(0, Math.min(1, (db + 60) / 60));
      
      // Apply additional smoothing
      currentLevel = (SMOOTHING_FACTOR * lastLevelRef.current) + ((1 - SMOOTHING_FACTOR) * currentLevel);
      
      // Apply decay if needed
      if (currentLevel < lastLevelRef.current) {
        const decayAmount = DECAY_PER_FRAME * deltaTime;
        currentLevel = Math.max(currentLevel, lastLevelRef.current - decayAmount);
      }
      
      lastLevelRef.current = currentLevel;
      setLevel(currentLevel);
      animationFrameRef.current = requestAnimationFrame(updateLevel);
    };
    
    updateLevel(performance.now());
    
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
      lastLevelRef.current = 0;
      lastTimeRef.current = 0;
      rmsHistoryRef.current = [];
    };
  }, [mediaStream]);
  
  return level;
};