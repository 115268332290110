import React from 'react';
import Icon from '../Icon'
import backgroundColorOptions from '../../utils/backgroundColorOptions'

const SelectBGColorGrid = ({ selectNext, smallSize, selectedBGColor, setSelectedBGColor }) => {
  // const bgOptions = [
  //   { id: 0, color: 'rgb(63,141,239)' ,label:'blue',config:{projectBackground:3,firstSlide:9,lastSlide:21}},
  //   { id: 1, color: 'rgb(94,188,120)' ,label:'green',config:{projectBackground:4,firstSlide:10,lastSlide:22}},
  //   { id: 2, color: 'rgb(219,91,109)' ,label:'red',config:{projectBackground:7,firstSlide:13,lastSlide:25}},
  //   { id: 3, color: 'rgb(161,115,223)' ,label:'purple',config:{projectBackground:8,firstSlide:14,lastSlide:26}},
  //   { id: 4, color: 'rgb(238,129,98)' ,label:'orange',config:{projectBackground:5,firstSlide:11,lastSlide:23}},
  //   { id: 5, color: 'rgb(22,57,102)' ,label:'navy',config:{projectBackground:6,firstSlide:12,lastSlide:24}}
  // ];

  const cloudinaryBaseUrl = 'https://res.cloudinary.com/yarn/image/upload';
  const transformations = 'c_scale,w_400,q_auto,f_png';

  return (
    <div data-small-size={smallSize} className="selectGrid selectGrid--color">
      {backgroundColorOptions.map((bg) => (
        <button
          key={bg.id}
          data-active-state={selectedBGColor === bg.id ? 'active' : 'inactive'}
          className='selectGrid-btn selectGrid-btn--color'
          onClick={() => setSelectedBGColor(bg.id)}
          onDoubleClick={selectNext}
        >
          <div className='selectGrid-btn-inner'>
          {bg.src ? (
            <img className='selectGrid-btn--color-img' src={`${cloudinaryBaseUrl}/${transformations}/${bg.src}`} alt={`Background ${bg.id}`} />
          ) : (
            <div className="selectGrid-btn--color-colorSwatch" style={{ background: bg.color }} />
          )}
          {selectedBGColor === bg.id &&
            <div className='selectGrid-btn--color-iconContainer'> 
              <Icon name='checkmark' />
            </div>
          }
          <div className='selectGrid-btn-activeBorder' />
          </div>
        </button>
      ))}
    </div>
  );
};

export default SelectBGColorGrid;