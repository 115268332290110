import React from 'react';

const VideoPlayerWatermark = ({ size }) => {
  const filterId = React.useId();
  
  return (
    <div 
      style={{ 
        position: 'absolute', 
        width: size, 
        height: size, 
        pointerEvents: 'none', 
        zIndex: 10,
        isolation: 'isolate', // Important for blend modes
        mixBlendMode: 'difference' // Move blend mode to container
      }}
    >
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 1000 1000"
        style={{ 
          width: '100%', 
          height: '100%',
        }}
      >
        <defs>
          <filter id={`watermark-${filterId}`}>
            {/* Simplified filter for better performance */}
            <feGaussianBlur stdDeviation="1"/>
            <feComponentTransfer>
              <feFuncA type="linear" slope="0.8"/> {/* Adjust opacity */}
            </feComponentTransfer>
          </filter>
        </defs>
        
        <g style={{ opacity: 0.85 }}> {/* Add group for opacity control */}
          <path 
            d="M6.63 528.526c-9.704-12.333-8.647-29.974 2.46-41.056 11.106-11.086 28.75-12.103 41.06-2.374l348.883 275.728-352.785-423.95c-10.196-12.253-9.42-30.245 1.791-41.575 11.213-11.33 29.195-12.294 41.554-2.227l453.78 369.637L143.623 187.3a30.737 30.737 0 0 1-4.594-7c-4.04-8.457-3.968-18.26-.03-26.582a30.76 30.76 0 0 1 6.104-8.645 30.759 30.759 0 0 1 8.553-6.045c8.47-4.045 18.29-3.968 26.618-.013a30.727 30.727 0 0 1 7.027 4.606l475.405 399.75-369.634-453.78c-10.067-12.358-9.103-30.34 2.227-41.552s29.322-11.988 41.574-1.792l423.952 352.785L485.093 50.15c-9.729-12.31-8.707-29.954 2.375-41.06 11.086-11.107 28.726-12.164 41.055-2.46l284.073 223.55-78.228-109.286c-9.95-13.901-6.749-33.238 7.15-43.189 13.904-9.952 33.237-6.749 43.192 7.152l204.814 286.117c8.972 12.532 7.358 29.752-3.786 40.4-11.14 10.646-28.417 11.475-40.53 1.945L696.09 217.275l297.242 376.103c9.83 12.435 8.672 30.287-2.675 41.356-11.352 11.069-29.227 11.772-41.414 1.632l-406.422-338.2L918 758.75c10.083 12.382 9.096 30.402-2.286 41.608-11.379 11.206-29.417 11.914-41.64 1.636l-452.95-380.872 380.869 452.954c10.277 12.223 9.57 30.257-1.632 41.64-11.206 11.378-29.23 12.368-41.613 2.281l-460.58-375.174 338.196 406.422c10.14 12.183 9.437 30.062-1.627 41.41-11.07 11.35-28.922 12.505-41.361 2.675l-376.1-297.243 196.044 249.125c9.53 12.108 8.7 29.385-1.946 40.529-10.647 11.14-27.868 12.754-40.399 3.785L84.857 784.708c-13.901-9.95-17.103-29.288-7.152-43.187 9.951-13.903 29.288-17.105 43.19-7.155l109.284 78.233L6.629 528.526Z" 
            fill="white"
            filter={`url(#watermark-${filterId})`}
          />
        </g>
      </svg>
    </div>
  );
};

export default VideoPlayerWatermark;