import React, { useState, useEffect,useRef } from 'react';
import { useParams } from 'react-router-dom';
import OmniDeliveryPage from '../components/deliveryPage/OmniDeliveryPage';
import {fetchCloudflareForShareId,fetchVariablesForShareId} from '../actions/videoDelivery'

const OmniVideoPageContainer = () => {
	const [cloudflareId, setCloudflareId] = useState(null);
  const [variables, setVariables] = useState({});
	const { shareId } = useParams();

	useEffect(() => {
		const fetchCloudflareId = async () => {
			try {
				const response = await fetchCloudflareForShareId(shareId)
				setCloudflareId(response.cloudflare_id)
			} catch (error) {
				console.log(error)
			}
		};


    const fetchVariables = async () => {
      try {
        const response = await fetchVariablesForShareId(shareId)
        setVariables(response)
      } catch (error) {
        console.log(error)
      }
    }
		fetchCloudflareId() 
    fetchVariables()
	}, [shareId]);

	
	return (
		<div className='deliveryPageContainer'>
      <OmniDeliveryPage
				cloudflarePlaybackId={cloudflareId}
				variantData={variables}
			/> 
		</div>
	);
};

export default OmniVideoPageContainer;