import React, { useState, useRef, useEffect } from 'react';
import Icon from '../Icon'

const SelectAIVoiceGrid = ({ smallSize,selectedVoiceId,setSelectedVoiceId  }) => {

  const [playingVoice, setPlayingVoice] = useState(null);
  const audioRefs = useRef({});


  const voicesData = [
    {
      "id": "0",
      "label": "Your voice with AI",        
      "noPlayback": true,
      "providerId": '0',
      "subtitle": "A matched clone of you."
    },
    // {
    //   "id": "2228",
    //   "label": "Annie",
    //   "src": "v1723229985/clay/annie_sample_bxopr4",
    //   "providerId": "OYTbf65OHHFELVut7v2H",
    //   "modelId":"eleven_turbo_v2_5",
    //   "stability": 0.86,
    //   "similarityBoost": 0.75  
    // },
    {
      "id": "2234",
      "label": "Daniel",
      "src": "v1722963892/clay/daniel_sample_gql759",
      "providerId": "uFIXVu9mmnDZ7dTKCBTX",
      "modelId":"eleven_multilingual_v2",
      "stability": 0.5,
      "similarityBoost": 0.75
    },
    {
      "id": "5",
      "label": "Jada",
      "src": "v1722963615/clay/jada_sample_noxbbg",
      "providerId": "kQwC2rkdNm44J1JTkuc6",
      "stability": 0.5,
      "similarityBoost": 0.99,
      "styleExaggeration": 0.45
    },
    // {
    //   "id": "172932586",
    //   "label": "James",
    //   "src": "v1723230024/clay/james_sample_rzd81e",
    //   "providerId": "ErXwobaYiN019PkySvjV",
    //   "modelId":"eleven_multilingual_v2",
    //   "styleExaggeration": 0.12
    // },
    {
      "id": "1",
      "label": "Sarah",
      "src": "/v1722963408/clay/sarah_sample_rr7h5w",
      "providerId": "Io0CZSZh6JLTZOA1dVGo"
    },
    {
      "id": "1590474050",
      "label": "Tom",
      "src": "v1722963700/clay/tom_sample_rcibyf",
      "providerId": "iP95p4xoKVk53GoZ742B",
      "modelId":"eleven_turbo_v2",
      "styleExaggeration": 0.12
    },
    {
      "id": "2533",
      "label": "Yash",
      "src": "/v1739486684/clay/yash_sample_hcfyk3",      
      "providerId": "8DK1SVnQRPUpUiJTZiEo",
      "stability": 0.5,
      "similarityBoost": 0.99,
      "styleExaggeration": 0.15
    },
  ]



  const getTransformedUrl = (src) => {
    // return `https://res.cloudinary.com/yarn/video/upload/so_20/br_192k/${src}.mp3`;
      return `https://res.cloudinary.com/yarn/video/upload/${src}.mp3`;
  };

  const stopAllAudio = () => {
    Object.values(audioRefs.current).forEach(audio => {
      audio.pause();
      audio.currentTime = 0;
    });
    setPlayingVoice(null);
  };

  useEffect(() => {
    // Preload all audio files
    voicesData.forEach(option => {
      const audio = new Audio();
      audio.src = getTransformedUrl(option.src);
      audio.preload = 'auto';
      audioRefs.current[option.id] = audio;
      audio.addEventListener('ended', () => {
      setPlayingVoice(null);
    });
  });


  const handleVisibilityChange = () => {
    if (document.hidden) {
      stopAllAudio();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      stopAllAudio();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      Object.values(audioRefs.current).forEach(audio => {
      audio.src = '';
      });
    };
  }, []);

  const handlePlayPause = (id, providerId) => {

    if (playingVoice === id) {
      audioRefs.current[id].pause();
      setPlayingVoice(null);
    } else {
      stopAllAudio();
      audioRefs.current[id].currentTime = 0;
      audioRefs.current[id].play();
      setPlayingVoice(id);
    }
    setSelectedVoiceId(providerId);
  };


  return (
    <div data-small-size={smallSize} className="selectGrid selectGrid--music">
      {voicesData.map((voice) => {
        return (
          <div
            key={voice.id}
            data-active-state={selectedVoiceId === voice.providerId ? 'active' : 'inactive'}
            className='selectGrid-btn selectGrid-btn--voice'          
          >
            <div className='selectGrid-btn-inner'>
              
              <div className='selectGrid-btn--voice-title'>
                {voice.label}
              </div>
              {voice.subtitle &&
              <div className='selectGrid-btn--music-subtitle'>
                {voice.subtitle}
              </div>
              }

              {!voice.noPlayback && 
                <button 
                  className='selectGrid-btn-playPauseBtn'
                  onClick={() => handlePlayPause(voice.id,voice.providerId)}
                >
                  {playingVoice === voice.id ? <Icon name='playbackPause' /> : <Icon name='playbackPlay' />}
                </button>
              }

              {selectedVoiceId === voice.providerId &&
                <div className='selectGrid-btn--voice-activeIconContainer'>
                  <Icon name='checkmark' />
                </div>
              }

              <div className='selectGrid-btn-activeBorder' />

              <button 
                onClick={() => setSelectedVoiceId(voice.providerId)}              
                className='selectGrid-btn--voice-bgButton' 
              />
            </div>
          </div>
        )
      })}
    </div>
  );
};

export default SelectAIVoiceGrid;