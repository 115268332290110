import React, { useState } from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import Icon from '../Icon';

const DeliveryPageProcessing = ({ videoWidth, variantData }) => {
  

  return (
    
      <div className="deliveryPage-processing">
        <div className="deliveryPage-processing-spinnerContainer">
          <div className='deliveryPage-processing-spinner' />
        </div>
      </div>
    
  );
};

export default DeliveryPageProcessing;