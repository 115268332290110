import React, { useState } from 'react';
import Icon from '../Icon';

const SelectGrid = ({ items, allowMultiple = true }) => {
  const [activeItems, setActiveItems] = useState([]);

  const toggleItem = (id) => {
    setActiveItems(prev => {
      if (allowMultiple) {
        return prev.includes(id) 
          ? prev.filter(itemId => itemId !== id) 
          : [...prev, id];
      } else {
        return prev.includes(id) ? [] : [id];
      }
    });
  };
  
  return (
    <div className="selectGrid selectGrid--music">
      {items.map((item) => {
        const isActive = activeItems.includes(item.id);
        return (
          <button
            key={item.id}
            data-active-state={isActive ? 'active' : 'inactive'}
            className='selectGrid-btn selectGrid-btn--voice'
            onClick={() => toggleItem(item.id)}          
          >
            <div className='selectGrid-btn-inner'>
              <div className='selectGrid-btn--voice-title selectGrid-btn--voice-title--useCase'>
                {item.label}
              </div>
              {item.description && (
                <div className='selectGrid-btn--music-subtitle selectGrid-btn--music-subtitle--useCase'>
                  {item.description}
                </div>
              )}
              {isActive &&
                <div className='selectGrid-btn--voice-activeIconContainer'>
                  <Icon name='checkmark' />
                </div>
              }
              <div className='selectGrid-btn-activeBorder' />
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default SelectGrid;