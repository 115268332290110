import React, { useEffect, useRef, useState } from 'react';
import Icon from '../../Icon';

const MIN_FONT_SIZE = 10;
const MAX_FONT_SIZE = 56;
const RESIZE_DEBOUNCE_MS = 150;

const CONTAINER_PADDING = {
  top: 70,    // To account for title + its spacing
  bottom: 90, // to account for play button
  left: 48,
  right: 48
};

const VideoRecordModalInstructionsPanel = ({ instructions, showExampleVideoButton, showVideoPreview  }) => {
  const containerRef = useRef(null);
  const textRef = useRef(null);
  const measureRef = useRef(null);
  const resizeTimerRef = useRef(null);
  const [fontSize, setFontSize] = useState(MAX_FONT_SIZE);
  const [isSized, setIsSized] = useState(false);
  const [isResizing, setIsResizing] = useState(false);  
  const [containerDimensions, setContainerDimensions] = useState({ width: 0, height: 0 });


  useEffect(() => {
    const calculateIdealFontSize = () => {
      if (!containerRef.current || !measureRef.current) return;

      const container = containerRef.current;
      const measureElement = measureRef.current;

      // Get container dimensions
      const containerWidth = container.clientWidth;
      const containerHeight = container.clientHeight;

      let low = MIN_FONT_SIZE;
      let high = MAX_FONT_SIZE;
      
      const checkOverflow = (size) => {
        measureElement.style.fontSize = `${size}px`;

        // Force reflow
        void measureElement.offsetHeight;

        const isHeightOverflow = measureElement.scrollHeight > containerHeight;
        const isWidthOverflow = measureElement.scrollWidth > containerWidth;

        return isHeightOverflow || isWidthOverflow;
      };

      while (low <= high) {
        const mid = Math.floor((low + high) / 2);
        
        if (checkOverflow(mid)) {
          // mid is too big, decrease high
          high = mid - 1;
        } else {
          // mid fits, try to go bigger
          low = mid + 1;
        }
      }

      // After binary search ends, 'high' is the largest font size that fits
      const finalSize = Math.max(MIN_FONT_SIZE, Math.min(high, MAX_FONT_SIZE));
      setFontSize(finalSize);
      setIsSized(true);
      setIsResizing(false);
    };
    
    setIsResizing(true);
    const timer = setTimeout(calculateIdealFontSize, 0);
    
    const handleResize = () => {
      setIsResizing(true);
      setIsSized(false);
      
      if (resizeTimerRef.current) {
        clearTimeout(resizeTimerRef.current);
      }
      
      resizeTimerRef.current = setTimeout(() => {
        calculateIdealFontSize();
      }, RESIZE_DEBOUNCE_MS);
    };
    
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timer);
      if (resizeTimerRef.current) {
        clearTimeout(resizeTimerRef.current);
      }
    };
  }, [instructions]);

  //const paragraphs = instructions.split('\n').filter(p => p.trim().length > 0);
  const paragraphs = instructions ? instructions.split('\n').filter(p => p.trim().length > 0) : []
  
  const renderParagraphs = (ref) => (
    <div ref={ref} style={{ whiteSpace: 'pre-wrap' }}>
      {paragraphs.map((paragraph, index) => (
        <div 
          key={index} 
          className='videoRecordModal--instructions-panel-paragraph'
          style={{
            marginBottom: index === paragraphs.length - 1 ? 0 : '0.7em'
          }}
        >
          {paragraph}
        </div>
      ))}
    </div>
  );

  return (
    <>
      <div 
        className='videoRecordModal--instructions-panel'        
        data-font-size-found={isSized}
        data-is-resizing={isResizing}
      >
        
        <div 
          className='videoRecordModal--instructions-panel-titleContainer'
          style={{
            position: 'absolute',
            top: '0px',
            left: CONTAINER_PADDING.left,
            height: CONTAINER_PADDING.top,
          }}
        >
          <div className='videoRecordModal--instructions-panel-title'>
            Instructions
          </div>
        </div>

        
        <div 
          data-visibility-state={showExampleVideoButton ? 'visible' : 'hidden'}
          className='videoRecordModal--instructions-panel-playPreviewButtonContainer'
          style={{
            position: 'absolute',
            bottom: '0px',
            left: CONTAINER_PADDING.left,
            height: CONTAINER_PADDING.bottom,
          }}
        >
          <button onClick={showVideoPreview} className='videoRecordModal--instructions-panel-playPreviewButton'>
            <div className='videoRecordModal--instructions-panel-playPreviewButton-iconContainer'>
              <Icon name='playRectangle' />
            </div>
            <div className='videoRecordModal--instructions-panel-playPreviewButton-label'>
              Example Video
            </div>            
          </button>
        </div>        

        <div 
          className='videoRecordModal--instructions-panel-innerContainer' 
          ref={containerRef}
          style={{
            position: 'absolute',
            top: CONTAINER_PADDING.top,
            bottom: CONTAINER_PADDING.bottom,
            left: CONTAINER_PADDING.left,
            right: CONTAINER_PADDING.right,
            overflow: 'hidden',
            whiteSpace: 'pre-wrap'
          }}
        >          
          <div 
            className='videoRecordModal--instructions-panel-innerText' 
            ref={textRef} 
            style={{ 
              fontSize: `${fontSize}px`,
              opacity: isResizing ? 0 : 1,
              transition: 'opacity 75ms ease-in-out',
              whiteSpace: 'pre-wrap'
            }}
          >
            {paragraphs.map((paragraph, index) => (
              <div 
                key={index} 
                className='videoRecordModal--instructions-panel-paragraph'
                style={{ marginBottom: index === paragraphs.length - 1 ? 0 : '0.7em' }}
              >
                {paragraph}
              </div>
            ))}
          </div>
        </div>
        
        {/* Hidden measuring element */}
        <div 
          ref={measureRef}
          style={{ 
            position: 'absolute',
            top: CONTAINER_PADDING.top,
            left: CONTAINER_PADDING.left,
            width: `calc(100% - ${CONTAINER_PADDING.left + CONTAINER_PADDING.right}px)`,
            height: `calc(100% - ${CONTAINER_PADDING.top + CONTAINER_PADDING.bottom}px)`,
            visibility: 'hidden',
            overflow: 'hidden',
            whiteSpace: 'pre-wrap'
          }}
        >
          {paragraphs.map((paragraph, index) => (
            <div 
              key={index} 
              className='videoRecordModal--instructions-panel-paragraph'
              style={{ marginBottom: index === paragraphs.length - 1 ? 0 : '0.7em' }}
            >
              {paragraph}
            </div>
          ))}
        </div>
      </div>        
      <div className='videoRecordModal--instructions-center-hSpacer' />
      
    </>
  );
};

export default VideoRecordModalInstructionsPanel;
