import React, { useCallback } from 'react';
import Icon from '../../Icon'


const VideoRecordModalInstructionsWizardPreRecordToolbar = ({ 
  goNext
}) => {
  return (
    <div className='videoRecordModal--instructions-toolbar videoRecordModal--instructions-toolbar--preRecord'>      
      <button onClick={() => goNext()} className='videoRecordModal--instructions-toolbar--preRecord-nextBtn'>
        Next
      </button>
    </div>        
  );
};

export default VideoRecordModalInstructionsWizardPreRecordToolbar;