import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/file';
import ReactSlider from 'react-slider';
import Icon from '../../Icon';

const VideoRecordModalInstructionsExampleVideo = ({ 
  hideVideo, 
  containerWidth, 
  containerHeight, 
  previewVideo,
  webcamId
}) => {
  let inputVideoUrl 
  let generatedVideoUrl
  if (previewVideo?.previewSegments?.length > 0) {
    // Find the matching preview segment based on webcamId
    const matchingSegment = previewVideo.previewSegments.find(segment => 
      segment.clipId === webcamId
    );

    if (matchingSegment) {
      const generatedDuration = matchingSegment.duration;
      const generatedStartTime = matchingSegment.startTime;
      const generatedCloudinaryId = `generation_${previewVideo.id}`;
      
      generatedVideoUrl = `https://res.cloudinary.com/yarn/video/upload/so_${generatedStartTime},eo_${generatedStartTime + generatedDuration}/collabPreviewVideo/${generatedCloudinaryId}.mp4`;

      const captureId = matchingSegment.captureId 
      //TODO handle trims
      inputVideoUrl = `https://res.cloudinary.com/yarn/video/upload/so_0,eo_${generatedDuration}/webcamRecordings/${captureId}.mp4`;
    }
  }

  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [seeking, setSeeking] = useState(false);
  const [activeVideo, setActiveVideo] = useState('recording');

  let inputPlayer = null;
  let generatedPlayer = null;

  const innerVideoTopPadding = 16;
  const innerVideoLeftPadding = 0;
  const innerVideoRightPadding = 0;
  const innerVideoBottomPadding = 10;
  const sidePadding = 80;
  const heightControls = 80;
  
  const availableWidth = containerWidth - (sidePadding * 2) - innerVideoLeftPadding - innerVideoRightPadding;
  const availableHeight = containerHeight - heightControls - innerVideoTopPadding - innerVideoBottomPadding;
  
  const videoHeight = Math.min(availableHeight, (availableWidth * 9) / 16);
  const videoWidth = (videoHeight * 16) / 9;
  const topPosition = ((containerHeight - heightControls - videoHeight) / 2) + innerVideoTopPadding - innerVideoBottomPadding;
  const availableLeftButtonSpace = (containerWidth - videoWidth) / 2;

  const handleProgress = (state) => {
    if (!seeking) {
      setProgress(state.played);
    }
  };

  const handlePlay = () => {
    setPlaying(true);
  };

  const handlePause = () => {
    setPlaying(false);
  };

  const handleSeekChange = (value) => {
    const floatValue = parseFloat(value);
    setProgress(floatValue);
    inputPlayer?.seekTo(floatValue);
    generatedPlayer?.seekTo(floatValue);
  };

  const handleSeekMouseDown = () => {
    setSeeking(true);
  };

  const handleSeekMouseUp = (value) => {
    setSeeking(false);
    const floatValue = parseFloat(value);
    setProgress(floatValue);
    inputPlayer?.seekTo(floatValue);
    generatedPlayer?.seekTo(floatValue);
  };

  const handleEnded = () => {    
    inputPlayer?.seekTo(0);
    generatedPlayer?.seekTo(0);

    setProgress(0);
    setPlaying(false);
  };

  const styles = {
    container: {
      position: 'relative',
      width: '100%',
      height: '100%'
    },
    button: {
      position: 'absolute',
      width: `${availableLeftButtonSpace}px`,
      left: 0,
      top: 0,
      bottom: 0,
    },
    video: {
      position: 'absolute',      
      width: `${videoWidth}px`,
      height: `${videoHeight}px`,
      top: `${topPosition}px`
    },
    controls: {
      position: 'absolute',
      width: `${videoWidth}px`,
      bottom: 0,
      height: `${heightControls}px`
    }
  };

  return (
    <div className='videoRecordModal--instructions-exampleVideo'>
      <button style={styles.button} className='videoRecordModal--instructions-exampleVideo-backButton' onClick={hideVideo}>        
        <Icon name='backArrow' />
      </button>
      
      <div style={{top: `${topPosition}px`}} className='videoRecordModal--instructions-exampleVideo-switchContainer'>        
        <button 
          onClick={() => setActiveVideo('recording')}
          data-active-state={activeVideo === 'recording' ? 'active' : 'inactive'} 
          className='videoRecordModal--instructions-exampleVideo-switch'
        >
          Original
        </button>        
        <button 
          onClick={() => setActiveVideo('generated')}
          data-active-state={activeVideo === 'generated' ? 'active' : 'inactive'}
          className='videoRecordModal--instructions-exampleVideo-switch'
        >
          Output
        </button>
      </div>

      <button onClick={() => setPlaying(!playing)} style={styles.video} className='videoRecordModal--instructions-exampleVideo-videoPlayPauseBtn' />

      <div data-visibility-state={activeVideo === 'recording' ? 'visible' : 'hidden'} style={styles.video} className='videoRecordModal--instructions-exampleVideo-video videoRecordModal--instructions-exampleVideo-video--recording'>
        <ReactPlayer
          ref={p => { inputPlayer = p }}
          url={inputVideoUrl}
          width="100%"
          height="100%"
          playing={playing}
          progressInterval={20}
          onPlay={handlePlay}
          onPause={handlePause}
          onProgress={handleProgress}
          onEnded={handleEnded}
          controls={false}
          muted={activeVideo === 'recording' ? false : true}
        />
      </div>

      <div data-visibility-state={activeVideo === 'generated' ? 'visible' : 'hidden'} style={styles.video} className='videoRecordModal--instructions-exampleVideo-video videoRecordModal--instructions-exampleVideo-video--generated'>
        <ReactPlayer
          ref={p => { generatedPlayer = p }}
          url={generatedVideoUrl}
          width="100%"
          height="100%"
          playing={playing}
          progressInterval={20}
          onPlay={handlePlay}
          onPause={handlePause}
          onEnded={handleEnded}
          controls={false}
          muted={activeVideo === 'recording' ? true : false}
        />
      </div>

      <div style={styles.controls} className='videoRecordModal--instructions-exampleVideo-controls'>        
        <button onClick={() => setPlaying(!playing)} className='videoRecordModal--instructions-exampleVideo-controls-playPauseBtn'>
          {playing && <Icon name='playbackPause' />}
          {!playing && <Icon name='playbackPlay' />}
        </button>        
        <div className='videoRecordModal--instructions-exampleVideo-controls-seekBar'>
          <ReactSlider
            className="videoRecordModal--instructions-exampleVideo-controls-seekBar-seekBar"
            thumbClassName="videoRecordModal--instructions-exampleVideo-controls-seekBar-seekBar-thumb"
            trackClassName="videoRecordModal--instructions-exampleVideo-controls-seekBar-seekBar-track"
            renderThumb={(props, state) => {
              const { key, ...restProps } = props;
              return <div key={key} className='videoRecordModal--instructions-exampleVideo-controls-seekBar-thumb-thumb' {...restProps}></div>;
            }}
            value={progress}
            min={0}
            max={0.999999}
            step={0.0001}
            onAfterChange={handleSeekMouseUp}
            onBeforeChange={handleSeekMouseDown}
            onChange={handleSeekChange}
          />
        </div>        
      </div>
    </div>
  );
};

export default VideoRecordModalInstructionsExampleVideo;