import React, { useState, useEffect } from 'react';
import CampaignVariantHeader from './CampaignVariantHeader';
import CampaignVariantFooter from './CampaignVariantFooter';
import CampaignVariantWebcamTab from './webcamTab/CampaignVariantWebcamTab';
import CampaignVariantGenerateTab from './generateTab/CampaignVariantGenerateTab';
import CampaignVariantProspectCard from './CampaignVariantProspectCard';
import CampaignVariantContextCards from './CampaignVariantContextCards';
import CampaignVariantSampleScript from './CampaignVariantSampleScript';
import Icon from '../Icon'

const CampaignVariantPage = (props) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const checkIfMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const mobileRegex = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;
      setIsMobile(mobileRegex.test(userAgent));
    };
    checkIfMobile();
    // Optionally, you can also check on window resize, in case the user switches between desktop and tablet mode
    window.addEventListener('resize', checkIfMobile);
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  const {campaign,campaignVariant,activeTab,showNextButton,showPreviousButton,canNavigate} = props



  let companyBulletPoints=[]
  if(campaignVariant && campaignVariant.company_news && campaignVariant.company_news.bullet_points){
    companyBulletPoints=campaignVariant.company_news.bullet_points
  }
  const context = companyBulletPoints

  const [userDisplayScript, setUserDisplayScript] = useState(false);

  

  return (
    <div className="campaignVariantPage">
      <CampaignVariantHeader 
        status={activeTab.status}
        generateProject={props.generateProject}
        campaign={campaign}
        campaignVariant={campaignVariant}        
      />
      <div className="campaignVariantPage-middle">
        <div className="campaignVariantPage-middle-top" />          
        <div className="campaignVariantPage-middle-middle">
          <div className="campaignVariantPage-middle-middle-left">
            {showPreviousButton && canNavigate &&
            <button className='campaignVariantPage-navVariantBtn' onClick={props.goToPrevious}>
              <Icon name='chevronLeft' />
            </button>
            }
          </div>
          <div className="campaignVariantPage-middle-middle-center">
            <CampaignVariantProspectCard
              campaignVariant={campaignVariant}
            />

            
            
            {campaignVariant && context && activeTab.id !='generate' && 
              <CampaignVariantContextCards 
                companyNews={companyBulletPoints}
                simpleProfile={campaignVariant.simple_profile}
              />
            }   
            {/*}*/}       
            {campaignVariant &&
              <CampaignVariantSampleScript 
                tabState={activeTab.id} 
                status={activeTab.status}
                key={`${campaignVariant.id}_${activeTab.id}`}
                campaignVariant={campaignVariant}
                userDisplayScript={userDisplayScript}
                setUserDisplayScript={setUserDisplayScript}
              />  
            }


            {activeTab.id=='outro' && activeTab.status=='pre' && 
            <button onClick={props.rerecordIntro} className='campaignVariantPage-rerecordIntroButton'>
              <div className='campaignVariantPage-rerecordIntroButton-iconContainer'>
                <Icon name='backArrow' />
              </div>
              <div className='campaignVariantPage-rerecordIntroButton-label'>
                Redo Intro
              </div>
            </button>
            }


            {(activeTab.id=='intro' || activeTab.id=='outro') &&
              <CampaignVariantWebcamTab 
                activeTab={activeTab}
                isMobile={isMobile}
                addWebcamToCampaignVariant={props.addWebcamToCampaignVariant}
                campaign={campaign}
                campaignVariant={campaignVariant}
                setTabStatus={props.setTabStatus}
              />
           }

            {activeTab.id=='generate' && 
              <CampaignVariantGenerateTab 
                isMobile={isMobile}
                campaignVariant={campaignVariant}
                generateProject={props.generateProject}
                isUploading={props.isUploading}
              />
            }
            
            <div data-visibility-state={activeTab.id == 'generate' ? 'hidden' : 'visible'} className='campaignVariantPage-topGradient' />
            <div data-visibility-state={activeTab.id == 'generate' ? 'hidden' : 'visible'} className='campaignVariantPage-bottomGradient' />

          </div>
          <div className="campaignVariantPage-middle-middle-right">
            {showNextButton && canNavigate &&
              <button className='campaignVariantPage-navVariantBtn' onClick={props.goToNext}>
                <Icon name='chevronRight' />
              </button>       
              }     
          </div>
        </div>
        <div className="campaignVariantPage-middle-bottom">
          {/* row 4  */}
        </div>    
      </div>

      
      <CampaignVariantFooter
        status={activeTab.status}
        generateProject={props.generateProject}
        campaign={campaign}
        campaignVariant={campaignVariant}
        tabs={props.tabs}
        setActiveTab={props.setActiveTab}
      />
      

    </div>
  


  );
};

export default CampaignVariantPage;
