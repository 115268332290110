import React, { useState, useEffect } from 'react';
import Icon from '../Icon';
import { useNavigate } from 'react-router-dom';
import CampaignDetailsModal from './CampaignDetailsModal';

const CampaignVariantHeader = (props) => {
  const {status,campaign} = props
  const navigate = useNavigate();

  const navigateToCampaignPage = () => {
    if(campaign){
      navigate(`/campaign/${campaign.id}`);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const hasShownModal = localStorage.getItem('hasShownCampaignModal');
    if (!hasShownModal) {
      setIsModalOpen(true);
      localStorage.setItem('hasShownCampaignModal', 'true');
    }
  }, []);

   const handleModalOpen = () => {
    console.log('open the modal')
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const fade = status === 'recording' || status === 'countdown'

  return (
    <div data-fade-state={fade ? 'fade' : 'default'} className="campaignVariantPage-top">
      <div className="campaignVariantPage-top-left">
        <div onClick={navigateToCampaignPage} className='campaignVariantPage-orgLogoContainer'>
          <div className='campaignVariantPage-orgLogo'>
            <img src={process.env.PUBLIC_URL + '/clay-logo.png'} />
          </div>
        </div>
      </div>
      <div className="campaignVariantPage-top-center">
        <button onClick={navigateToCampaignPage} className='campaignVariantPage-campaignTitleBtn'>          
          <div className='campaignVariantPage-campaignTitleBtn-iconContainer'>
            <Icon name='grid' />
          </div>
          <div className='campaignVariantPage-campaignTitleBtn-label'>
            {campaign?.name || ''}
          </div>
        </button>        
        <div className="campaignVariantPage-top-center-hSpacer" />
        {/*}
        {/* <div className='campaignVariantPage-campaignProgress' /> 
        <CampaignDetailsModal isOpen={isModalOpen} onClose={handleModalClose} campaign={campaign}>
          <button  onClick={handleModalOpen} className='campaignVariantPage-campaignDetailsBtn'>                      
            <div className='campaignVariantPage-campaignDetailsBtn-iconContainer'>
              <Icon name='info' />
            </div>
            <div className='campaignVariantPage-campaignDetailsBtn-label'>
              Campaign Info
            </div>
          </button>  
        </CampaignDetailsModal>
        */}


      </div>
      <div className="campaignVariantPage-top-right" />
      
    </div>
  );
};

export default CampaignVariantHeader;