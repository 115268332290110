import React, { useState, useRef, useEffect } from 'react';
import Icon from '../Icon';

const AIVoicePreviewButton = ({ voiceMatchSampleUrl }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    const audio = new Audio(voiceMatchSampleUrl);
    audio.preload = 'auto';
    audioRef.current = audio;

    const handleVisibilityChange = () => {
      if (document.hidden) {
        stopAudio();
      }
    };

    const handleAudioEnded = () => { 
      setIsPlaying(false);
    };

    audio.addEventListener('ended', handleAudioEnded);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      stopAudio();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      audio.removeEventListener('ended', handleAudioEnded);
      audio.src = '';
    };
  }, [voiceMatchSampleUrl]);

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setIsPlaying(false);
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      stopAudio();
    } else {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  return (
    <div onClick={handlePlayPause} className="selectedVoice">
      <div className="selectedVoice-inner">                
        <div className="selectedVoice-title">
          AI Voiceover Sample
        </div>
        <button className="selectedVoice-playPauseBtn">
          {isPlaying ? <Icon name="playbackPause" /> : <Icon name="playbackPlay" />}
        </button>       
        <div className='selectedVoice-activeBorder' />   
        <div className='selectedVoice-activeBorder-activeIconContainer'>
          <Icon name='checkmark' />
        </div>
      </div>
    </div>
  );
};

export default AIVoicePreviewButton;