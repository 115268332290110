import React, { useState, useEffect } from 'react';
import Icon from '../Icon';
import { formatTimestamp } from '../../utils/formatTimestamp';

const DeliveryPageTitleOmni = ({ videoWidth, variantData, url, mp4DownloadUrl, deliveryPageUrl, showCalendar, setShowCalendar }) => {
   
  const segment = variantData.Intro
  const companyName = variantData.companyName

  let title
  if(segment === 'Startup Founders'){
    title = `Simplifying startup analytics at ${companyName}`
  }
  if(segment === 'Midmarket CTOs'){
    title = `Balancing speed and trust in BI at ${companyName}`
  }
  if(segment === 'Midmarket Head of Data'){
    title = `Keeping data teams aligned at ${companyName}`
  }
  if(segment === 'Midmarket Business Analysts'){
    title = `Easier analysis with fewer workarounds at ${companyName}`
  }
  if(segment === 'Enterprise CIO'){
    title = `Centralizing analytics at scale at ${companyName}`
  }
  if(segment === 'General'){
    title = `Data insights without complexity at ${companyName}`
  }  
  
  const avatarUrl = "https://res.cloudinary.com/yarn/image/upload/w_96,c_scale/JasperHeadshot_wz5kfy";  
  const author = "Jasper Story"
  const org = "Omni"

  const documentTitle = title

  useEffect(() => {
    document.title = documentTitle;
  }, [documentTitle]);


  return (
    
    <>
      <div className="deliveryPage-title">
        <div className="deliveryPage-title-left">
          <div className="deliveryPage-title-avatarContainer">
            <img src={avatarUrl} className="deliveryPage-title-avatar"  />
          </div>
          
          <div className="deliveryPage-title-details">            
              <div className="deliveryPage-title-details-title">
                {title}
              </div>            
            <div className="deliveryPage-title-details-author">
              {author}
              <span className="deliveryPage-title-name-author-company">
                {org}
              </span>
            </div>
          </div>          
        </div>

        <div className="deliveryPage-title-right">
          <div className="deliveryPage-title-timestamp">
            4 hours ago
          </div>       
          {/*}
          <button data-state={showCalendar ? 'active' : 'inactive'} onClick={() => setShowCalendar(!showCalendar)} className='deliveryPage-showCalendarBtn deliveryPage-showCalendarBtn--shopify'>
            <div className='deliveryPage-showCalendarBtn-iconContainer'>
              <Icon name='calendar' />
            </div>
            <div className='deliveryPage-showCalendarBtn-label'>
              {showCalendar ? 'Hide' : 'Book Call'}
            </div>
          </button>
          */}
        </div>
      </div>

      <div className="deliveryPage-titleMobile forceDarkTheme">
        <div className="deliveryPage-titleMobile-title">
          {title}
        </div>
        <div className="deliveryPage-titleMobile-subtitle">
          <div className="deliveryPage-titleMobile-subtitle-avatarContainer">
            <img src={avatarUrl} className="deliveryPage-titleMobile-subtitle-avatar"  />
          </div>
          <div className="deliveryPage-titleMobile-subtitle-author">
            {author}
            <div className="deliveryPage-titleMobile-subtitle-author-company">
              {org}
            </div>
          </div>
          <div className="deliveryPage-titleMobile-subtitle-timestamp">
            · 4 hours ago
          </div>          
        </div>
      </div>

      </>



    
  );
};

export default DeliveryPageTitleOmni;