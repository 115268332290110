import React, { useState } from 'react';
import CampaignVariantProspectCardCompanyLogo from './CampaignVariantProspectCardCompanyLogo'
const CampaignVariantProspectCard = ({campaignVariant}) => {
  let prospectName
  let prospectRole
  let prospectCompany
  let prospectProfilePhotoSrc
  if(campaignVariant && campaignVariant.personalization_data){
    prospectName = campaignVariant.personalization_data.prospectName
    prospectRole =campaignVariant.personalization_data.prospectTitle
    prospectCompany = campaignVariant.personalization_data.prospectOrg
    prospectProfilePhotoSrc = campaignVariant.personalization_data.prospectProfilePhoto
  }

  if(campaignVariant){
  return (
    <div className="campaignVariantPage-prospectCard">
      <div className="campaignVariantPage-prospectCard-left">
        <div className="campaignVariantPage-prospectCard-card">
          {prospectProfilePhotoSrc && 
            <div className="campaignVariantPage-prospectCard-avatarContainer">
              <img src={prospectProfilePhotoSrc} className='campaignVariantPage-prospectCard-avatar' />
            </div>
          }
          {!prospectProfilePhotoSrc && 
            <div className='campaignVariantPage-prospectCard-noAvatarSpacer' />
          } 
          <div className="campaignVariantPage-prospectCard-info">
            <div className="campaignVariantPage-prospectCard-info-name">
              {prospectName}
            </div>
            <div className="campaignVariantPage-prospectCard-info-role">
              {prospectRole}<span>{prospectCompany}</span>
            </div>
            {/*}
            <div className="campaignVariantPage-prospectCard-info-company">
              {prospectCompany}
            </div>
            */}
          </div>    
        </div>
      </div>
      {campaignVariant.prospect_logo_light &&
      <CampaignVariantProspectCardCompanyLogo 
        logoSrc={campaignVariant.prospect_logo_light}
      />
      }      
    </div>
  );
}else return null
};

export default CampaignVariantProspectCard;
