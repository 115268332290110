import React, { useState, useEffect, useRef } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import RecordRTC from 'recordrtc';
import VideoRecordModalInstructions from './VideoRecordModalInstructions';
import VideoRecordModalInstructionsWizard from './VideoRecordModalInstructionsWizard';
import VideoRecordModalScript from './VideoRecordModalScript';
import { convertMedia } from '@remotion/webcodecs';
import { webFileReader } from '@remotion/media-parser/web-file';

const VideoRecordModal = (props) => {
  const {
    isOpen, 
    onOpenChange, 
    onVideoRecorded,
    instructions, 
    script,
    type,
    selectHideVideo,
    setSelectHideVideo,
    previewVideo,
    webcamId
  } = props;

  const [isRecording, setIsRecording] = useState(false);
  const [isDisplayRecording, setIsDisplayRecording] = useState(false);
  const [videoStreamReady, setVideoStreamReady] = useState(false);
  const [mediaStream, setMediaStream] = useState(null);
  const [recorder, setRecorder] = useState(null);
  const [cameras, setCameras] = useState([]);
  const [microphones, setMicrophones] = useState([]);
  const [activeCamera, setActiveCamera] = useState('');
  const [activeMicrophone, setActiveMicrophone] = useState('');


  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const mountedRef = useRef(false);
  const canvasStreamRef = useRef(null);
  const animationFrameRef = useRef(null);
  const blackCanvasRef = useRef(null);

  //
  // Timer
  const [fadeOutActive, setFadeOutActive] = useState(false);
  const [recordingTime, setRecordingTime] = useState({ minutes: 0, seconds: 0 });
  const timerIntervalRef = useRef(null);
  const totalSecondsRef = useRef(0);

  useEffect(() => {
    const shouldRunTimer = isRecording || fadeOutActive;
    
    if (shouldRunTimer) {
      timerIntervalRef.current = setInterval(() => {
        totalSecondsRef.current++;
        setRecordingTime({
          minutes: Math.floor(totalSecondsRef.current / 60),
          seconds: totalSecondsRef.current % 60
        });
      }, 1000);
    } else {
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
        timerIntervalRef.current = null;
      }
      // Only reset time when both recording and fade-out are done
      totalSecondsRef.current = 0;
      setRecordingTime({ minutes: 0, seconds: 0 });
    }

    return () => {
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
      }
    };
  }, [isRecording, fadeOutActive]);



  const [audioAssets, setAudioAssets] = useState({
    start: null,
    stop: null
  });

  useEffect(() => {
    const startAudio = new Audio('/recordStart.mp3');
    const stopAudio = new Audio('/recordEnd.mp3');
    
    startAudio.volume = 0.05;
    stopAudio.volume = 0.05;

    Promise.all([
      startAudio.load(),
      stopAudio.load()
    ]).then(() => {
      setAudioAssets({
        start: startAudio,
        stop: stopAudio
      });
    });
  }, []);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
      if (recorder) {
        recorder.destroy();
      }
      cleanupMediaStream();
    };
  }, []);

  const cleanupMediaStream = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach(track => track.stop());
      setMediaStream(null);
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
    }
    if (canvasStreamRef.current) {
      canvasStreamRef.current.getTracks().forEach(track => track.stop());
      canvasStreamRef.current = null;
    }
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
      animationFrameRef.current = null;
    }
    setVideoStreamReady(false);
  };

  useEffect(() => {
    const videoEl = videoRef.current;
    if (!videoEl) return;

    const handleCanPlay = () => {
      if (mountedRef.current) {
        setVideoStreamReady(true);
      }
    };

    videoEl.addEventListener('loadedmetadata', handleCanPlay);
    videoEl.addEventListener('loadeddata', handleCanPlay);

    return () => {
      videoEl.removeEventListener('loadedmetadata', handleCanPlay);
      videoEl.removeEventListener('loadeddata', handleCanPlay);
    };
  }, [videoRef.current]);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const videoDevices = devices
        .filter(device => device.kind === 'videoinput')
        .map(device => ({
          value: device.deviceId,
          label: device.label || 'Camera ' + (cameras.length + 1)
        }));
      
      const audioDevices = devices
        .filter(device => device.kind === 'audioinput')
        .map(device => ({
          value: device.deviceId,
          label: device.label || 'Microphone ' + (microphones.length + 1)
        }));

      setCameras(videoDevices);
      setMicrophones(audioDevices);
      
      if (videoDevices.length > 0 && !activeCamera) {
        setActiveCamera(videoDevices[0].value);
      }
      if (audioDevices.length > 0 && !activeMicrophone) {
        setActiveMicrophone(audioDevices[0].value);
      }
    });
  }, []);

  useEffect(() => {
    if (!isOpen) {
      cleanupMediaStream();
      return;
    }

    let isMounted = true;

    const setupStream = async () => {
      try {
        if (selectHideVideo) {
          // Create a persistent canvas
          if (!blackCanvasRef.current) {
            blackCanvasRef.current = document.createElement('canvas');
            blackCanvasRef.current.width = 1280;
            blackCanvasRef.current.height = 720;
            const ctx = blackCanvasRef.current.getContext('2d');
            ctx.fillStyle = 'black';
            ctx.fillRect(0, 0, blackCanvasRef.current.width, blackCanvasRef.current.height);
          }

          // Function to draw black frame
          const drawBlackFrame = () => {
            if (!blackCanvasRef.current || !isMounted) return;
            const ctx = blackCanvasRef.current.getContext('2d');
            ctx.fillStyle = 'black';
            ctx.fillRect(0, 0, blackCanvasRef.current.width, blackCanvasRef.current.height);
            animationFrameRef.current = requestAnimationFrame(drawBlackFrame);
          };

          // Start animation loop
          drawBlackFrame();

          // Get audio stream first
          const audioStream = await navigator.mediaDevices.getUserMedia({
            audio: {
              deviceId: activeMicrophone ? { exact: activeMicrophone } : undefined,
              echoCancellation: true,
              noiseSuppression: true,
              sampleRate: 44100,
            }
          });

          if (!isMounted) {
            audioStream.getTracks().forEach(track => track.stop());
            return;
          }

          // Create canvas stream
          const canvasStream = blackCanvasRef.current.captureStream(30);
          canvasStreamRef.current = canvasStream;

          // Combine streams
          const combinedStream = new MediaStream([
            ...canvasStream.getVideoTracks(),
            ...audioStream.getAudioTracks()
          ]);

          setMediaStream(combinedStream);
          if (videoRef.current) {
            videoRef.current.srcObject = combinedStream;
            await videoRef.current.play();
          }
          setVideoStreamReady(true);

        } else {
          // Original webcam logic
          const stream = await navigator.mediaDevices.getUserMedia({
            video: {
              deviceId: activeCamera ? { exact: activeCamera } : undefined,
              width: { 
                ideal: 1920,
                min: 1280,
                max: 1920
              },
              height: {
                ideal: 1080,
                min: 720,
                max: 1080
              },             
            },
            audio: {
              deviceId: activeMicrophone ? { exact: activeMicrophone } : undefined,
              echoCancellation: true,
              noiseSuppression: true,
              sampleRate: {
                ideal: 48000,
                min: 44100
              },
            }
          });

          if (!isMounted) {
            stream.getTracks().forEach(track => track.stop());
            return;
          }

          setMediaStream(stream);
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            await videoRef.current.play();
          }
        }
      } catch (error) {
        console.error('Error accessing media devices:', error);
        if (isMounted) {
          setVideoStreamReady(false);
        }
      }
    };

    setupStream();

    return () => {
      isMounted = false;
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      cleanupMediaStream();
    };
  }, [isOpen, activeCamera, activeMicrophone, selectHideVideo]);

  const handleStartRecordingClick = async () => {
    if (!mediaStream || !audioAssets.start) return;

    totalSecondsRef.current = 0;
    setRecordingTime({ minutes: 0, seconds: 0 });
    setFadeOutActive(false);

    setIsDisplayRecording(true);
    await audioAssets.start.play();
    await new Promise(resolve => setTimeout(resolve, 550));

    const recordRTC = new RecordRTC(mediaStream, {
      type: 'video',
      mimeType: 'video/webm;codecs=vp8,opus',
      videoBitsPerSecond: 12000000,
      audioBitsPerSecond: 128000
    });

    recordRTC.startRecording();
    setRecorder(recordRTC);
    setIsRecording(true);    
  };

  const handleStopRecordingClick = () => {
    if (!recorder || !audioAssets.stop) return;

    setIsDisplayRecording(false);
    setIsRecording(false);
    setFadeOutActive(false);

    recorder.stopRecording(async () => {
      // Play the stop sound
      audioAssets.stop.play();
      const rawBlob = recorder.getBlob();
      onVideoRecorded(rawBlob)

      //Old stuff- conversion is now done in useWebcams.js
      // try {
      //   // Try to convert the blob using Remotion's WebCodecs conversion
      //   const stableBlob = await convertMedia({
      //     src: rawBlob,
      //     container: 'webm',
      //     reader: webFileReader,
      //   });
      //   onVideoRecorded(stableBlob);
      // } catch (error) {
      //   console.error(
      //     'Error converting blob using WebCodecs, falling back to original blob:',
      //     error
      //   );
      //   // Fall back to the original blob if conversion fails
      //   onVideoRecorded(rawBlob);
      // }

      // Cleanup recorder resources
      setIsRecording(false);
      if (recorder.stream) {
        recorder.stream.getTracks().forEach(track => track.stop());
      }
      recorder.destroy();
      setRecorder(null);
    });
  };


  const handleRestartClick = () => {
    if (recorder) {
      recorder.destroy();
      setRecorder(null);
    }
    setIsRecording(false);
    setIsDisplayRecording(false);
    audioAssets.stop.play();
    setFadeOutActive(true);
  };

  useEffect(() => {
    return () => {
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
      }
    };
  }, []);


  const isReadyToRecord = (videoStreamReady || isRecording) && mediaStream !== null;


  const inputStartTime = 0;
  const inputDuration = 9;
  const inputCloudinaryId = 'owen-capture_nizmm7';
  const generatedCloudinaryId = 'owen-generated_eeawzo';
  const inputVideoUrl = `https://res.cloudinary.com/yarn/video/upload/so_0,eo_${inputDuration}/${inputCloudinaryId}.mp4`;
  const generatedVideoUrl = `https://res.cloudinary.com/yarn/video/upload/so_${inputStartTime},eo_${inputStartTime + inputDuration}/${generatedCloudinaryId}.mp4`;

  return (
    <Dialog.Root 
      open={isOpen} 
      onOpenChange={(open) => {
        if (!open && isRecording) {
          handleStopRecordingClick();
        }
        onOpenChange(open);
      }}
    >
      <Dialog.Portal>
        <Dialog.Overlay className='videoRecordModalOverlay forceDarkTheme' />
        <Dialog.Content className={'videoRecordModal forceDarkTheme ' + (type === 'instructions' ? 'videoRecordModal--instructions' : 'videoRecordModal--script')}>
          
          {type === "script" && (
            <VideoRecordModalScript
              videoRef={videoRef}
              isRecording={isRecording}
              isDisplayRecording={isDisplayRecording}
              onStartRecording={handleStartRecordingClick}
              onStopRecording={handleStopRecordingClick}
              onRestart={handleRestartClick}
              onOpenChange={onOpenChange}
              activeCamera={activeCamera}
              setActiveCamera={setActiveCamera}
              activeMicrophone={activeMicrophone}
              setActiveMicrophone={setActiveMicrophone}
              cameras={cameras}
              microphones={microphones}
              script={script}              
              isReadyToRecord={isReadyToRecord}
              selectHideVideo={selectHideVideo}
              setSelectHideVideo={setSelectHideVideo}
              mediaStream={mediaStream}
            />
          )}

          {type === "instructions" && (
            <VideoRecordModalInstructions
              videoRef={videoRef}
              isRecording={isRecording}
              isDisplayRecording={isDisplayRecording}
              onStartRecording={handleStartRecordingClick}
              onStopRecording={handleStopRecordingClick}
              onRestart={handleRestartClick}
              onOpenChange={onOpenChange}
              activeCamera={activeCamera}
              setActiveCamera={setActiveCamera}
              activeMicrophone={activeMicrophone}
              setActiveMicrophone={setActiveMicrophone}
              cameras={cameras}
              microphones={microphones}
              instructions={instructions}              
              isReadyToRecord={isReadyToRecord}
              selectHideVideo={selectHideVideo}
              setSelectHideVideo={setSelectHideVideo}
              inputVideoUrl={inputVideoUrl}   
              generatedVideoUrl={generatedVideoUrl}
              recordingMinutes={recordingTime.minutes.toString().padStart(1, '0')}
              recordingSeconds={recordingTime.seconds.toString().padStart(2, '0')}
              showRecordingTimer={isDisplayRecording}
              previewVideo={previewVideo}
              webcamId={webcamId}
              mediaStream={mediaStream}
            />
          )}

          


        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default VideoRecordModal;