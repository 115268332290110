import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';

import ClayCreatorPageContainer from './legacyCreatorPages/ClayCreatorPageContainer'
import ClayDevCreatorPageContainer from './legacyCreatorPages/ClayDevCreatorPageContainer'
import GifPageContainer from './GifPageContainer'


import ClayOnboardingVideoContainer from './ClayOnboardingVideoContainer' //Delivery page for viewing the clay onboarding videos
import CreatorPageContainer from './CreatorPageContainer'
import RemoteLinkRecordPage from '../components/remoteLinkRecordPage/RemoteLinkRecordPage'; //recording dev page with telepromopter

//////Demos
//juno
import JunoDeliveryPageContainer from './JunoDeliveryPageContainer' //Juno Sales Demo Video Delivery Page
import CampaignPageContainer from './campaigns/CampaignPageContainer'
import CampaignVariantPageContainer from './campaigns/CampaignVariantPageContainer'

import OmniVideoPageContainer from './OmniVideoPageContainer'
//static pages
import ElevenLabsDemoPageContainer from './ElevenLabsDemoPageContainer'
import ShopifyDemoPageContainer from './ShopifyDemoPageContainer'


const gifProjectIds=['709652615','452897519']
const ELEVEN_LABS_DEMO_PROJECT_ID="2144900467"

//some of the clay projects are in dev environment
//hubspot 350777700
//metapropter 293741495
//GMaps 321762070
//hubspot yarn edit 1783198200
//gmaps yarn edit 994585621
//claygent neon 1353136186


//Clay stuff is a  bit of a mess - ClayDevCreatorPageContainer is the old one that points at the dev api
//ClayCreatorPageContainer is the old gen page (not generated from link in the app wihout page setting options etc)
//All new creator pages that you generate from the app are at CreatorPageContainer
//Voice matches are still on dev db need to migrate this at some point


const DEV_PROJECT_IDS=['350777700','293741495','321762070','1783198200','994585621','1353136186']


const HomePageWrapperContainer = () => {
  
  const { customerName, projectNameAndId } = useParams();
  
  const projectId = projectNameAndId.split('-').pop(); // Assuming projectNameAndId format is "name-id"
  
  if (projectId === ELEVEN_LABS_DEMO_PROJECT_ID) {
    return <ElevenLabsDemoPageContainer />;
  } else if (gifProjectIds.includes(projectId)) {
    return <GifPageContainer />;
  } else {
    const isDev = DEV_PROJECT_IDS.includes(projectId)
    if(isDev){
      return <ClayDevCreatorPageContainer />;
    }else{
      return <ClayCreatorPageContainer />;
    }  
  }
};
const getSubdomain = (hostname) => {
  // Special handling for localhost
  if (hostname.includes('localhost')) {
    const parts = hostname.split('.');
    if (parts.length > 1) {
      return parts[0];
    }
  }
  // Regular domain handling
  return hostname.split('.').slice(0, -2);
}

const AppContainer = () => {
  const subdomain = getSubdomain(window.location.hostname);

  let isClay = true 
  if(subdomain && subdomain=='omni'){
    isClay = false
  }
  return (
    <div id="app">
      <Router>
        <Routes>
          <Route path="/record" element={<RemoteLinkRecordPage />} /> 
          <Route path="/watch/:shareId" element={isClay ? <ClayOnboardingVideoContainer /> : <OmniVideoPageContainer />} />
          <Route path="/shopify/Future-Proofing-Retail-xj123jalsll2" element={<ShopifyDemoPageContainer />} />
          <Route path="/share/:shareId" element={<JunoDeliveryPageContainer />} />          
          <Route path="/campaign/:campaignId/:variantId" element={<CampaignVariantPageContainer />} />
          <Route path="/campaign/:campaignId" element={<CampaignPageContainer />} />
          <Route path="/collab/:projectNameAndId" element={<CreatorPageContainer />} />
          <Route path="/:customerName/:projectNameAndId" element={<HomePageWrapperContainer />} />
        </Routes>
      </Router>
    </div>
  );
};

export default AppContainer;