import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AppContainer from './containers/AppContainer'; // Your main App component
import store from './store'
import './styles/App.scss';

import LogRocket from 'logrocket';


//const STAGING_LOGROCKET='wxsdav/y-dynamic-staging'
// const PROD_LOGROCKET= 

LogRocket.init(process.env.REACT_APP_LOGROCKET_PROJECT);


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppContainer />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);