const saveStateToLocalStorage = (projectId, state) => {
  localStorage.setItem(`clay_${projectId}`, JSON.stringify(state));
};



const loadStateFromLocalStorage = (projectId) => {
  const savedState = localStorage.getItem(`clay_${projectId}`);
  return savedState ? JSON.parse(savedState) : null;
};


export { saveStateToLocalStorage, loadStateFromLocalStorage };