import {fetch} from '../apiProd'


// export const fetchCloudflareForShareId = (shareId) => {
//   return fetch(`/generation-request/${shareId}`)
//     .then((response) => {
//       return response
//     })
//     .catch((error) => {
//       return error
//     })
// }





export const fetchCloudflareForShareId = (shareId) => {
  const startTime = performance.now();
  
  return fetch(`/generation-request/${shareId}`)
    .then((response) => {
      const endTime = performance.now();
      const duration = endTime - startTime;
      console.log(`${duration.toFixed(2)}ms`);
      return response;
    })
    .catch((error) => {
      const endTime = performance.now();
      const duration = endTime - startTime;
      console.log(`Request for shareId ${shareId} failed after ${duration.toFixed(2)}ms`);
      return error;
    });
};