import React, { useEffect, useState } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { fetchCampaign,fetchCampaignVariants,generateCampaignVariant } from '../../actions/campaign'; 
import CampaignHomePage from '../../components/campaigns/homePage/CampaignHomePage'
import pusherInstance from '../../pusherInstance'


const CampaignPageContainer = () => {
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [connectedToPusher, setConnectedToPusher] = useState(false);


  useEffect(() => {
    if (!connectedToPusher) {
      subscribeToCampaignPusherChannel();
    }
    return () => {
      if (connectedToPusher) {
        pusherInstance.unsubscribe(`campaign-${campaignId}`);
      }
    };
  }, [connectedToPusher, campaignId]);

  const subscribeToCampaignPusherChannel = () => {
    const channel = pusherInstance.subscribe(`campaign-${campaignId}`);
    channel.bind('update', (data) => {
      dispatch(fetchCampaign(campaignId))
      dispatch(fetchCampaignVariants(campaignId))
    });
    setConnectedToPusher(true);
  };

  const campaign = useSelector(state => state.campaign);
  const campaignVariants = useSelector(state => state.campaignVariants);

  useEffect(() => {
    dispatch(fetchCampaign(campaignId))
    dispatch(fetchCampaignVariants(campaignId))
  }, [campaignId, dispatch]);

  const handleVariantClick = (variantId) => {
    navigate(`/campaign/${campaignId}/${variantId}`);
  };


  if(campaign && campaignVariants){

    const sortedVariants = [...campaignVariants].sort((a, b) => 
      new Date(b.updated_at) - new Date(a.updated_at)
    );

    const upNext = sortedVariants.filter(variant => variant.status === 'pending');
    const doneVariants = sortedVariants.filter(variant => variant.status !== 'pending');

    // const processingVariants = sortedVariants.filter(variant => variant.status !== 'pending' && variant.status !== 'complete');
    // const readyVariants = sortedVariants.filter(variant => variant.status === 'complete');
  
    return (
      <>
      <CampaignHomePage 
        campaign={campaign}
        upNextVariants={upNext}
        handleVariantClick={handleVariantClick}
        doneVariants={doneVariants}
        // processingVariants={processingVariants}
        // readyVariants={readyVariants}
      />
    </>
  );
  }else return <div>loading</div>
};

export default CampaignPageContainer;