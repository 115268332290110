import { post, put } from '../api';
import { randomID } from '../utils/randomID';

import {uploadToS3,uploadLargeFileToS3} from './utils/uploadWebcamToS3'


const SIZE_THRESHOLD = 100 * 1024 * 1024; 


//lets manually set the updated at to the current time when we make the request for the sort order
export const generateCampaignVariantProject = (campaignId,variantId) => {
  return (dispatch) => {
  dispatch({ type: 'GENERATE_VARIANT_REQUEST' ,variantId:variantId});
  return post(`/juno/generate-campaign-variant-project/${campaignId}/${variantId}`)
    .then((response) => {
      console.log('generate project response', response);
      return response;
    })
    .catch((error) => {
      console.error('Error generating campaign variant project:', error);
      throw error;
    });
  }
};




export const addWebcamToCampaignVariantRequest = async (blobUrl,variantId,videoType) => {
  const capture_id = randomID();  
  console.log(`capture ID is ------- ${capture_id}`)
  try {
    const res = await fetch(blobUrl);
    const blob = await res.blob();
    const { uploadURL, key } = await post(`/juno-webcam/${variantId}`, { capture_id });
    // Determine upload method based on file size
    if (blob.size > SIZE_THRESHOLD) {
      await uploadLargeFileToS3(blob, uploadURL);
    } else {
      await uploadToS3(blob, uploadURL);
    }
    // Notify the server that the upload is complete
    const updateResponse = await post(`/juno-webcam/${variantId}/${capture_id}/upload-complete`, { video_type:videoType });
    console.log('Upload complete response:', updateResponse);
    return updateResponse;
  } catch (error) {
    console.error('Error during webcam upload process:', error);
    throw error;
  }
};
