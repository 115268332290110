import React, {useState} from 'react'
import Icon from '../../Icon';
import ReactSlider from 'react-slider';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

function VideoPreviewPlayerControls({    
    playing,
    onPlayPauseClick,
    progress,
    onSeek,
    onSeekMouseDown,
    onSeekMouseUp,
    duration,
    showControls,
    editable,
    playbackRate,
    onPlaybackRateChange
  }) {

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const playbackRates = [1.0, 1.2, 1.5, 2.0];

  return (
    <>

    <div data-visible-state={showControls ? true : false} className='page-videoPreviewPlayer-controls'>
                  
      <button onClick={onPlayPauseClick} className='page-videoPreviewPlayer-controls-playPauseBtn'>
        {playing &&
          <Icon name='playbackPause' />
        }
        {!playing && 
          <Icon name='playbackPlay' />
        }
      </button>

      <div className='page-videoPreviewPlayer-controls-timestamp'>
        <div className='page-videoPreviewPlayer-controls-timestamp-timestamp page-videoPreviewPlayer-controls-timestamp-timestamp--current'>
          {formatTime(progress * duration)}
        </div>
        <div className='page-videoPreviewPlayer-controls-timestamp-timestamp page-videoPreviewPlayer-controls-timestamp-timestamp--total'>
          {formatTime(duration)}
        </div>

      </div>

      <div className='page-videoPreviewPlayer-controls-seekBar'>
        <ReactSlider
          className="page-videoPreviewPlayer-controls-seekBar-seekBar"
          thumbClassName="page-videoPreviewPlayer-controls-seekBar-seekBar-thumb"
          trackClassName="page-videoPreviewPlayer-controls-seekBar-seekBar-track"                  
          renderThumb={(props, state) => {
              const { key, ...restProps } = props;
              return <div key={key} className='page-videoPreviewPlayer-controls-seekBar-thumb-thumb' {...restProps}></div>;
            }}        
          value={progress}
          min={0}
          max={0.999999}
          step={0.0001}
          onAfterChange={onSeekMouseUp}
          onBeforeChange={onSeekMouseDown}
          onChange={onSeek}
        />

      </div>

      
      <DropdownMenu.Root modal={false}>
        <DropdownMenu.Trigger asChild>
          <button className='page-videoPreviewPlayer-controls-playbackRateBtn'>
            <div className='page-videoPreviewPlayer-controls-playbackRateBtn-label'>
              {playbackRate !== undefined && playbackRate !== null ? playbackRate.toFixed(1) : '1.0'}
            </div>
            <div className='page-videoPreviewPlayer-controls-playbackRateBtn-iconContainer'>
              <Icon name='multiplySemibold' />
            </div>            
          </button>
        </DropdownMenu.Trigger>
        
        <DropdownMenu.Content 
          align="end"
          alignOffset={-4} 
          sideOffset={5} 
          side='top' 
          className='dropdownMenu dropdownMenu--playbackRate forceDarkTheme'
        >
          {playbackRates.map((rate) => (
            <DropdownMenu.Item
              key={rate}
              className={rate === playbackRate ? ' dropdownMenu-item--active ' : ''}              
              onSelect={() => onPlaybackRateChange(rate)}
            >
              <div className='dropdownMenu-item--playbackRate-label'>
                {rate.toFixed(1)}
              </div>
              <div className='dropdownMenu-item--playbackRate-iconContainer'>
                <Icon name='multiplySemibold' />
              </div>
              
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Root>

      {editable && 
        <div className='page-videoPreviewPlayer-controls-editableSpacer' />      
      }

    </div>
          
    

    </>
  );
}

export default VideoPreviewPlayerControls;