import React, { useState } from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import Icon from '../Icon';
import { formatTimestamp } from '../../utils/formatTimestamp';

const DeliveryPageTitle = ({ videoWidth, variantData, url, mp4DownloadUrl, deliveryPageUrl, showCalendar, setShowCalendar }) => {
  let videoTitle;
  let timestampLabel;
  
  if (variantData) {
    videoTitle = variantData.campaignName;
    timestampLabel = formatTimestamp(variantData.updatedAt);
  }
  
  let prospectOrg = ''
 
  if(variantData && variantData.personalizationData && variantData.personalizationData.prospectOrg){
    prospectOrg=variantData.personalizationData.prospectOrg
  }
 
  videoTitle = `Scaling ${prospectOrg}'s most creative ideas`
 
  const userName = "Kareem Amin";
  const org = "Clay";
  const avatarUrl = "https://res.cloudinary.com/yarn/image/upload/w_96,c_scale/v1723642295/clay/Kareem-sq-1_ymj5zw.png";

  const [copied, setCopied] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(deliveryPageUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };


  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = mp4DownloadUrl;
    link.setAttribute('download', `${videoTitle}.mp4`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  

  // const handleDownload = async () => {
  //   try {
  //     setIsDownloading(true);
  //     const response = await fetch(mp4DownloadUrl, { mode: 'cors' });
  //     const blob = await response.blob();
  //     const link = document.createElement('a');
  //     link.href = URL.createObjectURL(blob);
  //     link.setAttribute('download', `${videoTitle}.mp4`);

  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);

  //     URL.revokeObjectURL(link.href);
  //   } catch (error) {
  //     console.error('Error downloading the file:', error);
  //   } finally {
  //     setIsDownloading(false);
  //   }
  // };


  return (
    <Tooltip.Provider delayDuration={0}>
      <div className="deliveryPage-title">
        <div className="deliveryPage-title-left">
          <div className="deliveryPage-title-avatarContainer">
            <img src={avatarUrl} className="deliveryPage-title-avatar" alt={userName} />
          </div>
          {prospectOrg && prospectOrg.length > 1 && 
            <div className="deliveryPage-title-details">            
                <div className="deliveryPage-title-details-title">
                  {videoTitle}
                </div>            
              <div className="deliveryPage-title-details-author">
                {userName}
                <span className="deliveryPage-title-name-author-company">
                  {org}
                </span>
              </div>
            </div>
          }
        </div>

        <div className="deliveryPage-title-right">
          <div className="deliveryPage-title-timestamp">
            {timestampLabel}
          </div>       

          <button data-state={showCalendar ? 'active' : 'inactive'} onClick={() => setShowCalendar(!showCalendar)} className='deliveryPage-showCalendarBtn'>
            <div className='deliveryPage-showCalendarBtn-iconContainer'>
              <Icon name='calendar' />
            </div>
            <div className='deliveryPage-showCalendarBtn-label'>
              {showCalendar ? 'Hide' : 'Calendar'}
            </div>
          </button>
          {/*}   need to get copied right which is weird
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <button onClick={handleCopyLink} className="deliveryPage-title-btn deliveryPage-title-btn--copyLink">
                <div className="deliveryPage-title-btn-iconContainer">
                  <Icon name="link" />
                </div>
              </button>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content collisionPadding={10} sideOffset={2} className="tooltip forceDarkTheme">
                {copied ? 'Copied!' : 'Copy Link'}
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
          */}

       
          
          {/*<Tooltip.Root>
            <Tooltip.Trigger asChild>
              <button onClick={handleDownload} className="deliveryPage-title-btn deliveryPage-title-btn--download">
                <div className="deliveryPage-title-btn-iconContainer">
                  <Icon name="download" />
                </div>
              </button>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content collisionPadding={10} sideOffset={2} className="tooltip forceDarkTheme">
                Download
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>*/}
        
        </div>
      </div>
    </Tooltip.Provider>
  );
};

export default DeliveryPageTitle;