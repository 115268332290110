import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Icon from '../Icon'
import SelectLibraryVoiceGrid from './SelectLibraryVoiceGrid'
import { submitVoiceMatchSample } from '../../actions/voiceMatch';
import { fetchSpeech } from '../../actions/textToSpeech';


const VORecordModal = (props) => {  
  const {firstName,handleGenerateSample,selectedLibraryVoice,setSelectedLibraryVoice,closeVORecordModal,projectId,email,queryParams,setVoiceMatchId,setVoiceMatchSampleUrl} = props
  const dispatch = useDispatch();
  const [stage, setStage] = useState('preRecord');
  const [audioBlob, setAudioBlob] = useState(null);
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);
  const mediaStream = useRef(null);
  
  const showScript = stage === 'preRecord' || stage === 'recording' || stage ===  'generatingVO'

  const [activeVoice, setActiveVoice] = useState(selectedLibraryVoice || '2228')

  const selectLibraryVoiceAndClose=()=>{
    setSelectedLibraryVoice(activeVoice)
    closeVORecordModal()
  }

  const startRecording = async () => {
    audioChunks.current = [];
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStream.current = stream;
      mediaRecorder.current = new MediaRecorder(stream);

      mediaRecorder.current.ondataavailable = (event) => {
      //   console.log('Data available:', event.data.size);
        audioChunks.current.push(event.data);
      };

        mediaRecorder.current.onstart = () => {
        console.log('MediaRecorder started');
      };

      mediaRecorder.current.onstop = () => {
        stream.getTracks().forEach(track => track.stop());
      };

        await new Promise((resolve, reject) => {
        mediaRecorder.current.onstart = () => {

          resolve();
        };
        mediaRecorder.current.onerror = (event) => {
          reject(event.error);
        };
        mediaRecorder.current.start(1000); // Collect data every second
       // console.log('MediaRecorder.start() called');
      });

      setStage('recording');
    } catch (error) {
      console.error('Error accessing the microphone', error);
      setStage('failedPermission');
    }
  };

   const stopRecording = () => {
    mediaRecorder.current.stop();
    setStage('generatingVO');
  };

  useEffect(() => {
    if (stage === 'generatingVO') {
      const blob = new Blob(audioChunks.current, { type: 'audio/webm' });
      setAudioBlob(blob);
      submitRecording(blob);
    }
  }, [stage]);

  const cancelRecording = () => {
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
    }
    setStage('preRecord');
    setAudioBlob(null);
  };


  // const handleGenerateSample = async (voiceId) => {
  //   try {
  //     const audioUrl = await dispatch(fetchSpeech(VOICE_MATCH_SAMPLE_TEXT, voiceId));
  //     setVoiceMatchSampleUrl(audioUrl);
  //   } catch (error) {
  //     console.error('Error generating speech:', error);
  //   }
  // }


  const submitRecording = async (blob) => {
    if (!blob) return;

    try {
      const result = await dispatch(submitVoiceMatchSample(projectId, email, queryParams, blob));
      if (result.success) {
        const voiceId = result.voiceId;
        setVoiceMatchId(voiceId)

        const sample = await handleGenerateSample(voiceId)
        closeVORecordModal();
      } else {
        setStage('preRecord');
      }
    } catch (error) {
      console.error('Error in submitRecording:', error);
      setStage('preRecord');
    }
  };


  const helpVideoBaseUrl = "https://res.cloudinary.com/yarn/video/upload/";
  const transformations = "q_auto,w_1080,so_0.5/";
  const videoId = "v1724071592/clay/final_output_2024-08-19T12-46-02-529Z_v10pg0.mp4";
  const fullHelpVideoUrl = `${helpVideoBaseUrl}${transformations}${videoId}`;

  return (
     
      <div className={'VOFlow ' + (stage ? `VOFlow--${stage}` : '')} >

        {stage === 'preRecord' && 
          <div className='VOFlow-header'>      
            <div className='VOFlow-header-h1'>     
              Press the record button then read the script
            </div>
            <div className='VOFlow-header-h2'>
              Your tone will be matched in your AI voiceover, so speak clearly and confidently
            </div>
          </div>
        }

        {stage === 'recording' && 
          <div className='VOFlow-header'>      
            <div className='VOFlow-header-h2'>     
              Read the script then hit stop
            </div>            
          </div>
        }

        {stage === 'generatingVO' && 
          <div className='VOFlow-header'>      
            <div className='VOFlow-header-h2'>     
              Generating Voiceover
            </div>            
          </div>
        }

        {stage === 'selectLibraryVoice' && 
          <div className='VOFlow-header'>      
            <div className='VOFlow-header-h1'>     
              Select Library Voice
            </div>            
          </div>
        }

         {stage === 'failedPermission' && 
          <div className='VOFlow-header'>      
            <div className='VOFlow-header-h1'>     
              Setting up Microphone Permissions
            </div>
            <div className='VOFlow-header-h2'>
              Follow the video to enable permissions
            </div>
          </div>
        }




        {showScript && 
          <div className='VOFlow-center'>
            <div className='VOFlow-center-script'>
              <div className='VOFlow-center-script-para'>
                Hey – my name is {firstName}! You'll never guess what happened to me this morning!
              </div>
              <div className='VOFlow-center-script-para'>
                I saw a frog wearing a top hat. I thought I was seeing things, but there he was! He tipped his hat as if to say "Hello" and then with a jump, disappeared into the pond.
              </div>        
              <div className='VOFlow-center-script-para'>
                It was amazing! But what kind of frog wears a top hat at 7 a.m. on a Monday?
              </div>            
            </div>
          </div>
        }

        {stage === 'failedPermission' && 
          <div className='VOFlow-center'>
            <div className='VOFlow-center-desktopHelpVideoContainer'>                  
              <video
                src={fullHelpVideoUrl}                  
                autoPlay
                loop
                muted
                playsInline
              /> 
            </div>    
          </div>
        }

        {stage === 'selectLibraryVoice' && 
          <div className='VOFlow-center'>
            <SelectLibraryVoiceGrid              
              selectedLibraryVoice={activeVoice}
              setSelectedLibraryVoice={setActiveVoice}
            />
          </div>
        }

        {stage === 'failedPermission' && 
          <div className='VOFlow-footer'>     
            <button onClick={() => setStage('preRecord')} className='VOFlow-footer-primaryBtn'>               
              <div className='VOFlow-footer-primaryBtn-text'>
                Done
              </div>
            </button>            
          </div>
        }



        {stage === 'preRecord' && 
          <div className='VOFlow-footer'>     
            <button onClick={startRecording} className='VOFlow-footer-primaryBtn'> 
              <div className='VOFlow-footer-primaryBtn-iconContainer'>
                <Icon name='microphone' />
              </div>
              <div className='VOFlow-footer-primaryBtn-text'>
                Record voice sample
              </div>
            </button>
            <button onClick={() => setStage('selectLibraryVoice')} className='VOFlow-footer-subBtn'>
              Use Library Voice instead
            </button>
          </div>
        }

        {stage === 'recording' && 
          <div className='VOFlow-footer'>     
            <button onClick={stopRecording} className='VOFlow-footer-primaryBtn VOFlow-footer-primaryBtn--stop'> 
              <div className='VOFlow-footer-primaryBtn--stop-icon' />    
            </button>
            <button onClick={() => setStage('preRecord')} className='VOFlow-footer-subBtn'>
              Cancel
            </button>
          </div>
        }


        {stage === 'generatingVO' && 
          <div className='VOFlow-footer'>     
            <button disabledonClick={() => setStage('generatingVO')} className='VOFlow-footer-primaryBtn VOFlow-footer-primaryBtn--generating'>               
              <div className='VOFlow-footer-primaryBtn--generating-spinnerContainer'>
                <div className='VOFlow-footer-primaryBtn--generating-spinner' />
              </div>
            </button>            
          </div>
        }




        {stage === 'selectLibraryVoice' && 
          <div className='VOFlow-footer'>     
            <button onClick={selectLibraryVoiceAndClose} className='VOFlow-footer-primaryBtn'>               
              <div className='VOFlow-footer-primaryBtn-text'>
                Select Library Voice
              </div>
            </button>
            <button onClick={cancelRecording} className='VOFlow-footer-subBtn'>
              Cancel
            </button>
          </div>

        }
      </div>
  );
};

export default VORecordModal;