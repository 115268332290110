import {combineReducers} from 'redux'
import project from './project'
import campaign from './campaign'
import campaignVariants from './campaignVariants'


const rootReducer = combineReducers({
project,
campaign,
campaignVariants
})

export default rootReducer