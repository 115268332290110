import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import VoiceRecorder from '../components/VoiceRecorder';
import VoiceMatchPlayground from '../components/VoiceMatchPlayground'
// import {generateProjectWithNewVoice} from '../actions/voiceMatch'
import { useDispatch } from 'react-redux';
import ExportProjectComponent from '../components/ExportProjectComponent'


////If we have no voice then we show the voice recorder
//if we have a voice ID then show the playground (can try it and see if you like it)

const TEST_VOICE_ID='ApZf0OKtCSKJU9uBoGkz' //me clone
//ApZf0OKtCSKJU9uBoGkz
//const TEST_VOICE_ID=null

//const TEST_VOICE_ID='TX3LPaxmHKxFdv7VOQHJ' //liam

 const projectId='811873518' //short one
//const projectId='355201306'

const ProjectPageContainer = () => {
   const dispatch = useDispatch();
  const { projectNameAndId } = useParams();
  const [voiceId, setVoiceId] = useState(TEST_VOICE_ID);

  //const [generatedProjectId, setGeneratedProjectId] = useState('813728533');
    const [generatedProjectId, setGeneratedProjectId] = useState(null);
  // const [voiceId, setVoiceId] = useState(null);

  const handleVoiceMatchSucess = (voiceId) => {
    console.log('voice match sucess')
    console.log(voiceId)
    setVoiceId(voiceId)
    //setVoiceSample(sampleData);
  };



  const handleGenerateNewProject=async()=>{
    // console.log('generate proect')
    //  const result = await dispatch(generateProjectWithNewVoice(projectId, voiceId));
    // console.log(result)
    // console.log(result.projectId)
    // if (result && result.projectId) {
    //   setGeneratedProjectId(result.projectId);
    // }
  }


const handleExport = () => {
    // Implement your export logic here
    console.log('Exporting project:', generatedProjectId);
  }



  return (
    <div className='projectPage'>
      <h1>Project Page</h1>
      {!voiceId &&
        <VoiceRecorder projectId={projectNameAndId} onVoiceMatchSuccess={handleVoiceMatchSucess} />
      }
      {voiceId &&

      <VoiceMatchPlayground voiceId={voiceId}/>
      } 


      <br/>
      <br/>
      {voiceId &&


       <button onClick={handleGenerateNewProject}> generate project </button>

      }


       {generatedProjectId &&
        <ExportProjectComponent projectId={generatedProjectId}/>
      }

     
    </div>
  );
};

export default ProjectPageContainer;