import {fetch,put} from '../api'


export const fetchCampaign = (campaignId) => {
  return (dispatch) => {
    return fetch(`/campaigns/${campaignId}`)
      .then((response) => {
        dispatch({ type: 'FETCH_CAMPAIGN_SUCCESS' ,campaign:response});
        return response
      })
      .catch((error) => {
        return error
      })
  }
}

export const fetchCampaignVariants = (campaignId) => {
  return (dispatch) => {
    return fetch(`/campaigns/${campaignId}/variants`)
      .then((response) => {
        dispatch({ type: 'FETCH_CAMPAIGN_VARIANTS_SUCCESS' ,variants:response});
        return response
      })
      .catch((error) => {
        return error
      })
  }
}

export const fetchACampaignVariant = (variantId) => {
  return (dispatch) => {
    return fetch(`/campaign-variant/${variantId}`)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error
      })
  }
}

export const generateCampaignVariant = (variantId) => {
  return (dispatch) => {
    return put(`/campaign-variant/${variantId}/generate`)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error
      })
  }
}





