import React, { useState } from 'react';
import CampaignVariantRecord from './CampaignVariantRecord';
import CampaignVariantPlayback from './CampaignVariantPlayback';
import CampaignVariantProspectCard from '../CampaignVariantProspectCard';

const CampaignVariantWebcamTab = (props) => {
  const {isMobile,addWebcamToCampaignVariant,campaignVariant,activeTab,setTabStatus} = props
  const status = activeTab.status
  const [playbackUrl, setPlaybackUrl] = useState(null);

  const setStatus=(newStatus)=>{
    setTabStatus(activeTab.id,newStatus)
  }
  
  const handleRecordingComplete = (url) => {
    setPlaybackUrl(url);
    // Use the url parameter directly instead of the playbackUrl state
    addWebcamToCampaignVariant(url);
  };

  const handleDoneButton=()=>{
    addWebcamToCampaignVariant(playbackUrl)
  }

  return (
    <div className="campaignVariantPage-videoContainer">
      
      <div className='campaignVariantPage-videoScrim' />

     
      {campaignVariant && status !== 'complete' && (
        <CampaignVariantRecord
          key={`${activeTab.id}`}
          status={status}
          setStatus={setStatus}
          onRecordingComplete={handleRecordingComplete}
          isMobile={isMobile}
          variantId={campaignVariant.id}
        />
      )}

      {/*}
      {status === 'postEdit' && playbackUrl && (
        <CampaignVariantPlayback
          playbackUrl={playbackUrl}
          setStatus={setStatus}
          isMobile={isMobile}
          handleDoneButton={handleDoneButton}
        />
      )}
      */}
      
      
    </div>
  );
};

export default CampaignVariantWebcamTab;
