import React, { useState, useRef, useEffect } from 'react';
import VideoRecordModalInstructionsPanel from './VideoRecordModalInstructionsPanel';
import VideoRecordModalInstructionsToolbar from './VideoRecordModalInstructionsToolbar';
import VideoRecordModalInstructionsExampleVideo from './VideoRecordModalInstructionsExampleVideo'
import VideoRecordModalInstructionsInputDevices from './VideoRecordModalInstructionsInputDevices'

const VideoRecordModalInstructions = ({
  videoRef,
  isRecording,
  onStartRecording,
  onStopRecording,
  onRestart,
  onOpenChange,
  activeCamera,
  setActiveCamera,
  activeMicrophone,
  setActiveMicrophone,
  cameras,
  microphones,
  instructions,
  isReadyToRecord,
  isDisplayRecording,
  selectHideVideo,
  setSelectHideVideo,  
  inputVideoUrl,
  generatedVideoUrl,
  showRecordingTimer,
  recordingMinutes,
  recordingSeconds,
  previewVideo,
  webcamId,
  mediaStream
}) => {

  const [showVideoPreview, setShowVideoPreview] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const measurerRef = useRef(null);

  useEffect(() => {
    if (!measurerRef.current) return;

    const resizeObserver = new ResizeObserver(entries => {
      const entry = entries[0];
      setDimensions({
        width: entry.contentRect.width,
        height: entry.contentRect.height
      });
    });

    resizeObserver.observe(measurerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  
  const [countdownActive, setCountdownActive] = useState(false);
  const [countdownValue, setCountdownValue] = useState(3);

  const onStartRecordingRef = useRef(onStartRecording);

  // Keep ref updated
  useEffect(() => {
    onStartRecordingRef.current = onStartRecording;
  }, [onStartRecording]);

  // Countdown effect with only countdownActive dependency
  useEffect(() => {
    let intervalId;
    
    if (countdownActive) {
      intervalId = setInterval(() => {
        setCountdownValue((prev) => {
          if (prev <= 1) {
            clearInterval(intervalId);
            setCountdownActive(false);
            // Call the parent callback from the ref with setTimeout
            setTimeout(() => {
              onStartRecordingRef.current?.();
            }, 0);
            return 3;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [countdownActive]);

  const handleStartRecording = () => {
    if (countdownActive) {
      // Cancel countdown if clicked during countdown
      setCountdownActive(false);
      setCountdownValue(3);
    } else {
      // Start countdown if clicked normally
      setCountdownActive(true);
    }
  };

  const getCountdownStatus = (number) => {
    if (countdownValue > number) return 'completed';
    if (countdownValue === number) return 'active';
    return 'pre';
  };

  return (
    <>
      
       {showVideoPreview &&
        <VideoRecordModalInstructionsExampleVideo
          hideVideo={() => setShowVideoPreview(false)}     
          containerWidth={dimensions.width}
          containerHeight={dimensions.height}     
          inputVideoUrl={inputVideoUrl}   
          generatedVideoUrl={generatedVideoUrl}   
          previewVideo={previewVideo}     
          webcamId={webcamId}

        />
      }

      <div data-hide-recording={selectHideVideo ? true : false} className="videoRecordModal--instructions-center">        
        <VideoRecordModalInstructionsPanel 
          instructions={instructions}
          selectHideVideo={selectHideVideo}          
          showExampleVideoButton={previewVideo && !countdownActive && !isDisplayRecording}
          showVideoPreview={() => setShowVideoPreview(true)}              
        />
        <div className="videoRecordModal--instructions-preview">
          
          <div data-visibility-state={showRecordingTimer ? 'visible' : 'hidden'} className='videoRecordModal--instructions-preview-stopwatchView'>
            <div className='videoRecordModal--instructions-preview-stopwatchView-number'>
              {recordingMinutes}
            </div>
            <div className='videoRecordModal--instructions-preview-stopwatchView-dots'>
              :
            </div>
            <div className='videoRecordModal--instructions-preview-stopwatchView-number'>
              {recordingSeconds}
            </div>
          </div>

          <div data-visibility-state={countdownActive ? 'visible' : 'hidden'} disabled={!countdownActive} onClick={()=> handleStartRecording()} className="videoRecordModal--instructions-preview-countdownContainer">
            <div className='videoRecordModal--instructions-preview-countdown'>
              <div data-active-state={getCountdownStatus(3)} className="videoRecordModal--instructions-preview-countdown-number">
                3
              </div>
              <div data-active-state={getCountdownStatus(2)} className="videoRecordModal--instructions-preview-countdown-number">
                2
              </div>
              <div data-active-state={getCountdownStatus(1)} className="videoRecordModal--instructions-preview-countdown-number">
                1
              </div>
            </div>
          </div>

          <VideoRecordModalInstructionsInputDevices 
            isReadyToRecord={isReadyToRecord}
            isDisplayRecording={isDisplayRecording}
            selectHideVideo={selectHideVideo}
            setSelectHideVideo={setSelectHideVideo}
            countdownActive={countdownActive}
            activeCamera={activeCamera}
            setActiveCamera={setActiveCamera}
            activeMicrophone={activeMicrophone}
            setActiveMicrophone={setActiveMicrophone}
            cameras={cameras}
            microphones={microphones}
            mediaStream={mediaStream}            
          />

          

          <video 
            ref={videoRef} 
            autoPlay 
            playsInline 
            muted
            className="videoRecordModal--instructions-preview-video"
          />
        </div>        

      </div>
      <VideoRecordModalInstructionsToolbar 
        isRecording={isRecording}
        onStartRecording={handleStartRecording}
        countdownActive={countdownActive}
        onStopRecording={onStopRecording}
        onRestart={onRestart}
        onOpenChange={onOpenChange}
        activeCamera={activeCamera}
        setActiveCamera={setActiveCamera}
        activeMicrophone={activeMicrophone}
        setActiveMicrophone={setActiveMicrophone}
        cameras={cameras}
        microphones={microphones}
        isReadyToRecord={isReadyToRecord}
        isDisplayRecording={isDisplayRecording}
        selectHideVideo={selectHideVideo}
        setSelectHideVideo={setSelectHideVideo}
      />

      <div 
        ref={measurerRef}
        className='videoRecordModal--instructions-measurer' 
      />
    </>
  );
};

export default VideoRecordModalInstructions;