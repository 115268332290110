import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import ProjectPageContainer from './ProjectPageContainer';
import HomePageContainer from './HomePageContainer'
import GifPageContainer from './GifPageContainer'
import DemoHomePageContainer from './DemoHomePageContainer'
import DeliveryPageContainer from './DeliveryPageContainer'
import CampaignPageContainer from './campaigns/CampaignPageContainer'
import CampaignVariantPageContainer from './campaigns/CampaignVariantPageContainer'
// import DevCampaignVariantPageContainer from './campaigns/DevCampaignVariantPageContainer'



const gifProjectIds=['709652615','452897519']
const ELEVEN_LABS_DEMO_PROJECT_ID="2144900467"


const HomePageWrapperContainer = () => {
  const { customerName, projectNameAndId } = useParams();
  const projectId = projectNameAndId.split('-').pop(); // Assuming projectNameAndId format is "name-id"
  if (projectId === ELEVEN_LABS_DEMO_PROJECT_ID) {
    return <DemoHomePageContainer />;
  } else if (gifProjectIds.includes(projectId)) {
    return <GifPageContainer />;
  } else {
    return <HomePageContainer />;
  }
};


const AppContainer = () => {
  return (
    <div id="app">
      <Router>
        <Routes>
          <Route path="/share/:shareId" element={<DeliveryPageContainer />} />
          {/*<Route path="/record" element={<DevCampaignVariantPageContainer />} />*/}
          <Route path="/campaign/:campaignId/:variantId" element={<CampaignVariantPageContainer />} />
          <Route path="/campaign/:campaignId" element={<CampaignPageContainer />} />
          <Route path="/:customerName/:projectNameAndId" element={<HomePageWrapperContainer />} />
        </Routes>
      </Router>
    </div>
  );
};

export default AppContainer;