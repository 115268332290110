import React, {useRef, useState, useEffect} from 'react';
import Icon from '../../Icon';
import VideoDuration from './videoDuration';
import ReactSlider from 'react-slider';


function VideoPlayerSeekbar({playing, progress, onSeekMouseDown, onSeekMouseUp, onSeek, playbackId, duration, showTimelinePreview}) {

  const [storyboardJson, setStoryboardJson] = useState(null);
  
  const [seekBarWidth, setSeekBarWidth] = useState(0);

  const [hoverTime, setHoverTime] = useState(0);
  const [hoverPercentage, setHoverPercentage] = useState(0);
  const [hoverX, setHoverX] = useState(0);
  const seekBarRef = useRef(null); // Ref for the seekBar
  const [seekBarHovered, setSeekBarHovered] = useState(false); 

  const handleMouseMove = (e) => {
    if (!seekBarHovered) return; // Only proceed if hovered

    const seekBar = seekBarRef.current;
    if (seekBar) {
      const { left, width } = seekBar.getBoundingClientRect();
      setSeekBarWidth(width);
      const hoverPositionX = e.clientX - left; // Cursor position within the seekBar      
      setHoverX(hoverPositionX);
      const hoverPercentage = hoverPositionX / width; // Percentage of hover position
      setHoverPercentage(hoverPercentage);
      const durationHoverX = hoverPercentage * duration; // Calculate time based on hover
      setHoverTime(durationHoverX);
    }
  };

  const handleMouseEnter = () => setSeekBarHovered(true);
  const handleMouseLeave = () => setSeekBarHovered(false);




  return (
    <>
    <div 
      ref={seekBarRef}
      className='videoPlayer-seekBar'
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >


      <ReactSlider
        className="videoPlayer-seekBar-seekBar"
        thumbClassName="videoPlayer-seekBar-seekBar-thumb"
        trackClassName="videoPlayer-seekBar-seekBar-track"                  
        renderThumb={(props, state) => {
            const { key, ...restProps } = props;
            return <div key={key} className='videoPlayer-seekBar-thumb-thumb' {...restProps}></div>;
          }}        
        value={progress}
        min={0}
        max={0.999999}
        step={0.0001}
        onAfterChange={onSeekMouseUp}
        onBeforeChange={onSeekMouseDown}
        onChange={onSeek}
      />  

     

      <div data-state={seekBarHovered ? 'visible' : 'hidden'} className='videoPlayer-seekBar-marker' style={{left: `${hoverPercentage * 100}%` }} />
      

    </div>
    </>
  );
}

export default VideoPlayerSeekbar;