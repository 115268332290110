import React, { useRef, useState, useEffect } from 'react';
import ReactPlayer from 'react-player/file';
import ReactSlider from 'react-slider';
import getBlobDuration from 'get-blob-duration';
import Icon from '../../Icon';
import CampaignVariantPlaybackTrimBar from './CampaignVariantPlaybackTrimBar';

const CampaignVariantPlayback = ({ playbackUrl, setStatus,handleDoneButton }) => {
  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [muted, setMuted] = useState(false);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const [progressSeconds, setProgressSeconds] = useState(0);
  const [seeking, setSeeking] = useState(false);

  const [startTrimProgress, setStartTrimProgress] = useState(0);
  const [endTrimProgress, setEndTrimProgress] = useState(1);

  useEffect(() => {
    const fixBlobDuration = async () => {
      if (playbackUrl.startsWith('blob:')) {
        try {
          const actualDuration = await getBlobDuration(playbackUrl);
          setDuration(actualDuration);
          
          // Apply the Chrome workaround
          const videoElement = playerRef.current.getInternalPlayer();
          videoElement.currentTime = actualDuration;
          setTimeout(() => {
            videoElement.currentTime = 0.1;
          }, 100);
        } catch (error) {
          console.error('Error getting blob duration:', error);
        }
      }
    };

    fixBlobDuration();
  }, [playbackUrl]);

  const handlePlay = () => {
    setPlaying(true);
  };

  const handlePause = () => {
    setPlaying(false);
  };

  const handlePlayPause = () => {
    setPlaying((prevPlaying) => !prevPlaying);
  };

  const handleProgress = (state) => {
    if (!seeking) {
      setProgress(state.played);
      setProgressSeconds(state.playedSeconds);

      // Pause at trim end or video end if playing
      if (playing && (state.played >= endTrimProgress || state.played >= 1)) {
        setPlaying(false);
      }
    }
  };

  const handleDuration = (newDuration) => {
    if (isFinite(newDuration) && newDuration > 0) {
      setDuration(newDuration);
    }
  };

  const handleSeekChange = (newValue) => {
    const newProgress = parseFloat(newValue);
    if (isFinite(newProgress) && newProgress >= 0 && newProgress <= 1) {
      if (playerRef.current) {
        playerRef.current.seekTo(newProgress);
      }
      setProgress(newProgress);
      setProgressSeconds(newProgress * duration);
    }
  };

  const handleSeekMouseDown = () => {
    setSeeking(true);
  };

  const handleSeekMouseUp = (newValue) => {
    setSeeking(false);
    handleSeekChange(newValue);
  };

  const handleRerecord = () => {
    setStatus('pre');
  };

  // const handleDone = () => {
  //   console.log('Recording finalized.');
  //   props.handleDoneButton()
  //   // Handle the finalization of the recording (e.g., upload or save)
  // };

  const formatTime = (timeInSeconds) => {
    if (!isFinite(timeInSeconds) || timeInSeconds < 0) {
      return '00:00';
    }
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleTrimChange = (start, end) => {
    setStartTrimProgress(start);
    setEndTrimProgress(end);
  };

  return (
    <div className="campaignVariantPage-playback">
      <div className="campaignVariantPlayback-preview">
        <ReactPlayer
          ref={playerRef}
          url={playbackUrl}
          width="100%"
          height="100%"
          playing={playing}
          volume={volume}
          muted={muted}
          onPlay={handlePlay}
          onPause={handlePause}
          onProgress={handleProgress}
          onDuration={handleDuration}
          progressInterval={16}  // Update progress every 16ms (approximately 60 fps)
          controls={false}
        />
      </div>
      <div className="campaignVariantPage-playback-toolbarContainer">
        <div className="campaignVariantPage-playback-toolbar">          
            <button
              onClick={handlePlayPause}
              className="campaignVariantPage-playback-toolbar-btn campaignVariantPage-playback-toolbar-btn--playPause"
            >
              {playing ? <Icon name='playbackPause' /> : <Icon name='playbackPlay' /> }
            </button>
          
          <div className="campaignVariantPage-playback-toolbar-trimBarOuterContainer">
           {/* <CampaignVariantPlaybackTrimBar
              duration={duration}
              onTrimChange={handleTrimChange}
              onSeek={handleSeekChange}
            />*/}
            <div className="campaignVariantPage-playback-toolbar-trimBarInnerContainer">
              <div className="campaignVariantPage-playback-toolbar-trimBar-seekbar">
                <ReactSlider
                  className="campaignVariantPage-playback-toolbar-trimBar-seekbar-slider"
                  thumbClassName="campaignVariantPage-playback-toolbar-trimBar-seekbar-slider-thumb"
                  trackClassName="campaignVariantPage-playback-toolbar-trimBar-seekbar-slider-track"
                  value={progress}
                  onBeforeChange={handleSeekMouseDown}
                  onChange={handleSeekChange}
                  onAfterChange={handleSeekMouseUp}
                  min={0}
                  max={1}
                  step={0.001}
                />
              </div>              
            </div>
          </div>
          <div className="campaignVariantPage-playback-toolbar-rightBtnContainer">
            <button
              onClick={handleRerecord}
              className="campaignVariantPage-playback-toolbar-btn campaignVariantPage-playback-toolbar-btn--rerecord"
            >
              Re-record
            </button>
            <button
              onClick={handleDoneButton}
              className="campaignVariantPage-playback-toolbar-btn campaignVariantPage-playback-toolbar-btn--done"
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignVariantPlayback;