import axios from 'axios'


// let API='https://y-dev-api.onrender.com'
 let API='https://y-prod-api.onrender.com'

//let API='https://yarn.ngrok.io'

const getSubdomain = (hostname) => {
  // Special handling for localhost
  if (hostname.includes('localhost')) {
    const parts = hostname.split('.');
    if (parts.length > 1) {
      return parts[0];
    }
  }
  // Regular domain handling
  return hostname.split('.').slice(0, -2);
}

const subdomain = getSubdomain(window.location.hostname);

if(subdomain && subdomain=='dev'){
  API='https://y-dev-api.onrender.com'
}


const getHeaders = () => {

  return {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
     'Content-Type': 'application/json'
  };
};



export const fetch = async (
  url,
  params,
) => {
  const requestUrl=`${API}${url}`
  const headers = getHeaders()
  const result = await axios.get(requestUrl, {...params, headers})
  return result.data;
};



 export const put = async (
  url,
  params,
) => {
  const requestUrl=`${API}${url}`
  const headers = getHeaders()
 // const result = await axios.put(requestUrl, {...params, headers})
    const result = await axios.put(requestUrl, params, {headers})
  return result.data;
};

 export const post = async (
  url,
  params,
) => {
  const requestUrl=`${API}${url}`
  const headers = getHeaders()
  const result = await axios.post(requestUrl, params, {headers})
//  console.log(result)
  return result.data;
};



export const postWithArrayBuffer = async (url, params) => {
  const requestUrl = `${API}${url}`;
  const headers = getHeaders();
  const result = await axios.post(requestUrl, params, {
    headers,
    responseType: 'arraybuffer'
  });
  return result.data;
}; 
