import React, { useState, useRef, useEffect } from 'react';
import Icon from '../Icon'
import voicesData from '../../utils/voicesData'

const SelectLibraryVoiceGrid = ({ selectNext, smallSize,selectedLibraryVoice,setSelectedLibraryVoice  }) => {
  // const [activeBGMusic, setActiveBGMusic] = useState(null);
  const [playingVoice, setPlayingVoice] = useState(null);
  const audioRefs = useRef({});


  // const voiceOptions = [
  //   { id: 1, label: 'Annie', src: 'v1723229985/clay/annie_sample_bxopr4',voiceId:'OYTbf65OHHFELVut7v2H' },
  //   { id: 2, label: 'Daniel', src: 'v1722963892/clay/daniel_sample_gql759',voiceId:'uFIXVu9mmnDZ7dTKCBTX'  },      
  //   { id: 3, label: 'Jada', src: 'v1722963615/clay/jada_sample_noxbbg' ,voiceId:'kQwC2rkdNm44J1JTkuc6' },
  //   { id: 4, label: 'James', src: 'v1723230024/clay/james_sample_rzd81e',voiceId:'ErXwobaYiN019PkySvjV'  },    
  //   { id: 5, label: 'Sarah', src: '/v1722963408/clay/sarah_sample_rr7h5w' ,voiceId:'Io0CZSZh6JLTZOA1dVGo' },
  //   { id: 6, label: 'Tom', src: 'v1722963700/clay/tom_sample_rcibyf' ,voiceId:'iP95p4xoKVk53GoZ742B' },
  // ];


  const getTransformedUrl = (src) => {
    // return `https://res.cloudinary.com/yarn/video/upload/so_20/br_192k/${src}.mp3`;
      return `https://res.cloudinary.com/yarn/video/upload/${src}.mp3`;
  };

  const stopAllAudio = () => {
    Object.values(audioRefs.current).forEach(audio => {
      audio.pause();
      audio.currentTime = 0;
    });
    setPlayingVoice(null);
  };

  useEffect(() => {
    // Preload all audio files
    voicesData.forEach(option => {
      const audio = new Audio();
      audio.src = getTransformedUrl(option.src);
      audio.preload = 'auto';
      audioRefs.current[option.id] = audio;
      audio.addEventListener('ended', () => {
      setPlayingVoice(null);
    });
  });


  const handleVisibilityChange = () => {
    if (document.hidden) {
      stopAllAudio();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      stopAllAudio();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      Object.values(audioRefs.current).forEach(audio => {
      audio.src = '';
      });
    };
  }, []);

  const handlePlayPause = (id) => {
    if (playingVoice === id) {
      audioRefs.current[id].pause();
      setPlayingVoice(null);
   } else {
      stopAllAudio();
      audioRefs.current[id].currentTime = 0;
      audioRefs.current[id].play();
      setPlayingVoice(id);
    }
    setSelectedLibraryVoice(id);
  };

  return (
    <div data-small-size={smallSize} className="selectGrid selectGrid--music">
      {voicesData.map((voice) => (
        <div
          key={voice.id}
          data-active-state={selectedLibraryVoice=== voice.id ? 'active' : 'inactive'}
          className='selectGrid-btn selectGrid-btn--voice'          
        >
          <div className='selectGrid-btn-inner'>
            
            <div className='selectGrid-btn--voice-title'>
              {voice.label}
            </div>

            <button 
              className='selectGrid-btn-playPauseBtn'
              onClick={() => handlePlayPause(voice.id)}
            >
              {playingVoice === voice.id ? <Icon name='playbackPause' /> : <Icon name='playbackPlay' />}
            </button>

            {selectedLibraryVoice === voice.id &&
              <div className='selectGrid-btn--voice-activeIconContainer'>
                <Icon name='checkmark' />
              </div>
            }

            <div className='selectGrid-btn-activeBorder' />

            <button 
              onClick={() => setSelectedLibraryVoice(voice.id)}
              onDoubleClick={selectNext}
              className='selectGrid-btn--voice-bgButton' 
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default SelectLibraryVoiceGrid;