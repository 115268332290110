import React, { useState, useEffect } from 'react';

const CampaignVariantRecordInstructions = ({status, setStatus}) => {

  return (
    <div className="campaignVariantPage-record-instructionsContainer">
      <div className='campaignVariantPage-record-instructions'>
        <div className='campaignVariantPage-record-instructions-content'>
          We’re going to generate a 90 second production-grade sales video asset called “The rise of the Rev Ops team”. <br/><br/> It starts with a ~15 second personalized intro video from you to the prospect.
        </div>
        <button onClick={()=> setStatus('pre')} className='campaignVariantPage-record-instructions-btn'>
          Okay
        </button>
      </div>
    </div>
  );
};

export default CampaignVariantRecordInstructions;