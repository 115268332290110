import React from 'react';

const NextBtn = ({ nextTo }) => {  
  return (
    <button className='flow-submitBtn flow-submitBtn--next' onClick={nextTo}>
      Next
    </button>
  );
};

export default NextBtn;