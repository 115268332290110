import React from 'react';
import CampaignVariantRecordToolbarCountdown from './CampaignVariantRecordToolbarCountdown';
import CampaignVariantRecordToolbarStopButton from './CampaignVariantRecordToolbarStopButton';

const CampaignVariantRecordToolbar = ({ status, setStatus, stopRecording,restartRecording }) => {
  return (
    <>
    
    <div className="campaignVariantPage-record-toolbar">
      {status === 'pre' && (
        <button
          onClick={() => setStatus('countdown')}
          className="campaignVariantPage-record-toolbar-startBtn"
        >          
          <div className="campaignVariantPage-record-toolbar-startBtn-circle">
            <div className="campaignVariantPage-record-toolbar-startBtn-circle-inner" />
          </div>
        </button>
      )}        

      
      {status === 'recording' && (
        <CampaignVariantRecordToolbarStopButton 
          status={status}
          setStatus={setStatus}
          stopRecording={stopRecording}
          restartRecording={restartRecording}
          suggestedDuration={15}
        />
      )}
    </div>    
    {status === 'countdown' && (
      <CampaignVariantRecordToolbarCountdown 
        status={status}
        setStatus={setStatus} 
      />
    )}
    </>
  );
};

export default CampaignVariantRecordToolbar;