import React, { useState,useEffect,useMemo } from 'react';
import CampaignVariantPage from '../../components/campaigns/CampaignVariantPage'
import {addWebcamToCampaignVariantRequest,generateCampaignVariantProject} from '../../actions/juno'
import { fetchCampaign,fetchACampaignVariant,generateCampaignVariant,fetchCampaignVariants } from '../../actions/campaign'; 
import { useParams,useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import pusherInstance from '../../pusherInstance'

const initialTabs = [
	{ id: 'intro', label: 'Intro', iconName: 'cameraSemibold', isActive: true,  status:"pre" },
	{ id: 'outro', label: 'Outro', iconName: 'cameraSemibold', isActive: false,  status:"pre" },
	{ id: 'generate', label: 'Share Link', iconName: 'serverSemibold', isActive: false, isComplete: true },
];

const CampaignVariantPageContainer = () => {
	const { campaignId,variantId } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const campaign = useSelector(state => state.campaign);
	const campaignVariants = useSelector(state => state.campaignVariants);

	const campaignVariant = useSelector(state => 
		state.campaignVariants.find(variant => variant.id === variantId)
	);

	const [tabs, setTabs] = useState(initialTabs);
	const [uploadStatus, setUploadStatus] = useState({
		intro: { isUploading: false, isComplete: false },
		outro: { isUploading: false, isComplete: false }
	});


	  useEffect(() => {
    if (campaignVariant) {
      if (campaignVariant.status === 'pending') {
        setTabs(initialTabs);
        setUploadStatus({
          intro: { isUploading: false, isComplete: false },
          outro: { isUploading: false, isComplete: false }
        });
      } else {
        // For non-pending variants, set everything as complete and show only generate tab
        setTabs([
          { id: 'intro', label: 'Intro', iconName: 'cameraSemibold', isActive: false, status: "complete" },
          { id: 'outro', label: 'Outro', iconName: 'cameraSemibold', isActive: false, status: "complete" },
          { id: 'generate', label: 'Share Link', iconName: 'serverSemibold', isActive: true, isComplete: true },
        ]);
        setUploadStatus({
          intro: { isUploading: false, isComplete: true },
          outro: { isUploading: false, isComplete: true }
        });
      }
    }
  }, [campaignVariant]);


	const activeTab = tabs.find(tab => tab.isActive) || tabs[0];

	useEffect(() => {
		dispatch(fetchCampaign(campaignId))
		dispatch(fetchCampaignVariants(campaignId))
	}, [campaignId, dispatch]);


const { sortedVariants, currentIndex, showNext, showPrevious } = useMemo(() => {
    const nonPendingVariants = campaignVariants
        .filter(variant => variant.status !== 'pending')
        .sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at));

    const pendingVariants = campaignVariants
        .filter(variant => variant.status === 'pending')
        .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

    const sortedVariants = [...nonPendingVariants, ...pendingVariants];
    const index = sortedVariants.findIndex(variant => variant.id === variantId);
    
    return {
        sortedVariants,
        currentIndex: index,
        showNext: index < sortedVariants.length - 1,
        showPrevious: index > 0
    };
}, [campaignVariants, variantId]);



	const goToNext = () => {
		if (showNext && canNavigate) {
			const nextVariant = sortedVariants[currentIndex + 1];
			navigate(`/campaign/${campaignId}/${nextVariant.id}`);
			// resetTabs()
		}
	}

	const goToPrevious = () => {
		if (showPrevious && canNavigate) {
			const previousVariant = sortedVariants[currentIndex - 1];
			navigate(`/campaign/${campaignId}/${previousVariant.id}`);
			// resetTabs()
		}
	}

	const isUploading = uploadStatus.intro.isUploading || uploadStatus.outro.isUploading;

	const canNavigate = !(tabs.some(tab => tab.status === 'recording') || 
												activeTab.id === 'outro' || 
												isUploading);

const addWebcamToCampaignVariant = (url) => {
		const videoType = activeTab.id;
		// Set uploading status and move to next tab immediately
		setUploadStatus(prev => ({
			...prev,
			[videoType]: { ...prev[videoType], isUploading: true }
		}));
		// Move to next tab immediately
		if (videoType === 'intro') {
			setActiveTab('outro');
		} else if (videoType === 'outro') {
			setActiveTab('generate');
		}
		// Start the upload process 
		addWebcamToCampaignVariantRequest(url, variantId, videoType)
			.then(() => {
				setTabStatus(videoType, 'complete');
				setUploadStatus(prev => ({
					...prev,
					[videoType]: { isUploading: false, isComplete: true }
				}));
			})
			.catch((error) => {
				console.error('Error uploading webcam:', error);
				setTabStatus(videoType, 'error');
				setUploadStatus(prev => ({
					...prev,
					[videoType]: { isUploading: false, isComplete: false }
				}));
			});
	}


	useEffect(() => {
		if(campaignVariant && campaignVariant.status=='pending'){
			if (uploadStatus.intro.isComplete && uploadStatus.outro.isComplete) {
				generateProject();
			}
		}
	}, [uploadStatus.intro.isComplete, uploadStatus.outro.isComplete]);


	const generateProject = () =>{
		dispatch(generateCampaignVariantProject(campaignId,variantId))
	}

	const setActiveTab = (tabId) => {
		setTabs(prevTabs => 
			prevTabs.map(tab => 
				({ ...tab, isActive: tab.id === tabId })
			)
		);
	}

	const setTabStatus = (tabId, newStatus) => {
		setTabs(prevTabs =>
			prevTabs.map(tab =>
				tab.id === tabId ? { ...tab, status: newStatus } : tab
			)
		);
	}

////Pusher stuff
	const [connectedToPusher, setConnectedToPusher] = useState(false);
	useEffect(() => {
		if (!connectedToPusher) {
			subscribeToCampaignPusherChannel();
		}
		return () => {
			if (connectedToPusher) {
				pusherInstance.unsubscribe(`campaign-${campaignId}`);
			}
		};
	}, [connectedToPusher, campaignId]);

	const subscribeToCampaignPusherChannel = () => {
		const channel = pusherInstance.subscribe(`campaign-${campaignId}`);
		channel.bind('update', (data) => {
			dispatch(fetchCampaign(campaignId))
			dispatch(fetchCampaignVariants(campaignId))
		});
		setConnectedToPusher(true);
	};


	// const rerecordIntro=()=>{
	// 	console.log('re record intro')
	// 	setTabs(initialTabs.map(tab => ({
  //   	...tab,
  //   	isActive: tab.id === 'intro',
  //   	status: 'pre'
 	//  })));
  // // Reset upload status
  // setUploadStatus({
  //   intro: { isUploading: false, isComplete: false },
  //   outro: { isUploading: false, isComplete: false }
  // });
	// }


	const rerecordIntro = () => {
  // First ensure we're stopping any ongoing recordings/streams
  setTabs(prevTabs => prevTabs.map(tab => ({
    ...tab,
    isActive: tab.id === 'intro',
    // Force all tabs back to pre state
    status: 'pre'
  })));

  // Reset upload status
  setUploadStatus({
    intro: { isUploading: false, isComplete: false },
    outro: { isUploading: false, isComplete: false }
  });

  // Use a setTimeout to ensure state updates have propagated
  setTimeout(() => {
    setActiveTab('intro');
  }, 50);
};


	return (
		<div className='campaignVariantPageContainer'>
			<CampaignVariantPage 
				campaign={campaign}
				campaignVariant={campaignVariant}
				addWebcamToCampaignVariant={addWebcamToCampaignVariant}
				generateProject={generateProject}
				tabs={tabs}
				setActiveTab={setActiveTab}
				activeTab={activeTab}
				setTabStatus={setTabStatus}
				isUploading={isUploading}
				goToNext={goToNext}
				goToPrevious={goToPrevious}
				showNextButton={showNext}
				showPreviousButton={showPrevious}
				canNavigate={canNavigate}
				rerecordIntro={rerecordIntro}
			/> 
		</div>
	);
};

export default CampaignVariantPageContainer;