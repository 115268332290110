import React from 'react';
import Icon from '../../Icon'

const CampaignHomePageNextButton = ({prospectName, prospectCompany,variantId,handleVariantClick}) => {
  
  
  return (

    <div onClick={()=>{handleVariantClick(variantId)}} className='campaignHomePage-nextButton'>
      <div className='campaignHomePage-nextButton-button'>
        <Icon name='plus' />
      </div>
      <div className='campaignHomePage-nextButton-label'>
        <div className='campaignHomePage-nextButton-label-name'>
          {prospectName}           
        </div>
        <div className='campaignHomePage-nextButton-label-company'>
          {prospectCompany}
        </div>
      </div>      
    </div>    
  );
};

export default CampaignHomePageNextButton;