import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player/file';
import ReactSlider from 'react-slider';
import Icon from '../../Icon';

// First, let's create the Controls component
const VideoRecordModalInstructionsWizardExampleControls = ({
  playing,
  onPlayPauseClick,
  progress,
  onSeek,
  onSeekMouseDown,
  onSeekMouseUp,
  showControls,
}) => {
  return (
    <div className='videoRecordModal--instructions-example-playbackControls'>
      <button onClick={onPlayPauseClick} className='videoRecordModal--instructions-example-playbackControls-playPauseBtn'>
        {playing && <Icon name='playbackPause' />}
        {!playing && <Icon name='playbackPlay' />}
      </button>
      
      <div className='videoRecordModal--instructions-example-playbackControls-seekBar'>
        <ReactSlider
          className="videoRecordModal--instructions-example-playbackControls-seekBar-seekBar"
          thumbClassName="videoRecordModal--instructions-example-playbackControls-seekBar-seekBar-thumb"
          trackClassName="videoRecordModal--instructions-example-playbackControls-seekBar-seekBar-track"
          renderThumb={(props, state) => {
            const { key, ...restProps } = props;
            return <div key={key} className='videoRecordModal--instructions-example-playbackControls-seekBar-thumb-thumb' {...restProps}></div>;
          }}
          value={progress}
          min={0}
          max={0.999999}
          step={0.0001}
          onAfterChange={onSeekMouseUp}
          onBeforeChange={onSeekMouseDown}
          onChange={onSeek}
        />
      </div>
    </div>
  );
};

export default VideoRecordModalInstructionsWizardExampleControls