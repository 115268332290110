import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import Icon from '../Icon';

const CampaignDetailsModal = ({ campaign, children, isOpen, onClose  }) => {
  return (
    <>

        {children}
      <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className='campaignDetailsOverlay' />
        <Dialog.Content className='campaignDetailsModal forceDarkTheme'>          
          <div className='campaignDetailsModal-info'>
            <div className='campaignDetailsModal-info-label'>
              Campaign Details
            </div>
            <div className='campaignDetailsModal-info-title'>
              {campaign?.name || ''}
            </div>
            <div className='campaignDetailsModal-info-progress'>
              <div className='campaignDetailsModal-info-progress-stat'>
                <div className='campaignDetailsModal-info-progress-stat-number'>
                  0
                </div>
                <div className='campaignDetailsModal-info-progress-stat-label'>
                  Completed
                </div>
              </div>
              <div className='campaignDetailsModal-info-progress-stat'>
                <div className='campaignDetailsModal-info-progress-stat-number'>
                  24
                </div>
                <div className='campaignDetailsModal-info-progress-stat-label'>
                  Total Videos
                </div>
              </div>
            </div>
            <div className='campaignDetailsModal-info-instructions'>
              <div className='campaignDetailsModal-info-instructions-label'>
                Instructions
              </div>
              <div className='campaignDetailsModal-info-instructions-instructions'>
                <div className='campaignDetailsModal-info-instructions-instructions-para'>
                  You’ll record two short videos for each prospect which will be used to generate a 2-min campaign video with AI.
                </div>
                <div className='campaignDetailsModal-info-instructions-instructions-para'>
                  This campaign’s video is about how Clay enables rev-ops teams to have higher leverage with data enrichment, allowing SDRs to focus on building relationships.
                </div>
                <div className='campaignDetailsModal-info-instructions-instructions-para'>
                  Your two videos for each prospect will be:
                </div>
                <div className='campaignDetailsModal-info-instructions-instructions-para'>
                  1. A super personalized, energetic intro.
                </div>
                <div className='campaignDetailsModal-info-instructions-instructions-para'>
                  2. An outro message with proposed next steps.
                </div>

              </div>

            </div>

            <div className='campaignDetailsModal-info-vSpacer' />

            <Dialog.Close asChild>
              <button className='campaignDetailsModal-info-doneBtn' onClick={onClose}>  
                <div className='campaignDetailsModal-info-doneBtn-label'>
                  Got it
                </div>
                <div className='campaignDetailsModal-info-doneBtn-iconContainer'>
                  <Icon name='returnKey' />
                </div>
              </button>
            </Dialog.Close>

          </div>
          
          <div className='campaignDetailsModal-walkthrough'>          
            <div className='campaignDetailsModal-walkthrough-videoContainer'>

            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
    </>
  );
};

export default CampaignDetailsModal;