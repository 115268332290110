import axios from 'axios';
import {postWithArrayBuffer,put,post} from '../api'



export function fetchSpeech(text, voiceId) {


  return async (dispatch) => {
    try {
      let stability = 0.53;
      let similarityBoost = 0.65;
      let styleExaggeration = 0.5;
      let speakerBoost = true;
      let model_id = "eleven_multilingual_v2";


      const voiceSettings = {
        providerId:voiceId,
        stability,
        similarityBoost,
        styleExaggeration,
        speakerBoost,
        model_id
      };

      const response = await postWithArrayBuffer('/tts/clay', {
        text,
        voiceSettings
      }
      );


      const audioData = new Blob([response], { type: 'audio/mpeg' });
      const audioUrl = window.URL.createObjectURL(audioData);
  
      return audioUrl;
    } catch (error) {
      console.error('Error fetching speech:', error);
      // dispatch({ type: 'FETCH_SPEECH_ERROR', error });
      throw error;
    }
  };
}
