import findIndex from 'lodash/findIndex'



export default function (state = [], action) {
	switch (action.type) {
	

    case 'SIGN_OUT':
      return null

    case 'FETCH_CAMPAIGN_VARIANTS_SUCCESS':
      return action.variants;
    
    case 'GENERATE_VARIANT_REQUEST':
      console.log('generate variant request')
      return state.map(variant => {
        if (variant.id === action.variantId) {
          return {
            ...variant,
            updated_at: new Date().toISOString(),
            status:'submitted'
          };
        }
        return variant;
      });


    default:
      return state;
	}
}
